import AbstractService from './abstract.service';
import axios from 'axios';
import router from '../router';

class BuyerService extends AbstractService {
  constructor() {
    super();
  }

  async balance(token = null) {

    if (token) {
      const { data: response } = await this.axios.get('buyer/balance', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 'success') {
        await this.$store.dispatch('buyers/fetchBalance', response.data);
      }
      return
    }

    const { data: response } = await this.$axios.get('buyer/balance');

    if (response.status === 'success') {
      await this.$store.dispatch('buyers/fetchBalance', response.data);
    }

  }


  balanceAll() {
    return this.$numberFormat(Math.round(this.$store.getters['buyers/balanceAll']));
  }

  async cards(token = null) {
    let result;

    if (token) {
      const { data: response } = await axios.get('buyer/cards', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      result = response;
    } else {
      const { data: response } = await this.$axios.get('buyer/cards');
      result = response;
    }

    if (result.status === 'success') {
      await this.$store.dispatch('buyers/fetchCardUser', {
        cards: result.data,
        deposit: this.$store.getters['buyers/balance'].deposit,
      });
    }
  }

  async detail() {
    const token = router.app.$route.query.api_token;
    let response;

    if (token) {
      response = await axios.get('buyers/detail', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }else{
      response = await this.$axios.get('buyers/detail');
    }

    if (response.data.status === 'success') {
      await this.$store.dispatch('buyers/fetchUserInfo', response.data.data);
    }
  }

  async checkActionStatus() {
    const { data: response } = await this.$axios.post('action/check-status', {
      phone: this.$store.getters['buyers/userInfo'].phone,
    }, {
      headers: {
        'Content-Language': this.$i18n.locale,
      },
    });

    if (response?.status === 'success') {
      await this.$store.dispatch('buyers/actionStatus', response.data.action_detail.action_status || 0);
    } else {
      await this.$store.dispatch('buyers/actionStatus', 0);
    }
  }

  async scheduleList(contractId, token = null) {
    let result;

    if (token) {
      const { data: response } = await this.$axios.post('/buyer/contract',
        { contract_id: contractId },
        { headers: { Authorization: `Bearer ${token}` } },
      );

      result = response;

    } else {
      const { data: response } = await this.$axios.post('/buyer/contract', {
        contract_id: contractId,
      });

      result = response;
    }

    if (result.status === 'success') {

      return result.schedule_list.map((list, index) => ({
        ...list,
        month: index + 1,
      }))
      .filter(list => list.status === 0);
    }

    return [];
  }

}

export default new BuyerService();