import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../config';

import apiRequest from '@/helpers/apiRequest'

// TODO: replace 'fetchUserInfo' on 'refresh'
export function fetchUserInfo({ commit }, payload) {
  commit('setInfoUser', payload);
}

export function fetchBalance({ commit }, payload) {
  commit('setBalance', payload);
}

export function fetchCardUser({ commit }, { cards, deposit }) {
  commit('setCard', { cards, deposit });
}

export function actionStatus({ commit }, status) {
  commit('setActionStatus', status)
}

export async function userStatus({ commit }) {
  const api_token = Cookies.get('token');

  if (api_token) {
    const { data: { data } } = await axios.get(`${config.API_URL}/buyer/check_status`, {
      headers: { Authorization: `Bearer ${api_token}` },
    });
    commit('setStatus', data);
  }
}

export function signOut({ commit }) {
  commit('SET_USER_INFO', null);
}

export async function refresh({ commit }) {
  const api_token = Cookies.get('token');

  if (!api_token) return

  return apiRequest.get(`/buyers/detail`)
    .then(({ data }) => {
      commit('SET_USER_INFO', data.data)
    })
    .catch((error) => {
      // TODO: check on error
      console.log(error)
    })
}
