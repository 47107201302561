<template>
  <div
    class="sidebar"
    @mousedown="
      (e) => {
        e.preventDefault();
      }
    "
  >
    <div class="sidebar_head">
      <div v-if="userInfo" class="sidebar_top">
        <div class="top_img">
          <img :src="userPhoto ? userPhoto : profilePhotoDefault" alt="" />
        </div>
        <loading v-if="!userInfo" size="sm" />
        <div class="top_info" v-else>
          <h4>{{ userInfo?.name }} {{ userInfo?.surname }}</h4>
          <p>{{ userInfo?.phone }}</p>
        </div>
      </div>
      <div class="sidebar_content">
        <ul>
          <li v-for="(n, i) in links" :key="i">
            <router-link
              class="sidebar_link"
              :to="{ name: n.href }"
              exact
              active-class="active"
            >
              <span v-html="n.icon"></span>
              {{ n.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="sidebar_bottom">
      <ul>
        <li>
          <a target="_blank" href="https://uzumnasiya.uz/doc/publicOffer.pdf">
            <img src="@/assets/profile_assets/paperclip.svg" alt="" />
            <p>{{ $localize('footer')['support']['oferta'] }}</p>
          </a>
        </li>
        <li>
          <a href="tel:+998712092500">
            <img
              src="@/assets/profile_assets/customer-support.svg"
              alt=""
            />
            <p class="phone">
              {{ $localize('sidebar')['contactNumber'] }} <br />
              <a class="phone" href="javascript:">{{callCenterNumber}}</a>
            </p>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import profilePhotoDefault from '@/assets/profile_assets/Vector.png';
import BuyerService from '@/services/buyer.service'
import config from '@/config'
import Vue from 'vue'

export default {
  data: () => ({
    callCenterNumber: config.paymartSupportPhone,
    userPhoto: localStorage.getItem('profilePhoto') || null,
    profilePhotoDefault,
    links: [
      {
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M5.33301 19.8412V15.3342C5.33301 14.2292 6.22801 13.3342 7.33301 13.3342H16.666C17.771 13.3342 18.666 14.2292 18.666 15.3342V19.8412M14.9634 7.12316C14.9634 8.75958 13.6368 10.0862 12.0004 10.0862C10.364 10.0862 9.03738 8.75958 9.03738 7.12316C9.03738 5.48674 10.364 4.16016 12.0004 4.16016C13.6368 4.16016 14.9634 5.48674 14.9634 7.12316Z" stroke="#1e1e1e" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        href: 'profile-index',
        title: Vue.prototype.$localize('profile')['title'],
      },
      {
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M6.66699 8.00017H17.333M6.66699 12.0002H17.333M6.66699 16.0002H17.333M18.333 21.4932H5.66699C4.00999 21.4932 2.66699 20.1502 2.66699 18.4932V5.82617C2.66699 4.16917 4.00999 2.82617 5.66699 2.82617H18.334C19.991 2.82617 21.334 4.16917 21.334 5.82617V18.4932C21.333 20.1502 19.99 21.4932 18.333 21.4932Z" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
        href: 'profile.contracts',
        title: Vue.prototype.$localize('profile')['contract']['contracts'],
      },
      {
        icon: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 4.00098V17.427C4 18.847 5.151 19.998 6.571 19.998H20M8 15.999V10.667M12 16V8.00098M16 15.999V12.001M20 15.999V5.33498" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        href: 'profile.history',
        title: Vue.prototype.$localize('profile')['history']['title'],
      },
      {
        icon: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99999 5.33496H16M7.99999 5.33496L2.66699 10.667M7.99999 5.33496L12.175 10.667L16 5.33496M16 5.33496L21.333 10.667L11.718 21.332M11.718 21.332L2.66699 10.667M11.718 21.332L9.33299 13.333M2.66699 10.667H16" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        href: 'profile.bonus',
        title: Vue.prototype.$localize('profile')['bonus']['title'],
      },
      // {
      //   icon: `
      //   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      //   <path d="M20.435 16C18.937 19.153 15.723 21.334 12 21.334C6.84499 21.334 2.66699 17.155 2.66699 12.001C2.66699 8.27799 4.84699 5.06399 7.99999 3.56599M12 2.66699V12H21.333C21.333 6.84499 17.155 2.66699 12 2.66699Z" stroke="#1e1e1e" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      //   </svg>
      //   `,
      //   href: "/request-frient",
      //   title: "Пригласить друга",
      // },
    ],
  }),
  computed: {
    ...mapGetters('buyers', ['userInfo']),
  },
  async created() {
    await BuyerService.detail();
  },
};
</script>

<style lang="scss" scoped>
  .sidebar {
    width: 230px;
    position: fixed;
    left: 0;
    top: 96px;
    background: $white;
    min-height: calc(100vh - 96px);
    // position: relative;
    padding-bottom: 72px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.akciya {
      top: 144px;
    }

    &_top {
      padding: 20px 24px;
      display: flex;
      align-items: center;

      .top_img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        margin-right: 12px;
        overflow: hidden;

        img {
          object-fit: cover;
          max-width: 100%;
          height: auto;
        }
      }

      .top_info {
        h4 {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
        }

        a {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #333333;
        }
      }
    }

    &_bottom {
      ul li a:not(.phone) {
        padding: 24px;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        color: $black;
        transition: 0.33s;

        p {
          font-size: 14px;
          line-height: 20px;
          color: $black;
          margin-left: 10px;

          &.phone {
            font-weight: 600;
          }

          a {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $black;
          }
        }
      }
    }

    // &_content {
    //   ul {
    //     //
    //   }
    // }
  }
</style>
<style lang="scss">
  a.sidebar_link {
    padding: 16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: $black;
    transition: 0.33s;

    &:hover {
      color: $main;

      span {
        svg path {
          stroke: #6610F5;
        }
      }
    }

    &.active {
      color: $white;
      background: $main;

      span {
        svg path {
          stroke: $white;
        }
      }
    }

    span {
      margin-right: 10px;
    }
  }
</style>
