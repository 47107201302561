import axios from 'axios';
import Vue from 'vue';
import Cookie from 'js-cookie';
import config from '@/config/index';
import store from '@/store';
import router from '@/router';
import localize from '@/filters/localize.filter';
import i18n from '@/i18n/i18n';

axios.defaults.baseURL = config.API_URL;

const requestHandler = (request) => {
  store.dispatch('common/loading', true);

  const token = Cookie.get('token');
  request.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
  request.headers.common['Content-Language'] = i18n.locale;

  return request;
};

const responseHandler = (response) => {
  store.dispatch('common/loading', false);

  return response;
};

const errorHandler = (error) => {
  Vue.toasted.clear();
  Vue.toasted.error(error.message, {
    duration: 5000,
    className: 'error-toasted',
    action: {
      text: localize('button')['close'],
      onClick: async (e, toastObject) => {
        if (error.response.status === 401) {
          await store.dispatch('buyers/signOut');
          await Cookie.remove('token');

          await toastObject.goAway(0);
          await router.push({ name: 'home', params: { lang: 'uz' } });
        } else {
          toastObject.goAway(0);
        }
      },
    },
  });
  store.dispatch('common/loading', false);

  return error;
};

const errorHandlerV2 = (text) => {
  Vue.toasted.clear();
  Vue.toasted.error(text, {
    duration: 5000,
    className: 'error-toasted',
    action: {
      text: localize('button')['close'],
      onClick: async (e, toastObject) => {
        toastObject.goAway(0);
      },
    },
  });
  store.dispatch('common/loading', false);

  return text;
}

axios.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(error),
);

axios.interceptors.response.use(
  response=> responseHandler(response),
  (({ response }) => {
    if (response.data.message) {
      errorHandler(response)
      return
    }
    response.data.error.forEach(error => errorHandlerV2(error.text))
  }),
);

Vue.prototype.$axios = axios;

export default axios;
