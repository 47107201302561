<template>
  <footer>
    <div class="container">
      <div class="footer_top">
        <div class="row jus-c-sb">

          <div class="col-md-3 col-sm-12 mb-15">
            <div class="support">
              <h3>{{ localize("footer")["support"]["title"] }}</h3>
              <ul>
                <li class="phone_numbers support_item">
                  <img :src="phoneIcon" alt="" />
                  <ul>
                    <li v-for="(n, i) in phone" :key="i">
                      <a :href="`tel:` + n.phone">{{ n.phone }}</a>
                    </li>
                  </ul>
                </li>
                <li class="work_time support_item">
                  <img :src="calendar_icon" alt="" />
                  <p>
                    09:00 — 22:00 <br />
                    {{ localize("footer")["support"]["work-time"] }}
                  </p>
                </li>
                <!--                <li class="work_time support_item">-->
                <!--                  <a href="javascript:">{{-->
                <!--                    localize("footer")["support"]["confidence"]-->
                <!--                  }}</a>-->
                <!--                </li>-->
                <li class="work_time support_item">
                  <a
                    class="offerta"
                    target="_blank"
                    href="https://uzumnasiya.uz/doc/publicOffer.pdf"
                  >{{ localize("footer")["support"]["oferta"] }}</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-3 col-sm-12 mb-15">
            <div class="Information">

              <div class="info_head">
                <h3>{{ localize("footer")["info"]["title"] }}</h3>

                <span class="right">
                  <span class="icon active">
                    <img :src="plusIcon" alt="">
                  </span>
                </span>
              </div>

              <ul class="info_items">
                <li v-for="(n, i) in info" :key="i">
                  <router-link
                    active-class="pay_link_active "
                    exact
                    class="info_link scrollLink"
                    :to="{ name: n.href }"
                  >
                    {{ n.title }}
                  </router-link>
                </li>
                <li>
                  <a class="info_link scrollLink" href="https://solutionslab.uz/vacancies" target="_blank">
                    {{ localize("vacancies") }}
                  </a>
                </li>
              </ul>

            </div>
          </div>

          <div class="col-md-3 col-sm-12 mb-15">
            <!--            <div class="faq">-->

            <!--              <div class="faq-head">-->
            <!--                <h3>{{ localize('footer')['installments']['title'] }}</h3>-->

            <!--                <span class="right">-->
            <!--                  <span class="icon active">-->
            <!--                    <img :src="plusIcon" alt="">-->
            <!--                  </span>-->
            <!--                </span>-->
            <!--              </div>-->

            <!--              <ul class="faq_quesion">-->
            <!--                <li v-for="(n, i) in faqQuesion" :key="i">-->
            <!--                  <router-link class="faq_link" :to="{ name: 'faq', hash: n.href }">{{ n.title }}</router-link>-->
            <!--                </li>-->
            <!--              </ul>-->

            <!--            </div>-->
          </div>

          <div class="col-md-3 col-sm-12">
            <div class="social_networks">
              <img class="footer_brand" src="../assets/uzum-logo.svg" alt="" />

              <ul>
                <li class="location">
                  <img src="../assets/icons/location.svg" alt="" />

                  <p>
                    {{ localize("footer")["address"]["country"] }} <br />
                    {{ localize("footer")["address"]["region"] }} <br />
                    {{ localize("footer")["address"]["street"] }}
                  </p>
                </li>

                <li>
                  <h3>{{ localize("footer")["address"]["socials"] }}</h3>

                  <ul class="soc_set">
                    <li v-for="(n, i) in socSet" :key="i">
                      <a :href="n.href" target="_blank" class="soc_icon">
                        <img :src="n.img" alt="" />
                      </a>
                    </li>
                  </ul>
                </li>

                <li>
                  <h3>{{ localize("footer")["address"]["app"] }}</h3>

                  <ul
                    style="display: flex; align-items: center;"
                  >
                    <li>
                      <a :href="iosApp" role="button">
                        <img src="../assets/icons/appstore.svg" alt="" />
                      </a>
                    </li>

                    <li>
                      <a :href="androidApp" role="button">
                        <img src="../assets/icons/playmarket.svg" alt="" />
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="footer_bottom">
        <div class="df aic jus-c-sb">
          <p class="footer_bottom_text" v-html="localize('footer-bottom')['left-title']"></p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import phoneIcon from "@/assets/icons/phone.svg";
import calendar_icon from "@/assets/icons/calendar.svg";
import fb from "@/assets/icons/fb.svg";
import tg from "@/assets/icons/tg.svg";
import insta from "@/assets/icons/insta.svg";
import localize from "../filters/localize.filter";
import plusIcon from "../assets/icons/plus-mobile-orange.svg";
import $ from "jquery";
import config from "../config";

export default {
  name: "Footer",
  data: () => ({
    installable: false,
    deferredPrompt: null,
    phoneIcon,
    plusIcon,
    calendar_icon,
    phone: [
      {
        phone: config.paymartSupportPhone,
        href: "#!"
      }
    ],
    faqQuesion: [
      {
        title: localize("footer")["installments"]["what-is"],
        href: "#one"
      },
      {
        title: localize("footer")["installments"]["take"],
        href: "#two"
      },
      {
        title: localize("footer")["installments"]["verification"],
        href: "#three"
      }
      // {
      //   title: localize('footer')['installments']['pay'],
      //   href: '#four',
      // },
    ],
    info: [
      {
        title: localize("footer")["info"]["how-to-works"],
        other: false,
        href: "how-it-works"
      },
      {
        title: localize("footer")["info"]["partners"],
        other: false,
        href: "partners"
      },
      {
        title: localize("footer")["info"]["blog"],
        other: false,
        href: "news"
      }
    ],
    socSet: [
      {
        img: fb,
        href: "https://www.facebook.com/paymart.uz/"
      },
      {
        img: tg,
        href: "https://t.me/paymart_support_bot"
      },
      {
        img: insta,
        href: "https://www.instagram.com/paymart.uz/"
      }
    ],
    iosApp: config.iosAppUrl,
    androidApp: config.androidAppUrl
  }),
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });

    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  mounted() {
    if ($(window).width() < 575) {
      $(".faq-head").click(function() {
        $(this).next().slideToggle();
        $(this).find(".right .icon").toggleClass("active");
      });

      $(".info_head").click(function() {
        $(this).next().slideToggle();
        $(this).find(".right .icon").toggleClass("active");
      });
    }
  },
  methods: {
    async installPWA() {
      this.deferredPrompt.prompt();
    },
    localize,
    location() {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }
};
</script>

<style lang="scss" scoped>

@import "../assets/css/footer.scss";

.mb-32 {
  margin-bottom: 32px;
}

.mb-15 {
  margin-bottom: 15px;
}

.pt-16 {
  padding-top: 16px;
}

/*.info_head {
  .right {
    .icon {
      &.active {
        transform: translate(-180deg);
      }
    }
  }
}*/

@media (max-width: 575px) {
  .mb-32 {
    margin-bottom: 24px;
  }
  footer {
    padding-top: 24px;
    padding-bottom: 18px;

    .footer_bottom {
      margin-top: 16px;

      .footer_bottom_text {
        @media (max-width: $mobile) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
}
</style>
