import { versionRu } from "../data/politic-confidence";

export default {
  bonusTransfer: "Перевод бонусов",
  commisionAmount: "Сумма с комиссией:",
  selectCard: "Выберите карту для перевода",
  setPrice: "Введите сумму",
  BonusPay: {
    "form-title": "Логин"
  },
  overUZB: 'По всему Узбекистану',
  'not-found': 'Не найдено',
  'choose-region': 'Выберите регион',
  vacancies: "Вакансии",
  selectCategoryQuestion: 'Выберите категорию вопроса',
  mobile_operator_type: "Префикс код оператора {operator_type} не совпадает",
  documentTitle: "Uzum Nasiya - удобный сервис рассрочки!",
  navbar_client: "Для покупателей",
  navbar_business: "Для бизнеса",
  "navbar_download-app": "Скачайте приложение",
  navbar_singIn: "Войти",
  user: "Пользователь",
  buyFromUzumNasiya: "Покупки в Uzum Nasiya",
  sidebar: {
    contactNumber: "Контакт-центр:",
    technicalSupport: "Тех поддержка:",
    more: "Предстоящий розыгрыш призов"
  },
  footer: {
    support: {
      title: "Служба поддержки",
      "work-time": "Понедельник - Воскресенье",
      confidence: "Политика конфиденциальности",
      oferta: "Публичная оферта"
    },
    installments: {
      title: "Частые вопросы",
      "what-is": " Как начать пользоваться сервисом?",
      take: "Что делать, если товар оказался дороже лимита рассрочки?",
      verification: "Как оплачивать за договор?",
      pay: "Скидки? Бонусы?"
    },
    info: {
      title: "Информация",
      "how-to-works": "Как это работает?",
      partners: "Наши партнеры",
      bonuses: "Бонусы",
      news: "Блоги",
      blog: "Блог"
    },
    address: {
      country: "Узбекистан, г. Ташкент",
      region: " Яккасарайский район",
      street:
        "ул. Шота Руставели (ул. Восита Вохидова), дом 12 (Ориентир: Кафе Anjir, Офис Ucell)",
      socials: "Мы в соц. сетях",
      app: "Приложение Uzum Nasiya"
    }
  },
  "footer-bottom": {
    "left-title": "<a href=\"https://solutionslab.uz\" target=\"_blank\">SOLUTIONS LAB, AO ©</a> 2022. Все права защищены.",
    "refreshed-at": "Последняя обновления"
  },
  infoMessage:
    "Ваша заявка принята. В скором времени с вами свяжутся наши Менеджеры.",
  error: {
    error_card_sms_off: "SMS информирование не подключено",
    errorPage: { title: "Страница не найдена(" },
    emptyPage: {
      otherContract: "Не ваш договор",
      otherContractLogout: "Войти в свой аккаунт"
    },
    contract_out_of_date: "Срок активация договора истёк",
    successPage: { title: "Договор успешно оформлен" },
    text: "Ошибка !",
    upload: "Пожалуйста, загрузите фотографии",
    passport: "Ошибка чтения паспорта, загрузите новое фото",
    selfie: "Ошибка чтения селфи паспорта, загрузите новое фото",
    empty_card: "Недостаточно средств на балансе!",
    error_scoring: "Скоринг карты не пройден",
    term: "Данные карты введены неверно. Пожалуйста, перепроверьте",
    "empty-name": "Пожалуйста введите ФИО",
    "sms-check":
      "Номер телефона не совпадает с номером телефона смс информирования для прикреплённой карты. Пожалуйста, перепроверьте или пройдите регистрацию с использованием вашего телефонного номера",
    "card-has": "Эта карта уже была зарегистрирована",
    "enough-money":
      "Ваша карта не соответствует требованиям! Ежемесячные поступления на вашу карту должны превышать 1 млн. сум. за последние 6 месяцев. Попробуйте использовать другую карту.",
    empty: "Пожалуйста, заполните пустые поля",
    code: "Код введен неверно!",
    sms_not_sended: "СМС не отправлено. Попробуйте еще раз.",
    serviceUnavailable:
      "Процессинг сервис временно недоступен, попробуйте позже.",
    message: {
      notSms: "Код введен неверно. Попробуйте еще раз.",
      unCurretCartNumber: "Номер карты UZCARD/HUMO введен не верно.",
      unCurretTelNumber: "Пожалуйста, введите сначала телефонный номер.",
      applicationLoading: "Ваша заявка обрабатывается",
      contract_out_of_date: "Время оформления договора истёк."
    },
    cardAdd: {
      "card not fill": "sad",
      "exp not fill": "",
      c_card_error: "Нельзя добавлять корпоративную карту",
      invalid_card: "Неизвестный тип карты.",
      more_than_12: "Числа больше 12",
      more_than_31: "Срок карты превышает 2031 год",
      uzcard_denied: "Узкард временно недоступен!"
    },
    // TODO refactored errors
    user_is_blocked: "Данный пользователь заблокирован",
    sms_code_not_equal: "Неправильный SMS код"
  },
  "app.amount_not_fill": "У вас нет достаточного баланса.",
  "app.amount_incorrect": "Введите сумму",
  card_already_exists: "Эта карта уже существует",
  loading: {
    title: "Ваша заявка обрабатывается",
    text: "Это может занять несколько минут",
    waiting: "Ваша заявка обрабатывается, пожалуйста подождите."
  },
  "sign-up": {
    title: "Вход в кабинет",
    "form-label": "Номер телефона",
    "consent-title": "Согласие на обработку персональных данных",
    "phone-number": "Выберите номер телефона",
    sumPlaceholder: "Введите сумму"
  },
  politicConfidence: {
    title: "Политика конфиденциальности",
    content: versionRu
  },
  "sms-code": {
    title: "Введите SMS код",
    text: "Отправленный на номер",
    timer: "Отправить SMS еще раз",
    "timer-value": "(через ",
    "timer-time": " сек.)"
  },
  "card-sign": {
    sidebar: {
      "step-1": "Ввод номера телефона",
      "step-2": "Ввод данных платежной карты",
      "step-3": "Фото паспорта",
      "step-4": "Фото прописки",
      "step-5": "Селфи с паспортом",

      "step-id-1": "Ввод номера телефона",
      "step-id-2": "Ввод данных платежной карты",
      "step-id-3": "Фото ID карты",
      "step-id-4": "Фото прописки",
      "step-id-5": "Селфи с ID картой"
    },
    title: "Ввод данных карты",
    text: "Поздравляем! Пройдите пожалуйста быструю и единоразовую регистрацию.",
    number: "Номер карты",
    exp: "Срок карты",
    confirmSms: "SMS подтверждение",
    successAdded: "Карта успешно добавлена.",
    "agree-with": "Согласие с",
    "public-oferta": "Публичной офертой",
    "politic-confidence": "Политика компании в отношении кибербезопасности"
  },
  regions: [
    { id: 1703, name: "Андижанская область" },
    { id: 1706, name: "Бухарская область" },
    { id: 1708, name: "Джизакская область" },
    { id: 1710, name: "Кашкадарьинская область" },
    { id: 1712, name: "Навоийская область" },
    { id: 1714, name: "Наманганская область" },
    { id: 1718, name: "Самаркандская область" },
    { id: 1722, name: "Сурхандарьинская область" },
    { id: 1724, name: "Сырдарьинская область" },
    { id: 1726, name: "г. Ташкент" },
    { id: 1727, name: "Ташкентская область" },
    { id: 1730, name: "Ферганская область" },
    { id: 1733, name: "Хорезмская область" },
    { id: 1735, name: "Республика Каракалпакстан" }
  ],
  validation: {
    minPrice: {
      1000: "Минимальная сумма 1000 сум"
    },
    required: "Обязательное поле",
    userNameLength: "Минимальное значение 5",
    notValidUserName: "Невалидная имя пользователя",
    phoneNumberLength: "Некорректный номер телефона",
    equalPhoneNumber: "Нельзя вводить свой личный номер",
    guarantsPhoneMatchError: "Нельзя вводить одинаковые номера"
  },
  promotion: {
    title: "Предстоящие Розыгрыши",
    secondTitle: "Прошедшие Розыгрыши",
    thirdTitle: "Видео и фото отчеты",
    place: "Arena Markazi (Depo Mall)",
    empty_promotion: "АКЦИЯ",
    empty_soon: "Скоро",
    empty_notice:
      "О старте новой акции будет объявлено в ближайшее время. Оставайтесь с нами!"
  },
  regionChoose: "Выберите регион",
  statuses: {
    0: "Не активирован",
    1: "Активный",
    2: "На модерации",
    3: "Просрочен",
    4: "Просрочен",
    5: "Отмененный",
    9: "Закрыт"
  },
  registration: {
    "image-confirmation": "Выберите тип документа",
    "id-card": "ID карта",
    passport: "Паспорт"
  },
  "register-photo": {
    title: "Фото паспорта",
    "photo-ui":
      "Убедитесь что данные паспорта четко" +
      "<br/>" +
      " видны, отсутствуют блики",
    "id-title": "Фото ID карты",
    "download-id-file": "Фото ID карты" + "<br/>" + " загружено",
    "mobile-selfie-id-card": "Cелфи с ID картой",
    "download-selfie-id-card": "Селфи с " + "<br/>" + " ID картой загружено",
    "example-address-text-card":
      "Можно загрузить фото прописки с ID карты или другой удостоверяющий документ",
    "desctop-selfie-id-card": "Загрузить фото с ID картой",

    "desctop-id-file": "Загрузить фото передней части ID карты",
    "desctop-second-id-file": "Загрузить фото задней части ID карты",
    "desctop-passport-file": "Загрузить фото " + "<br>" + " паспорта",
    "desctop-selfie-file": "Загрузить селфи с" + "<br/>" + " паспортом",
    "mobile-passport-file": "Фото паспорта",
    "mobile-selfie-file": "Cелфи с паспортом",
    "passport-address": "Фото прописки",
    confidantTitle: "Контакты близких",
    confidantCardText: "Контакное лицо",
    confidantText: "Пожалуйста, укажите контакты близких Вам людей.",
    "download-passport-file": "Фото паспорта" + "<br/>" + " загружено",
    "download-selfie-file": "Селфи с " + "<br/>" + " паспортом загружено",
    "download-address-file": "Фото прописки " + "<br/>" + " загружено",
    "how-to-photo": "Узнайте как делать фотографии",
    "how-take-selfie-passport": "Как делать селфи с паспортом?",
    example: "Пример фотографии",
    exampleAddressText:
      "Можно загрузить фото прописки с паспорта или другой удостоверяющий документ"
  },
  oferta: {
    title:
      "Компания OA 'SOLUTIONS LAB' сотрудничает с ведущими международными и\n" +
      "          внутренними компаниями в сфере кибер безопасности.",
    partners: {
      title: "Наши процессинговые сервис партнеры",
      text:
        "Все данные между процессинговыми центрами проходят через защищенные\n" +
        "          каналы связи, а также в зашифрованном криптографическом виде. Данные\n" +
        "          пластиковых банковских карт хранятся на серверах процессинговых\n" +
        "          центров. По полученным данным от процессинговых центров, формируется\n" +
        "          анализ платежеспособности (скоринг) пользователя сервиса \"Uzum Nasiya\", а\n" +
        "          также формируется услуга рекуррентного (автоматического) платежа.\n" +
        "          Рекуррентные платежи осуществляются в размере и сроки согласно графика\n" +
        "          платежей за Товар или услугу до полного погашения оплаты за\n" +
        "          приобретаемый Товар"
    },
    "send-data": {
      title: "Передача данных",
      text:
        "Данные пользователей передаются по зашифрованному соединению SLL.\n" +
        "          Передача данных с помощью SSL соединения направленно на защиту всех\n" +
        "          проводимых транзакций и предотвращения нежелательного доступа к\n" +
        "          информации. SSL соединение - это надежный инструмент, чтобы\n" +
        "          гарантировать своему ресурсу юридическую и сетевую безопасность при\n" +
        "          работе с персональными и транзакционными данными."
    }
  },
  refusal: {
    title: "К сожалению, Вы не прошли верификацию",
    text: "Возможные причины отказа:",
    causes: {
      overdue: "Есть просроченные кредиты",
      alreadyHave: "Данный пользователь есть на сайте UZUM NASIYA"
    }
  },
  popup: {
    "how-to-works": "Как это работает",
    magazines: "Магазины",
    // bonuses: 'Бонусы',
    news: "Блог",
    "download-app": "Скачайте <br/> приложение",
    text: "и оформляйте моментальную <br/> рассрочку",
    alert: "* Будет доступно с 1 сентабря 2021 года"
  },
  balancePopup: {
    title: "Ваш баланс",
    limit: "Лимит рассрочки",
    possible: "Возможная рассрочка",
    topUp: "Пополнить",
    paymartCart: "Карта",
    personalBalance: "Лицевой счет",
    yourDeposit: "Ваш депозит"
  },
  button: {
    shopsAll: "Все магазины",
    bonus: "Воспользуйтесь Бонусами",
    back: "Назад",
    newmore: "Регистрация",
    uploadPhoto: "Загрузить фото",
    takePhoto: "Сделать фото",
    cancel: "Отмена",
    continue: "Продолжить",
    download: "Скоро *",
    delete: "Удалить",
    "go-to-site": "Перейти на сайт",
    partnerSite: "Перейти на сайт магазина",
    "go-profile": "Войти в кабинет",
    installments: "Попробуйте рассрочку",
    "number-change": "Изменить номер",
    next: "Следующая",
    more: "Подробнее",
    buy: "Купить",
    "orders-send": "Отправить заявку",
    register: "Пройти регистрацию",
    sell: "Начните продавать",
    upgrade: "Улучшить",
    open: "Открыть",
    share: "Поделиться",
    close: "Закрыть",
    pay: "Оплатить",
    goHome: "Вернуться на главную",
    logout: "Выход",
    downloadContract: "Скачать договор",
    offerPreview: "Посмотреть оферту",
    askActivationCode: "Запросить код для активации",
    done: "Оформить договор",
    checkStatus: "Проверить заявку",
    check: "Проверить",
    send: "Отправить",
    repay: "Погасить"
  },
  input: { "search-placeholder": "Найти магазин" },
  breadcrumb: {
    home: "Главная",
    whatIsPaycoin: "Что такое Paycoin?"
  },
  banner: {
    buyer: {
      title: "Uzum Nasiya — покупаете вы, платим мы!",
      text: "При использовании сервиса рассрочки Uzum Nasiya, вы всегда точно знаете про сумму и график оплаты. Никаких скрытых платежей и штрафов за просрочку."
    },
    business: {
      title: "Удобство для Вас и ваших клиентов",
      text: "Uzum Nasiya убирает проблему нехватки средств у ваших клиентов, при этом увеличивая средний чек и вашу клиентскую базу вне зависимости от вашей сферы деятельности."
    },
    bonuses: {
      title: "Бонусная система",
      text: "Прозрачная и очень выгодная бонусная система для Клиентов позволяет очень быстро 'прокачать' свою учетную систему и пользоваться огромными скидками, большим лимитом или увеличенным сроком возврата."
    },
    magazines: { title: "Магазины" },
    news: {
      title: "Блог",
      newsAll: "Все блоги"
    },
    "how-to-work": {
      title: "Как это работает?",
      text: "Зарегистрируйтесь на сайте всего за несколько минут и получите мгновенный доступ к рассрочке необходимых товаров с лимитом до 15 миллионов сум."
    }
  },
  profile: {
    checkPaymentType: "Выберите тип оплаты:",
    title: "Профиль",
    limit: "Текущий лимит:",
    limitPaycoin: "Предельный лимит",
    sales: "Скидки:",
    currency: "сум",
    bonusPoints: "Бонусные баллы:",
    month: "мес.",
    maxPeriod: "Макс период:",
    myCards: "Мои карты",
    cardAdd: "Добавить карту",
    cardTitleDefault: "Зарплатная карта",
    balanceUp: {
      title: "Пополнение лицевого счёта",
      cards: "Карты",
      replenishmentMethods: "Способы пополнения",
      numberPersonalAccount: "Номер вашего лицевого счета:",
      successPaid: "Успешно оплачено"
    },
    contract: {
      goBack: "Вернутся назад",
      confirm: "Подтвердить",
      number: "Договор",
      installmentTerm: "Срок рассрочки",
      contracts: "Договора",
      empty: "Нет заключенных договоров.",
      searchField: "Напишите номер договора",
      remaining: "Остаток:",
      labelSMSCode: "Смс код активации:",
      nextPay: "Следующее списание:",
      monthlyPayment: "Ежемесячный платеж:",
      thisMonth: "К оплате на этот месяц:",
      allPeriod: "Оплата за весь период:",
      repayment: "Для досрочного погашения:",
      payment_for_month: "К оплате за месяц",
      payment_for_months: "К оплате за",
      month_end: "месяца",
      months: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      paymentError: "Ошибка при проведения платежа, повторите еще!",
      paymentPeriod: "Выбрать период оплаты:",
      paymentCard: "Выберите карту для списания платежа",
      payInfo: "С вашей карты будет списан платеж за указанный период",
      payAccountCard:
        "С вашего лицевого счета будет списан платеж за указанный период",
      paymentType: {
        oneMonth: "Оплатить за 1 месяц",
        random: "Оплатить произвольную сумму"
      },
      selectAll: "Выбрать все",
      clearAll: "Очистить все",
      payFor: "К оплате"
    },
    history: {
      empty: "Пусто",
      title: "История",
      payments: "Платежи",
      documents: "Документы",
      sum: "Cумма:",
      payment: "Оплата:",
      paymentType: {
        user: "Пополнение л/с",
        user_auto: "Оплата",
        auto: "Автосписание",
        refund: "Возврат бонусов",
        fill: "Начисление бонусов",
        A2C: "Перевод бонусов на карту",
        upay: "Оплата по бонусам",
        Reimbursable: "списание по взысканию",
        unknown: "Неизвестно"
      },
      card: "Карта",
      time: "Время",
      underContract: "по договору №",
      paymentGraphic: "График платежей",
      paymentDate: "Дата платежа",
      amountToPay: "Сумма к оплате"
    },
    bonus: {
      title: "Бонусные суммы",
      bonusCardTitle: "Бонусные cуммы",
      spend: "Потратить"
    },
    sale: "Потратить Paycoin на улучшение скидки",
    forSale: "к Скидке"
  },
  carousel: {
    header: {
      title: "Покупайте с Uzum Nasiya в...",
      magazines: "Все 10 магазинов"
    },
    // text:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt aliquet nisl.",
    partners: {
      title: "Присоединяйтесь в ряд наших успешных партнеров",
      all: "Все партнеры"
    }
  },
  akciya: {
    days: "Дней",
    hours: "Часы",
    banner: "Акции и Розыгрыши Uzum Nasiya",
    bannerTitle:
      "Грандиозные ежемесячные розыгрыши где мы разыгрываем множество ценных призов, а главные призы - три автомобиля Chevrolet Spark. Зарегистрируйтесь в Uzum Nasiya, приобретайте товары в рассрочку и своевременно вносите оплату.",
    minutes: "Минуты",
    seconds: "Секунды",
    title: "Акция",
    status: "Ваш статус:",
    subTitle:
      "Оформите рассрочку, оплачивайте вовремя и выиграйте автомобиль Spark! Нажмите на кнопку \"Подробнее\" и\n" +
      "узнайте, участвуете ли вы в розыгрыше.",
    isYouMemberText: "Напишите свой номер и узнайте, участвуете ли Вы в акции",
    text:
      "Покупайте в рассрочку, оплачивайте вовремя и станьте обладателем автомобиля Spark!\n" +
      "В акции участвуют пользователи сервиса Uzum Nasiya, которые уже оформили покупку и своевременно вносят\n" +
      "ежемесячную оплату. Каждый месяц вы можете выиграть холодильники, телевизоры, стиральные машины и множество\n" +
      "других призов. Розыгрыш трех автомобилей Spark начнется в июне 2022 года. Каждые два месяца мы будем дарить\n" +
      "по одному автомобилю.",
    offerta: "Публичная оферта.",
    dates: [
      "Первый розыгрыш будет проходить 26.11.2021 в 17:00 ТЦ \"Riviera Mall\"",
      "Второй розыгрыш будет проходить - 26.12.2021 с 11:00 до 20:00, в магазине бытовой техники и электроники «MEDIAPARK Bozori» Мирабадский филиал",
      "Третий розыгрыш будет проходить - (Уточняется)",
      "Четвертый розыгрыш будет проходить - (Уточняется)",
      "Пятый розыгрыш будет проходить - (Уточняется)",
      "Шестой розыгрыш будет проходить - (Уточняется)",
      "Супер розыгрыш автомобиля - 18.06.2022"
    ],
    member: "Поздравляю вы участник акции!",
    overdue:
      "Имеется просрочка по задолженности, погасите задолженность и участвуйте в акции",
    "not-verified-user":
      "Вам необходимо продолжить регистрацию чтобы участвовать в других конкурсах нашей компании",
    "blocked-user": "Вы не можете участвовать в акции",
    "not-member": "Вы не участник акции",
    "no-user": "Вы не зарегистрированы",
    isMember: "Участник акции",
    notMember: "Не участник",
    winnersText: "Список победителей",
    winnersPhotos: "Фото и видео отчет",
    giftText: "Приз:",
    winners: {
      riviera: [
        {
          name: "Хамраева Шохиста Бахтиер кизи",
          gift: "стиральная машина	Samsung WW70J42G0PSDLD"
        },
        {
          name: "Шафиков Магсум Мансурович",
          gift: "холодильник	Indesit DS 4160 S"
        },
        {
          name: "Нормуминова Мухаббат Нормуротовна",
          gift: "газовая плита	Milagro 02-K CHU"
        },
        {
          name: "Ортиков Абдунаби Абдумажидович",
          gift: "смартфон	VIVO Y31 (4/64) Black"
        },
        {
          name: "Абдиева Дилдора Орифовна",
          gift: "ноутбук	Lenovo V15-IGL"
        },
        {
          name: "Собирова Шахноза Насимовна",
          gift: "телевизор	Samsung UE 40M5070"
        },
        {
          name: "Кодиров Бахтиер Абдимуротович",
          gift: "мини-печь	Itimat I-60 DL"
        },
        {
          name: "Пайзиев Фахриддин Сайфиддин угли",
          gift: "стиральная машина	Samsung WW70J42G0PSDLD"
        },
        {
          name: "Комилова Гульноза Умуровна",
          gift: "холодильник	Indesit DS 4160 S"
        },
        {
          name: "Сидиков Адимбай Акбаралиевич",
          gift: "газовая плита	Milagro 02-K CHU"
        },
        {
          name: "Эргашев Мусабек Бахромжон угли",
          gift: "смартфон	VIVO Y31 (4/64) Black"
        },
        {
          name: "Аъзамова Хуршидахон Шаробидиновна",
          gift: "ноутбук	Lenovo V15-IGL"
        },
        {
          name: "Норкулов Сирожиддин Намозович",
          gift: "телевизор	Samsung UE 40M5070"
        },
        {
          name: "Сарабекова Саодат Кобаевна",
          gift: "мини-печь	Itimat I-60 DL"
        }
      ],
      mediapark: [
        {
          name: "Каландарова Нафосат Октамбой кизи",
          gift: "газовая плита Milagro 02-K CHU"
        },
        {
          name: "Бобаерова Гульноза Амировна",
          gift: "мини-печь Itimat I-60 DL"
        },
        {
          name: "Хатамов Нодит Равшан угли",
          gift: "ноутбук Lenovo V15-IGL"
        },
        {
          name: "Кадирова Айнаш Рахметуллаевна",
          gift: "стиральная машина Samsung WW70J42G0PSDLD"
        },
        {
          name: "Абдуазимов Акмаль Маматкулович",
          gift: "смартфон VIVO Y31 (4/64) Black"
        },
        {
          name: "Шарипова Мехринисо Рахимовна",
          gift: "холодильник ART HD 316 FN S"
        }
      ],
      mediapark_qoratosh: [
        {
          name: "Алимбай Аркаев Худайбергенович",
          gift: "МИНИ-ПЕЧЬ Itimat I-60 DL"
        },
        {
          name: "Алишер Даутов Анаятович",
          gift: "НОУТБУК Lenovo V15-IGL N4020/4GB/1000GB"
        },
        {
          name: "Гульмира Хамракулова Убайдуллаевна",
          gift: "СТИРАЛЬНАЯ МАШИНА LG F2J5NS6W"
        },
        {
          name: "Гульшод Махмудова Джамиловна",
          gift: "ТЕЛЕВИЗОР Panasonic 40 TX-40DR400"
        },
        {
          name: "Гулбохор Тангирова Буриевна",
          gift: "СМАРТФОН VIVO Y31 (4/64) Black"
        },
        {
          name: "Нуриддин Турабаев Дусмуродович",
          gift: "ХОЛОДИЛЬНИК ART HD 316 FN S"
        },
        {
          name: "Махмиджон Мирзакаримов Турсунович",
          gift: "ГАЗОВАЯ ПЛИТА Milagro 02-K CHU"
        }
      ]
    }
  },
  step: {
    title: "Все максимально просто!",
    link: "Подробнее",
    buy: {
      title: "Быстрая покупка",
      text: "Оформите рассрочку за 5 минут и покупайте любые товары в партнерских магазинах без первоначального взноса."
    },
    calculations: {
      title: "Прозрачные расчеты",
      text: "В рассрочке Uzum Nasiya нет скрытых платежей и штрафов. Система расчетов понятна для всех."
    },
    renewableLimit: {
      title: "Возобновляемый лимит",
      text: "Лимит возобновляется на сумму погашенной ежемесячной задолженности."
    }
  },
  benefits: {
    title: "Преимущества партнерства с Uzum Nasiya",
    cards: [
      {
        heading: "Увеличение продаж и конверсии",
        title:
          "Наши партнеры отмечают увеличение среднего чека на 70% и средней конверсии в продажах на 15%. Также существенно растет число повторных и лояльных покупателей."
      },
      {
        heading: "Эффективный маркетинг",
        title:
          "Мы бесплатно расскажем о вас и ваших товарах тысячам наших пользователей по всему Узбекистану по всем нашим каналам продвижения."
      },
      {
        heading: "Универсальное решение для всех партнеров",
        title:
          "Будь то розничная точка, сеть магазинов или интернет- магазин, мы подберем для вас идеальные параметры для достижения общих целей."
      },
      {
        heading: "Возможность масштабирования",
        title: "С нами ваш бизнес будет расти быстрее с меньшими рисками."
      }
    ]
  },
  "how-it-works": {
    title: "Как это работает?",
    cards: [
      {
        heading: "Настройте свое решение",
        title:
          "Выберите, что лучше всего подходит для вашего покупателя: 6, 9 или 12 месяцев рассрочки, в зависимости от размера переплаты."
      },
      {
        heading: "Минимизируйте свои риски",
        title:
          "Продавайте свои товары в рассрочку и не думайте о рисках, связанных с ними. При продаже через Uzum Nasiya оплата за товары будет произведена нами."
      },
      {
        heading: "Начать очень легко",
        title:
          "Легкий старт с момента подачи заявки до осуществления первой продажи через Uzum Nasiya. Онлайн интеграция простая и быстрая. Мы всегда готовы вам помочь!"
      }
    ]
  },
  product: {
    single: "Товар",
    plural: "Товары",
    name: "Наименование",
    price: "Цена",
    amount: "Кол-во",
    sum: "Сумма",
    total: "Итого"
  },
  productPage: {
    title: "Товар"
  },
  "orders-form": {
    title: "Оставьте заявку и получите предложение",
    "company-name": "Наименование организации",
    "company-name-placeholder": "Введите организацию",
    phone: "Номер телефона",
    name: "Имя",
    "name-placeholder": "Введите имя",
    "fio-placeholder": "Введите ФИО доверителя (родственник)",
    "number-placeholder": "Введите номер телефона",
    products: "Вид организации",
    "products-placeholder": "Выберите опцию",
  },
  "pay-coin-info": [
    {
      heading: "Что такое Paycoin?",
      title:
        "Paycoin является элементом бонусной системы Платформы UZUM NASIYA. Лояльные и добросовестные Клиенты платформы всегда имеют возможность накопить Paycoin для улучшения условий договоров, заключаемых в рамках PAYMART. ",
      active: true
    },
    {
      heading: "Как заработать Paycoin?",
      title:
        "Зарабатывать Paycoin достаточно легко. Самый доступный способ это - своевременно оплачивать ежемесячные платежи. За каждый своевременный платеж вам будет начисляться Paycoin.",
      active: false
    },
    {
      heading: "На что тратить Paycoin?",
      title:
        "Paycoin можно потратить на приобретение специальных тарифных пакетов, с применением которых можно получить постоянную скидку, а также увеличение лимита до 2-х раз и срока рассрочки до 15 месяцев.",
      active: false
    }
  ],
  "registration-info": {
    title: "Что понадобится для регистрации на Uzum Nasiya ?",
    text: "После прохождения регистрации вы получите доступ к покупке товаров наших партнеров в раcсрочку и бонусам нашей системы!"
  },
  installment: {
    "how-to-sign": {
      title: "Что понадобится для регистрации на Uzum Nasiya?",
      documents: [
        "Номер телефона",
        "Паспорт/ID-карта",
        "Банковская пластиковая карта",
        "Мобильный телефон с камерой"
      ],
      text: "После прохождения регистрации вы получите доступ к покупке товаров наших партнеров в раcсрочку и бонусам нашей системы!"
    },
    "how-to-register": {
      title: "Как регистрироваться?"
    },
    step: [
      {
        heading: "На сайте:",
        lists: [
          "Ввести номер телефона",
          "Ввести номер платежной карты",
          "Загрузить фотографию паспорта",
          "Загрузить селфи фотографию с паспортом"
        ]
      },
      {
        heading: "Мобильный телефон",
        lists: [
          "Ввести номер телефона",
          "Ввести номер платежной карты",
          "Ввести серию паспорта и дату рождения",
          "Пройти проверку на живость (интеграция с системой  MyID)"
        ]
      },
      {
        heading: "После ввода данных на ваш номер придёт пароль для входа в личный кабинет в виде SMS-сообщения.",
      }
    ],
    hint: "После ввода данных на ваш номер придёт пароль для входа в личный кабинет в виде SMS-сообщения."
  },
  "how-to-buy": {
    title: "Как сделать покупки?",
    cards: [
      {
        heading: "Выберите товар в магазине наших партнеров",
        title:
          "Сообщите продавцу-консультанту, что вы хотите приобрести товар на условиях рассрочки через сервис Uzum Nasiya."
      },
      {
        heading: "Подберите удобные условия",
        title:
          "Выберите наиболее подходящий для вас срок рассрочки и сообщите об этом продавцу-консультанту. Предоставьте свой номер телефона, зарегистрированный на платформе Uzum Nasiya."
      },
      {
        heading: "Оформите рассрочку для выбранного товара",
        title:
          "Ознакомьтесь с условиями рассрочки и подтвердите покупку SMS-кодом, который придет на ваш зарегистрированный номер телефона."
      },
      {
        heading: "Наслаждайтесь покупками!",
        title:
          "Забирайте и пользуйтесь товаром, а оплачивайте позже по графику."
      }
    ]
  },
  "how-to-pay": {
    title: "Как оплачивать?",
    cards: [
      {
        heading: "Автоматическое погашение с карты пользователя",
        title:
          "Для вашего удобства сервисом Uzum Nasiya была внедрена бесплатная услуга автоматического погашения задолженности"
      },
      {
        heading: "Пополнение лицевого счета",
        title:
          "У вас есть возможность пополнить лицевой счет через карту в личном кабинете и произвести досрочное погашение вашей задолженности."
      },
      {
        heading: "Оплата через платежные системы",
        lists: [
          "Выберите оператора платежной системы: Payme, Click, Apelsin, MyUzcard, Upay",
          "Нажмите кнопку «Оплата» и найдите Uzum Nasiya в панели «Поиск»;",
          "Введите номер телефона и сумму платежа и нажмите кнопку «Оплатить»;",
          "Эти средства будут отправлены на ваш личный счет после успешной оплаты. Вы можете проверить свой личный счет в личном кабинете на платформе Uzum Nasiya."
        ]
      }
    ]
  },
  faq: {
    title: "Частые вопросы",
    register: {
      title: "РЕГИСТРАЦИЯ В СЕРВИСЕ РАССРОЧКИ",
      items: [
        {
          title: "Как начать пользоваться сервисом?",
          text: "Для начала вам необходимо пройти регистрацию на сайте uzumnasiya.uz. Понадобятся только паспорт, банковская пластиковая карта и мобильный телефон. После регистрации система анализа, разработанная специалистами компании Uzum Nasiya, определит максимальный лимит выделяемых средств клиенту. Информация с одобренной суммой отобразится в личном кабинете. Лимитом можно пользоваться сразу после регистрации."
        },
        {
          title: "Как пройти регистрацию?",
          text:
            "Регистрация проходит на сайте и в приложении uzumnasiya.uz. Для\n" +
            "                    регистрации Вам необходимо пройти поэтапно следующие шаги:",
          subItems: [
            "1. Ввести номер телефона, на который установлено смс оповещение по зарплатной пластиковой карте;",
            "2. Ввести номер банковской пластиковой карты и уникальный код с SMS-сообщения, который придет на ваш телефон. Система в автоматическом режиме определит лимит рассрочки, исходя из анализа доходов и расходов по карте",
            "3. <b>На веб-сайте:</b> Сфотографировать свой паспорт, а также сделать селфи-фотографию с паспортом. Важно: Ваш номер телефона, на который подключено смс-оповещение по карте, а также владелец паспорта и пластиковой карты должны полностью совпадать. </br> " +
            "<b>В мобильном приложении:</b> Ввести серийный номер вашего паспорта/ID-карты, а также дату рождения. Верификация происходит с помощью системы MyID. От вас потребуется пройти проверку на живность, выполнив указанные действия: посмотреть на камеру, моргнуть и улыбнуться."
          ]
        },
        {
          title: "",
          text: ""
        },
        {
          title: "",
          text: ""
        },
        {
          title: "",
          text: ""
        },
        {
          title: "",
          text: ""
        },
        {
          title: "",
          text: ""
        }
      ]
    },
    limit: {}
  },
  payServices: {
    mobile: "Мобильные операторы",
    other: "Интернет провайдеры"
  },
  "mobile-operator": "Мобильные операторы",
  categories: "Категории",
  "last-news": "Последние блоги",
  provider: "Интернет провайдеры",
  "faq-quest": [
    {
      title: "РЕГИСТРАЦИЯ В СЕРВИСЕ РАССРОЧКИ",
      id: "one",
      quest: [
        {
          head: "Как начать пользоваться сервисом?",
          title:
            "Для начала Вам необходимо пройти регистрацию на сайте uzumnasiya.uz. Понадобятся только паспорт и мобильный телефон. После регистрации система анализа, разработанная специалистами компании Uzum Nasiya, определит максимальный лимит выделяемых средств клиенту. Информация с одобренной суммой отобразится в личном кабинете, а также будет отправлена в виде смс-сообщения. Лимитом можно пользоваться сразу после регистрации.",
          height: "100px"
        },
        {
          head: "Как пройти регистрацию?",
          title:
            "Регистрация проходит на сайте и в приложении uzumnasiya.uz. Для регистрации Вам необходимо пройти поэтапно следующие шаги:",
          lists: [
            "Ввести номер телефона, на который установлено смс оповещение по зарплатной пластиковой карте;",
            "Ввести номер банковской пластиковой карты и уникальный код с SMS-сообщения, который придет на ваш телефон. Система в автоматическом режиме определит лимит рассрочки, исходя из анализа доходов и расходов по карте",
            "<b>На веб-сайте:</b> Сфотографировать свой паспорт, а также сделать селфи-фотографию с паспортом. Важно: Ваш номер телефона, на который подключено смс-оповещение по карте, а также владелец паспорта и пластиковой карты должны полностью совпадать. </br> </br> " +
            "<b>В мобильном приложении:</b> Ввести серийный номер вашего паспорта/ID-карты, а также дату рождения. Верификация происходит с помощью системы MyID. От вас потребуется пройти проверку на живность, выполнив указанные действия: посмотреть на камеру, моргнуть и улыбнуться."
          ],
          subtitle:
            "! Все данные предоставляются в компанию в зашифрованном виде.",
          height: "200px"
        },
        {
          head: "По каким причинам получают отказ при регистрации?",
          title:
            "Система, разработанная специалистами компании, без вмешательства человека, в автоматическом режиме определяет лимит рассрочки, исходя из доходов и расходов по пластиковой карте. Вам будет отказано в рассрочке, если у вас:",
          lists: [
            "ежемесячный доход меньше 1 млн сум.",
            "есть просроченные обязательства по другим кредитам.",
            "либо получали в иных магазинах и системах товар в рассрочку, за который не рассчитались вовремя."
          ],
          height: "100px"
        },
        {
          head: "Можно ли пройти повторную регистрацию, если ранее было отказано?",
          title:
            "Да, повторную регистрацию можно пройти при улучшении своей платежной истории:",
          lists: [
            "Заработная плата за последние 6 месяцев стала больше 1 млн сум.",
            "Кредиты погашены.",
            "Задолженность перед другими магазинами и системами, предоставляющими товары и услуги в рассрочку, погашена."
          ],
          height: "120px"
        },
        {
          head: "Какую пластиковую карту нужно указывать при регистрации?",
          title:
            "Если у вас несколько пластиковых карт, то необходимо указать карту с наибольшими поступлениями за последние 6 месяцев, так как система проводит анализ вашей платежеспособности по ней.",
          height: "50px"
        },
        {
          head: "Можно ли зарегистрироваться в системе дважды?",
          title:
            "Если вам ранее был одобрен определенный лимит, то повторно зарегистрироваться в системе нельзя.",
          height: "120px"
        },
        {
          head: "Сколько стоит пройти регистрацию?",
          title: "Регистрация в системе Uzum Nasiya полностью бесплатная.",
          height: "120px"
        }
      ]
    },
    {
      title: "ДОСТУПНЫЙ ЛИМИТ",
      id: "two",
      quest: [
        {
          head: "Что будет если я прошел регистрацию, но не воспользовался сервисом?",
          title:
            "Вы начинаете платить с месяца, следующего после совершения покупки в магазине. Если вы не воспользовались сервисом, то никаких выплат не будет."
        },
        {
          head: "Могу ли я повторно воспользоваться услугой рассрочки после погашения предыдущего договора?",
          title:
            "Да, после полного или частичного погашения предыдущего договора Ваш лимит восстановится и будет указан в вашем личном кабинете."
        },
        {
          head: "Можно ли воспользоваться лимитом рассрочки частично или совершить несколько покупок?",
          title:
            "Да, вы можете воспользоваться лимитом рассрочки частично и многократно. Например, сумма лимита рассрочки после прохождения регистрации составила 6 000 000 сум. Вы можете купить товар на сумму и в 500 000 сум в магазине-партнере системы. Минимальная сумма отсутствует. Оставшаяся сумма не сгорит, и Вы можете воспользоваться ей в любое удобное для вас время."
        },
        {
          head: "Что делать, если товар оказался дороже лимита рассрочки?",
          title:
            "В случае, если выбранный вами товар оказался дороже установленного лимита, это не преграда. Разницу между лимитом рассрочки и стоимостью товара можно внести через пополнение вашего лицевого счета в Системе. Данная сумма будет списана автоматически при совершении покупки, и на нее не будет применена наценка. Например, лимит рассрочки после регистрации был установлен в размере 3 000 000 сум. Выбранный вами товар стоит 3 500 000 сум. Сумму в размере 500 000 сум необходимо отправить на пополнение вашего лицевого счета. Сумма будет списана автоматически после совершения покупки. Остаток задолженности составит 3 000 000,00 сум. График платежей также будет рассчитан из расчета 3 000 000 сум. Никакой переплаты на 500 000 сум."
        },
        {
          head: "Как можно увеличить сумму лимита рассрочки?",
          title: `При своевременном погашении рассрочки клиентам предоставляется возможность увеличения суммы лимита рассрочки. Также, в случае, если вы сменили место работы и заработная плата на новом месте выше предыдущей, то вы можете обратиться в контактный центр компании и пройти повторную регистрацию. Однако, на новом месте нужно проработать не менее 6 месяцев.`
        }
      ]
    },
    {
      title: "ПЛАТЕЖИ И ГРАФИК ПОГАШЕНИЯ",
      id: "three",
      quest: [
        {
          head: "Как оплачивать за договор?",
          title:
            "Погашения по договорам рассрочки проходят в автоматическом режиме путем списания средств с пластиковой карты, без необходимости траты дополнительного времени на посещение офиса компании, банка или магазина."
        },
        {
          head: "Как узнать стоимость товаров и график платежей?",
          title:
            "При оформлении покупки продавцом магазина будет предоставлен бумажный вариант договора с полной информацией о товаре, стоимости и количестве товара, общей сумме договора, графике платежей и сумме каждого платежа, также полная информация по договору доступна в персональном кабинете клиента и информация придет смс-сообщением при оформлении договора."
        },
        {
          head: "Можно ли погашать покупку раньше срока, указанного в договоре?",
          title:
            "Да, вы имеете право на досрочное погашение всех ваших покупок. Для того, чтобы воспользоваться данной функцией в персональном кабинете, необходимо перейти в договор и совершить досрочное погашение. Досрочное погашение возможно в двух вариантах: полное досрочное погашение и частичное досрочное погашение."
        },
      ]
    },
    {
      title: "ПРОФИЛЬ И НАСТРОЙКИ",
      id: "five",
      quest: [
        {
          head: "Что делать, если на телефоне нет камеры или она очень плохая?",
          title:
            "В случае, если на вашем телефоне отсутствует камера, либо она очень плохая, то Вас может зарегистрировать продавец магазина, являющийся партнером нашей системы."
        }
      ]
    }
  ],
  partnersList: {
    about: "О партнере",
    productCategory: "Категория продуктов",
    categories: [
      "Бытовая техника",
      "Мобильные телефоны",
      "Компьютерная техника"
    ],
    text: {
      mediapark:
        "MEDIAPARK.UZ — интернет магазин, специализирующийся на продаже бытовой техники и электроники в Ташкенте и всей Республике Узбекистан. Мы предлагаем максимальный выбор продукции с оптимальным соотношением цены и качества.",
      texnomart:
        "Texnomart - это сеть магазинов бытовой техники с ценами как на базаре. Приобрести товары можно любым удобным для вас способом в магазинах сети и прямо на сайте с бесплатной доставкой до дома.",
      sandiq:
        "Sandiq.shop – один из ведущих интернет-магазинов мобильной и цифровой техники в Узбекистане. Интернет-магазин Sandiq.shop - это удобство оплаты и доставки"
    }
  },
  news: [
    {
      id: 0,
      image:
        "https://texnomart.uz/files/global/Bunny/photo_2021-04-28_11-01-22.jpg?_t=1619589751",
      heading: "✨Больше подарков — больше радостных моментов в Рамадан!",
      title:
        "В священный месяц Рамадан, покупая смартфоны Samsung Galaxy S21|S21+ или S21 Ultra, можно стать участником промо-акции и получить в подарок* планшет Galaxy A8 | A10.1.",
      href: "/news/detail",
      html:
        "Также вы автоматически становитесь участником еженедельного розыгрыша ценных призов:\n" +
        "10шт, Galaxy Watch Active 2\n" +
        "10шт, Galaxy Buds Live\n" +
        "Розыгрыш, будет проводиться в Instagram на странице. \n" +
        "Период акции: 28.04.2021 - 24.05.2021 (включительно).\n" +
        "*Количество подарков ограничено!\n" +
        "Успейте выгодно приобрести крутые гаджеты и ценные призы!\n",
      dateTime: "10.05.2021",
      dateDay: "15:25"
    },
    {
      id: 1,
      image:
        "https://texnomart.uz/files/global/Bunny/photo_2021-04-28_11-01-22.jpg?_t=1619589751",
      heading: "✨Ещё больше добрых акций в честь священного месяца Рамадан!",
      title:
        "Приобретайте смартфоны Galaxy A - серии и получайте в подарок Samsung Power Bank!\n" +
        "Смартфоны, участвующие в акции: Samsung Galaxy A31| A32| A41| A51| A52",
      href: "/news/detail",
      html:
        "Период акции: 24.04.2021 - 24.05.2021 (включительно).\n" +
        "Подарки в магазинах по адресу:\n" +
        "- г. Ташкент, площадь Чорсу, 3А (ориентир ТЦ Ташкент, бывший ГУМ);\n" +
        "- г. Ташкент, ул. Янги Шахар, 16a (oриентир торжественный зал Grand Hall, не доезжая Mega Planet).\n" +
        "Подробности акции можете найти на сайте samsung.uz\n",
      dateTime: "09.05.2021",
      dateDay: "21:00"
    },
    {
      id: 2,
      image: "https://mediapark.uz/upload/file/article/images/mp0_gor.jpg",
      heading: "✨Открытие обновленного MEDIAPARK Bozori Ипак Йўли",
      title:
        "Сеть магазинов бытовой техники MEDIAPARK поздравляет всех граждан Узбекистана с предстоящим священным праздником Хайит.",
      href: "/news/detail",
      html:
        "На 1 этаже филиала вас ждет широкий ассортимент мелкой и крупной бытовой техники, а также кухонных аксессуаров: от газовых плит и встраиваемой техники для дома до мангалов для приготовления ароматного барбекю на открытом воздухе. Компьютерная техника, телефоны, а также такая крупная бытовая техника, как холодильники, стиральные машины, кондиционеры и телевизоры, располагаются на 2 этаже.\n" +
        "Во время приобретения товаров мы предлагаем вам приятную и комфортную атмосферу, широкий ассортимент продукции от мировых брендов, официальную гарантию от сервисных центров производителей и высококачественное обслуживание от наших продавцов-консультантов. Кроме того, покупатели MEDIAPARK могут оформить выгодную рассрочку для себя и своих родных.\n" +
        "Однако это еще не все! Встречайте американский бренд Kitchen Aid и японский бренд Yamaha на полках магазина MEDIAPARK Ипак йули. Здесь представлена линейка первоклассных товаров от ведущего американского бренда Kitchen Aid: большой выбор планетарных миксеров, встраиваемой и крупной бытовой техники, тостеров, блендеров и многого другого. Также яркой новинкой в ассортименте являются товары от ведущего производителя музыкальных инструментов Yamaha, известного во всем мире.\n" +
        "Спешите приобрести товары для себя и своих родных к священному празднику Хайит. Пусть этот праздник принесёт в ваш дом и семью счастье и благополучие.\n",
      dateTime: "09.05.2021",
      dateDay: "18:15"
    },
    {
      id: 3,
      image:
        "https://mediapark.uz/upload/file/article/images/1024x200MobileNeponyatnayaShtuka.png",
      heading: "✨Впервые в Узбекистане – только в MEDIAPARK BOZORI!",
      title:
        "Вместе с покупкой смартфона, планшета или ноутбука вы можете приобрести дополнительно карту «MOBILE PROTECTION».",
      href: "/news/detail",
      text:
        "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
        "В случае любой поломки Вы можете обратиться в магазин к техническому специалисту, сдать телефон с поломкой и получить Гарантийный Сертификат «MOBILE PROTECTION», который можно обменять на любую продукцию в том же магазине\n" +
        "В результате Вы получаете комплексный сервис, позволяющий МОМЕНТАЛЬНО заменить свое поврежденное устройство на новое в случае непредвиденной ситуации!\n" +
        "Основные параметры сервиса:\n" +
        "Карта «MOBILE PROTECTION» продается дополнительно к мобильной технике (мобильные телефоны, смартфоны, планшеты и т.п.) стоимостью от 75 000 сум.\n" +
        "Срок действия Карты «MOBILE PROTECTION» - 1 год с момента приобретения мобильной техники." +
        `<br/>` +
        "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
        "Началом срока действия Карты «MOBILE PROTECTION» является дата фискального чека.\n" +
        "Условия приобретения: \n" +
        "Карту «MOBILE PROTECTION» можно пробрести только в день покупки товара.\n" +
        "Оплата Карты «MOBILE PROTECTION» должна быть отражена в кассовом чеке с той же датой, что и дата приобретения мобильного устройства.\n" +
        "Карта «MOBILE PROTECTION» действительна только при наличии кассового чека, за исключением случаев, предусмотренных законодательством Республики Узбекистан.\n" +
        "В случае утери Карты «MOBILE PROTECTION» бланк восстановлению не подлежит. \n" +
        "Для активации Карты «MOBILE PROTECTION» Вам надо пройти процесс регистрации на сайте www.mobileprotection.uz в течение 30 дней с даты покупки. \n" +
        "\n" +
        "Обмен Карты «MOBILE PROTECTION»  на Гарантийный сертификат «MOBILE PROTECTION»:\n" +
        "\n" +
        "Предъявитель Карты «MOBILE PROTECTION» имеет право на обмен поврежденного мобильного устройства на Гарантийный сертификат «MOBILE PROTECTION» в случаях, оговоренных программой:\n" +
        "Механическое повреждение - возникновение ущерба в результате внезапного механического воздействия непредвиденных физических сил при отсутствии прямой причинной связи с умышленными действиями клиента, а именно:\n" +
        "    повреждение в результате затопления, контакта с водой;\n" +
        "    повреждение в результате перепада напряжения в сети, удара электрическим током, замыкания в сети;\n" +
        "    повреждение в результате падения, удара;\n" +
        "    повреждение в результате контакта с открытым огнем, пожара, возгорания;\n" +
        "    Гарантийные поломки, предусмотренные условиями гарантии производителя; \n" +
        "Пакет документов, которые должен предоставить держатель Карты «MOBILE PROTECTION» для получения Гарантийного сертификата «MOBILE PROTECTION» в обмен на поврежденное устройство:\n" +
        "     Само мобильное устройство для осмотра сотрудником магазина \"MEDIAPARK BOZORI\" (Исключения составляют случаи пожара. В случае утраты мобильного устройства в результате пожара предоставляется соответствующая справка уполномоченного органа.)\n" +
        "    Упаковка от мобильного устройства и аксессуары от мобильного устройства, которые были частью стандартной комплектации. \n" +
        "    Гарантийный талон на мобильное устройство \n" +
        "    Копия паспорта, другого документа, удостоверяющего личность Покупателя \n" +
        "    Оригинал/копия кассового чека \n" +
        "    Оригинал Карты «MOBILE PROTECTION»\n",
      dateTime: "05.05.2021",
      html: `<div class="sale_main_text">
<span style="font-size:14px">Вместе с покупкой смартфона, планшета или ноутбука вы можете приобрести дополнительно карту «<strong>MOBILE PROTECTION».</strong></span></p>
<p><span style="font-size:14px">В случае любой поломки Вы можете обратиться в магазин к техническому специалисту, сдать телефон с поломкой и получить Гарантийный Сертификат «<strong>MOBILE PROTECTION</strong>», который можно обменять на любую продукцию в том же магазине.</span></p>
<p><span style="font-size:14px">В результате Вы получаете комплексный сервис, позволяющий МОМЕНТАЛЬНО заменить свое поврежденное устройство на новое в случае непредвиденной ситуации!</span></p>
<p>&nbsp;</p>
<p><span style="font-size:14px"><strong>Основные параметры сервиса:</strong></span></p>
<p>&nbsp;</p>
<p><span style="font-size:14px">Карта «<strong>MOBILE PROTECTION</strong>» продается дополнительно к мобильной технике (мобильные телефоны, смартфоны, планшеты и т.п.) стоимостью от 75 000 сум.<br>
Срок действия Карты «<strong>MOBILE PROTECTION</strong>» - 1 год с момента приобретения мобильной техники. Началом срока действия Карты «<strong>MOBILE PROTECTION</strong>» является дата фискального чека.</span></p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><span style="font-size:14px"><strong>Условия приобретения</strong>:&nbsp;</span></p>
<p><span style="font-size:14px">&nbsp;<br>
Карту «<strong>MOBILE PROTECTION</strong>» можно пробрести только в день покупки товара.<br>
Оплата Карты «<strong>MOBILE PROTECTION</strong>» должна быть отражена в кассовом чеке с той же датой, что и дата приобретения мобильного устройства.</span></p>
<p><br>
<span style="font-size:14px">Карта «<strong>MOBILE PROTECTION</strong>»&nbsp; действительна только при наличии кассового чека, за исключением случаев предусмотренных законодательством Республики Узбекистан.<br>
<br>
В случае утери Карты «<strong>MOBILE PROTECTION</strong>» бланк восстановлению не подлежит.&nbsp;<br>
Для активации Карты «<strong>MOBILE PROTECTION</strong>» Вам надо пройти процесс регистрации на сайте&nbsp;www.mobileprotection.uz&nbsp;в течение 30 дней с даты покупки.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style="font-size:14px"><strong>Обмен</strong> <strong>Карты</strong> «<strong>MOBILE PROTECTION</strong>» <strong>на</strong> <strong>Гарантийный</strong> <strong>сертификат</strong> «<strong>MOBILE PROTECTION</strong>»:</span></p>
<p><span style="font-size:14px">Предъявитель Карты «<strong>MOBILE PROTECTION</strong>» &nbsp;имеет право на обмен поврежденного мобильного устройства на Гарантийный сертификат «<strong>MOBILE PROTECTION</strong>» в случаях, оговоренных программой:<br>
<br>
Механическое повреждение - возникновение ущерба в результате внезапного механического воздействия непредвиденных&nbsp;физических сил при отсутствии прямой причинной связи с умышленными действиями клиента, а именно:</span></p>
<ul>
<li><span style="font-size:14px">повреждение в результате затопления, контакта с водой;</span></li>
<li><span style="font-size:14px">повреждение в результате перепада напряжения в сети, удара электрическим током, замыкания в сети;</span></li>
<li><span style="font-size:14px">повреждение в результате падения, удара;</span></li>
<li><span style="font-size:14px">повреждение в результате контакта с открытым огнем, пожара, возгорания;</span></li>
<li><span style="font-size:14px">Гарантийные поломки, предусмотренные условиями гарантии производителя;&nbsp;</span></li>
</ul>
<p><span style="font-size:14px"><strong>Пакет документов</strong>, которые должен предоставить держатель Карты «<strong>MOBILE PROTECTION</strong>» для получения Гарантийного сертификата «<strong>MOBILE PROTECTION</strong>» в обмен на поврежденное устройство:</span><br>
&nbsp;</p>
<ul>
<li><span style="font-size:14px">Само мобильное устройство для осмотра сотрудником магазина <strong>"</strong><strong>MEDIAPARK</strong> <strong>BOZORI</strong><strong>"</strong> (Исключения составляют случаи пожара. В случаи утраты мобильного устройства в результате пожара предоставляется соответствующая справка уполномоченного органа.)</span></li>
<li><span style="font-size:14px">Упаковка от мобильного устройства и аксессуары от мобильного устройства, которые были частью стандартной комплектации.&nbsp;</span></li>
<li><span style="font-size:14px">Гарантийный талон на мобильное устройство&nbsp;</span></li>
<li><span style="font-size:14px">Копия паспорта, другого документа, удостоверяющего личность Покупателя&nbsp;</span></li>
<li><span style="font-size:14px">Оригинал/копия кассового чека&nbsp;</span></li>
<li><span style="font-size:14px">Оригинал Карты «<strong>MOBILE PROTECTION</strong>»</span></li>
</ul>
<p><span style="font-size:14px">В обмен на пакет документов и поврежденное устройство клиент получает Гарантийный сертификат «<strong>MOBILE PROTECTION</strong>» который может обменять на любую продукцию в магазине &nbsp;«<strong>MEDIAPARK</strong> <strong>BOZORI</strong>».</span><br>
&nbsp;</p>
<p><span style="font-size:14px"><strong>Карта «</strong><strong>MOBILE PROTECTION</strong><strong>» не распространяется на:</strong></span><br>
&nbsp;</p>
<ul>
<li><span style="font-size:14px">Комплектующие материалы (зарядные устройства, наушники, аккумуляторы и т.п)&nbsp;</span></li>
<li><span style="font-size:14px">Косметические дефекты корпуса, не влияющие на нормальное функционирование мобильного устройства (трещины корпуса и экрана, царапины, оплавления)&nbsp;</span></li>
<li><span style="font-size:14px">Устройства, у которых обнаружены следы вскрытия корпуса;&nbsp;</span></li>
<li><span style="font-size:14px">Использование аксессуаров, не предусмотренных изготовителем;&nbsp;</span></li>
<li><span style="font-size:14px">Наличие в изделии посторонних предметов.</span></li>
</ul>
<p>&nbsp;</p>
<p><span style="font-size:14px"><strong>Спецификация Карты </strong>«<strong>MOBILE PROTECTION</strong>»<strong> в зависимости от стоимости устройства:</strong></span></p>
<p><img alt="" class="img-responsive" src="/upload/file/article/images/PRO.png"></p>
<p><span style="font-size:14px">Карты «<strong>MOBILE PROTECTION</strong>» можно приобрести во всех розничных магазинах сети «<strong>MEDIAPARK</strong> <strong>BOZORI</strong>».</span></p>
</div>`,
      dateDay: "09:20"
    }
    // {
    //   heading: "Nunc neque vulputate ut.",
    //   title:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla gravida porta aenean quam aenean quam enim sit a. Nunc nunc vulputate donec nulla eu lacus.",
    //   href: "/",
    //   dateTime: "СЕГОДНЯ",
    //   dateDay: "13:23",
    // },
  ],
  contractPage: {
    qrCode: `Отсканируйте <strong>QR-код</strong>, чтобы скачать мобильное приложение и активировать договор `,
    qrCodeSec: `<strong>Чек оплаты</strong>`,
    qrCodeMobile: `Скачайте мобильное приложение, чтобы активировать договор.`,
    downloadBtn: "Скачать мобильное приложение",
    cashBack: 'Вы имеете право на <span style="color:#6610F5">1% Cashback</span> от вашей покупки'
  }
};
