export default {
  authorizationFailed: {
    title: "Вы не прошли регистрацию",
    text: "Для прохождения регистрации, скачайте наше приложения по ссылке:"
  },
  currency: "{price} сум",
  month: "{number}{hyphen}месяц",
  phone: "Тел: {number}",
  akciyaFrom: "Акция от {title}",
  "mobile_operator_type": "Префикс код {operator_type} неверный",
  auth: {
    personalData: "Согласие на обработку <a href=\"/ru/politic-confidence\" target=\"_blank\" class=\"text-main\">персональных данных</a>"
  },
  map: {
    label: "Точки по городу ({number})"
  },
  nds: "(c НДС)",
  description: "Описание",
  installmentMonth: "/ 12 мес.",
  halyal: {
    title: "Халяльность Uzum Nasiya",
    text: "Uzum Nasiya предоставляет свои услуги по нормам и канонам шариата, что также говорит об открытости и честности сервиса. Это подтверждено соответствующим документом Управления мусульман Республики Узбекистан. Халяльность системы рассрочки Uzum Nasiya заключается в том, что у нас нет никаких скрытых платежей, штрафов и пени. Рассрочка от Uzum Nasiya не предусматривает и задолженностей, что также отличает нас от кредита. Совершая покупки с Uzum Nasiya, вы всегда точно знаете когда закончите платить."
  },
  vacancies: 'Вакансии',
  search: 'Найти',
  selectCategoryQuestion: 'Выберите категорию вопроса',
};