<template>
  <div
    :class="{ 'sidebar-mobile': true, active }"
    @mousedown="(e) => e.preventDefault()"
  >
    <div class="sidebar-user">
      <div class="user-img">
        <img src="../../assets/profile_assets/Vector.png" alt="" />
      </div>
      <div v-if="userInfo" class="user-info">
        <h3>{{ userInfo?.name }} {{ userInfo?.surname }}</h3>
        <p>{{ userInfo?.phone }}</p>
      </div>
    </div>

    <div class="balance-sidebar">
      <div class="balance-top">
        <div class="left">
          <div class="icon">
            <img
              src="../../assets/profile_assets/cash.svg"
              alt=""
              width="21"
              height="21"
            />
          </div>

          <div class="balance-info">
            <h3>Ваш баланс:</h3>
            <p>
              {{ $t('currency', { price: $numberFormat(balance.all) }) }}
            </p>
          </div>
        </div>

        <div class="right">
          <img src="../../assets/profile_assets/chevron-down.svg" alt="" />
        </div>
      </div>

      <div class="balance-body">
        <div class="limit">
          <h3>{{ localize('balancePopup')['limit'] }}</h3>
          <h4>{{ localize('balancePopup')['possible'] }}</h4>
          <p>
            {{ $t('currency', { price: $numberFormat(balance.installment) }) }}
          </p>
        </div>
        <div class="installment">
          <div class="top">
            <h3>{{ localize('balancePopup')['personalBalance'] }}:</h3>

            <div class="link cursor-pointer" @click="pushRoute">
              <span>{{ localize('balancePopup')['topUp'] }}</span>
              <img src="../../assets/profile_assets/add.svg" alt="" />
            </div>
          </div>

          <h4>{{ localize('balancePopup')['yourDeposit'] }}</h4>

          <div class="price">
            {{ $t('currency', { price: $numberFormat(balance.deposit) }) }}
          </div>
        </div>
      </div>
    </div>

    <div class="links-sidebar">
      <div class="links-top">
        <ul>
          <li v-for="(n, i) in links" :key="i" @click="closeSidebar">
            <router-link :to="{ name: n.href }" tag="a" class="sidebar_link">
              <span v-html="n.icon"></span>
              <p>{{ n.title }}</p>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="links-bottom"></div>
    </div>

    <div class="lang">
      <div
        class="lang-toggle"
        @click="openLang(defaultLang ? defaultLang.title : 'Русский')"
      >
        <div class="left">
          <img :src="defaultLang ? defaultLang.icon : ruIcon" alt="" />
          <p>{{ defaultLang ? defaultLang.title : 'Русский' }}</p>
        </div>
        <div class="right">
          <img src="../../assets/profile_assets/chevron-down.svg" alt="" />
        </div>
      </div>
      <div class="lang-menu">
        <div
          class="lang-item"
          @click="changeLang(n)"
          v-for="(n, i) in lang"
          :key="i"
        >
          <img :src="n.icon" alt="" />
          <p>{{ n.title }}</p>
        </div>
      </div>
    </div>

    <div class="support">
      <ul>
        <li>
          <a href="tel:+998712419666">
            <div class="icon">
              <img
                src="../../assets/profile_assets/customer-support.svg"
                alt=""
              />
            </div>
            <div class="title">
              <h4>{{ localize('sidebar')['technicalSupport'] }}</h4>
              <p>{{callCenterNumber}}</p>
            </div>
          </a>
        </li>
        <li>
          <a href="#!" @click.prevent="logout">
            <div class="icon">
              <img src="../../assets/profile_assets/log-out.svg" alt="" />
            </div>
            <div class="title">
              <p>{{ localize('button')['logout'] }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ruIcon from '@/assets/icons/rus.png';
import uzbIcon from '@/assets/icons/uzb.png';
import { mapGetters } from 'vuex';
import $ from 'jquery';
import localize from '../../filters/localize.filter';
import config from '@/config';

export default {
  props: {
    active: {
      type: Boolean,
    },
  },
  data: () => ({
    callCenterNumber: config.paymartSupportPhone,
    ruIcon,
    uzbIcon,
    defaultLang: null,
    uzbLang: {
      icon: uzbIcon,
      title: 'O\'zbekcha',
    },
    lang: [
      {
        icon: ruIcon,
        title: 'Русский',
      },
      {
        icon: uzbIcon,
        title: 'O\'zbekcha',
      },
    ],
    links: [
      // {
      //   icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      //           <path d="M14.282 20.241C13.83 21.033 12.977 21.567 12 21.567C11.022 21.567 10.169 21.033 9.71699 20.24M2.66699 17.318H21.334L19.174 14.09C18.844 13.596 18.667 13.016 18.667 12.422V9.33497C18.667 5.65297 15.682 2.66797 12 2.66797C8.31799 2.66797 5.33299 5.65297 5.33299 9.33497V12.423C5.33299 13.017 5.15699 13.598 4.82599 14.091L2.66699 17.318Z" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      //         </svg>
      //   `,
      //   href: "/notify",
      //   title: "Уведомление",
      // },
      {
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.927 9.55722V9.55722C22.0459 9.15225 22.0132 8.71783 21.835 8.33522L19.9 3.75722V3.75722C19.5557 2.9826 18.7826 2.48803 17.935 2.50022H6.065V2.50022C5.21741 2.48803 4.44427 2.9826 4.1 3.75722L2.165 8.33522H2.165C1.9868 8.71783 1.95409 9.15225 2.073 9.55722V9.55722C2.43578 10.5625 3.39744 11.2259 4.466 11.2082H4.466C5.81479 11.2446 6.93833 10.182 6.977 8.83322V8.83322C7.01567 10.1824 8.13982 11.2452 9.489 11.2082H9.489C10.8378 11.2446 11.9613 10.182 12 8.83322V8.83322C12.0733 10.2203 13.2571 11.2853 14.6441 11.2121C15.9288 11.1442 16.9552 10.1179 17.023 8.83322V8.83322C17.0617 10.182 18.1852 11.2446 19.534 11.2082V11.2082C20.6026 11.2259 21.5642 10.5625 21.927 9.55722V9.55722Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M19.5 13V21.5H4.5V13" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M17.5 12.5V16.5C17.5 17.0523 17.0523 17.5 16.5 17.5H7.5V17.5C6.94771 17.5 6.5 17.0523 6.5 16.5V16.5V12.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
        `,
        href: 'partners',
        title: localize('banner')['magazines']['title'],
      },
      {
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M5.33301 19.8412V15.3342C5.33301 14.2292 6.22801 13.3342 7.33301 13.3342H16.666C17.771 13.3342 18.666 14.2292 18.666 15.3342V19.8412M14.9634 7.12316C14.9634 8.75958 13.6368 10.0862 12.0004 10.0862C10.364 10.0862 9.03738 8.75958 9.03738 7.12316C9.03738 5.48674 10.364 4.16016 12.0004 4.16016C13.6368 4.16016 14.9634 5.48674 14.9634 7.12316Z" stroke="#1e1e1e" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        href: 'profile-index',
        title: 'Профиль',
      },
      {
        icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M6.66699 8.00017H17.333M6.66699 12.0002H17.333M6.66699 16.0002H17.333M18.333 21.4932H5.66699C4.00999 21.4932 2.66699 20.1502 2.66699 18.4932V5.82617C2.66699 4.16917 4.00999 2.82617 5.66699 2.82617H18.334C19.991 2.82617 21.334 4.16917 21.334 5.82617V18.4932C21.333 20.1502 19.99 21.4932 18.333 21.4932Z" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
        href: 'profile.contracts',
        title: 'Договора',
      },
      {
        icon: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 4.00098V17.427C4 18.847 5.151 19.998 6.571 19.998H20M8 15.999V10.667M12 16V8.00098M16 15.999V12.001M20 15.999V5.33498" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        href: 'profile.history',
        title: 'История',
      },
      {
        icon: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.99999 5.33496H16M7.99999 5.33496L2.66699 10.667M7.99999 5.33496L12.175 10.667L16 5.33496M16 5.33496L21.333 10.667L11.718 21.332M11.718 21.332L2.66699 10.667M11.718 21.332L9.33299 13.333M2.66699 10.667H16" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>`,
        href: 'profile.bonus',
        title: 'Бонусные суммы',
      },
      // {
      //   icon: `
      //   <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      //   <path d="M20.435 16C18.937 19.153 15.723 21.334 12 21.334C6.84499 21.334 2.66699 17.155 2.66699 12.001C2.66699 8.27799 4.84699 5.06399 7.99999 3.56599M12 2.66699V12H21.333C21.333 6.84499 17.155 2.66699 12 2.66699Z" stroke="#1e1e1e" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      //   </svg>
      //   `,
      //   href: "/request-frient",
      //   title: "Приглосить друга",
      // },
      {
        icon: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.87 6.22606L7.741 14.3551C6.96 15.1361 6.96 16.4021 7.741 17.1831C8.522 17.9641 9.788 17.9641 10.569 17.1831L18.697 9.05506C20.259 7.49306 20.259 4.96006 18.697 3.39806C17.135 1.83606 14.602 1.83606 13.04 3.39806L4.915 11.5231C2.57 13.8681 2.57 17.6701 4.915 20.0151C7.26 22.3601 11.062 22.3601 13.407 20.0151L20.589 12.8331" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        `,
        href: 'https://uzumnasiya.uz/doc/publicOffer.pdf',
        title: 'Публичная офферта',
      },
    ],
  }),
  computed: {
    ...mapGetters('buyers', ['balance', 'userInfo']),
  },
  methods: {
    localize,
    logout() {
      this.$cookieRemove('token');
      localStorage.removeItem('user_info');
      localStorage.removeItem('number');
      localStorage.removeItem('card_hash');
      localStorage.removeItem('hash');
      localStorage.removeItem('check');

      this.$store.dispatch('buyers/signOut');
      this.$router.push({ name: 'home' });
      // window.location.reload();
    },
    openLang(title) {
      let lang = [
        {
          icon: ruIcon,
          title: 'Русский',
        },
        {
          icon: uzbIcon,
          title: 'O\'zbekcha',
        },
      ];
      lang.filter((n) => n.title !== title);
    },
    changeLang(n) {
      this.defaultLang = n;
      if (n.title == 'Русский') {
        localStorage.setItem('lang', 'ru-RU');
        this.$i18n.locale = 'ru';
        this.$router.push({ params: { lang: 'ru' } });
        window.location.reload();
      } else {
        localStorage.setItem('lang', 'uz-UZ');
        this.$i18n.locale = 'uz';
        this.$router.push({ params: { lang: 'uz' } });
        window.location.reload();
      }
    },
    closeSidebar() {
      this.$emit('close-sidebar', { active: false });
    },
    pushRoute() {
      this.$router.push({ name: 'profile.balance-up' });
      this.$emit('closeDropdown', { active: false });
    },
  },
  mounted() {
    let langToggle = $('.lang-toggle');
    let langItem = $('.lang-item');
    let langMenu = $('.lang-menu');
    let balanceTop = $('.balance-top');
    let balanceBody = $('.balance-body');

    balanceTop.click(function () {
      balanceBody.slideToggle();
    });

    langToggle.click(function () {
      langMenu.slideToggle();
      $(this).slideUp();
    });

    langItem.click(function () {
      langMenu.slideToggle();
      langToggle.slideDown();
    });
  },
  created() {
    // this.$axios.get(`/buyer/balance`)
    //   .then((res) => {
    //     const { data } = res;
    //     let balance = data?.data;
    //     this.$store.dispatch('buyers/fetchBalance', balance || 0);
    //   });

    // this.$axios.get(`/buyers/detail`)
    //   .then((res) => {
    //     const { data } = res;
    //     const info = data.data;
    //     this.$store.dispatch('buyers/fetchUserInfo', info);
    //   });
  },
};
</script>

<style lang="scss" scoped>
  .sidebar-mobile {
    width: 320px;
    background: $white;
    position: fixed;
    top: 96px;
    left: 0;
    height: calc(100vh - 96px);
    z-index: 900;
    padding-top: 27px;
    padding-bottom: 32px;
    overflow: auto;
    transition: 0.5s;
    transform: translateX(-400px);

    &::-webkit-scrollbar {
      width: 3px;
    }

    &.akciya {
      top: 108px;
    }

    &.active {
      transform: translateX(0px);
    }

    .sidebar-user {
      display: flex;
      align-items: center;
      padding: 0 16px;
      margin-bottom: 15px;

      .user-img {
        width: 48px;
        height: 48px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 12px;
      }

      .user-info {
        h3 {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
        }

        p {
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #333333;
        }
      }
    }

    .balance-sidebar {
      .balance-top {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px;

        .left {
          display: flex;
          align-items: center;

          .icon {
            background: rgba(102, 16, 245, 0.1);
            border-radius: 8px;
            padding: 5.5px;
            display: flex;
            align-items: center;
            margin-right: 16px;
          }

          .balance-info {
            h3 {
              font-weight: bold;
              font-size: 14px;
              line-height: 17px;
              display: flex;
              align-items: center;
              color: $black;
              margin-bottom: 4px;
            }

            p {
              font-weight: 900;
              font-size: 16px;
              line-height: 20px;
              display: flex;
              align-items: center;
              color: $black;

              span {
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                display: flex;
                align-items: flex-end;
                color: $black;
                opacity: 0.3;
                margin-left: 12px;
              }
            }
          }
        }

        .right {
          img {
            transform: rotate(-90deg);
          }
        }
      }

      .balance-body {
        display: none;
        padding: 24px 16px 37px 16px;

        .limit {
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(30, 30, 30, 0.1);

          h3 {
            font-weight: bold;
            font-size: 16px;
            line-height: 28px;
            display: flex;
            align-items: center;
            color: $black;
          }

          h4 {
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: $black;
            margin-bottom: 12px;
          }

          p {
            font-weight: 900;
            font-size: 20px;
            line-height: 25px;
            color: $black;

            span {
              font-weight: bold;
              font-size: 20px;
              line-height: 24px;
              color: $black;
              opacity: 0.3;
              margin-left: 12px;
              display: inline-block;
            }
          }
        }

        .installment {
          padding-top: 13px;

          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .link {
              display: flex;
              align-items: center;
              font-weight: normal;
              font-size: 14px;
              line-height: 111.63%;
              color: $main;
            }

            h3 {
              font-weight: bold;
              font-size: 16px;
              line-height: 28px;
              color: $black;
            }
          }

          h4 {
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: $black;
            margin-bottom: 12px;
          }

          .price {
            font-weight: 900;
            font-size: 20px;
            line-height: 25px;
            color: $main;

            span {
              font-weight: bold;
              font-size: 20px;
              line-height: 24px;
              color: $black;
              opacity: 0.3;
              display: inline-block;
              margin-left: 12px;
            }
          }
        }
      }
    }

    .lang {
      margin-bottom: calc(98px - 16px);

      .lang-toggle {
        display: flex;
        padding: 16px;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .left {
          display: flex;
          align-items: center;

          p {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $black;
          }

          img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }

        .right {
          transform: rotate(-90deg);
        }
      }

      .lang-menu {
        display: none;

        .lang-item {
          padding: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 18px;
            height: 18px;
            object-fit: cover;
            margin-right: 10px;
          }

          p {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: $black;
          }
        }
      }
    }

    .support {
      ul {
        li {
          a {
            padding: 16px;
            display: flex;
            align-items: center;
            transition: 0.33s;

            h4 {
              font-weight: 600;
              font-size: 14px;
              line-height: 20px;
              color: $black;
            }

            p {
              font-size: 14px;
              line-height: 20px;
              color: $black;
            }

            .icon {
              padding: 3px;
              margin-right: 10px;
            }

            &:hover {
              background: $grey;
            }
          }
        }
      }
    }
  }

  a.sidebar_link {
    padding: 16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: $black;
    transition: 0.33s;

    &:hover {
      color: $main;

      span {
        svg path {
          stroke: #6610F5;
        }
      }
    }

    &.active {
      color: $white;
      background: $main;

      span {
        svg path {
          stroke: $white;
        }
      }
    }

    span {
      margin-right: 10px;
    }
  }

  @media (max-width: 575px) {
    .sidebar-mobile {
      top: 61px;
      height: calc(100vh - 48px);

      .lang {
        margin-bottom: 0;
      }
    }
  }
</style>
