<template>
  <div class="lds-ring" :class="size">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  props: { size: { type: String } },
};
</script>

<style lang="scss" scoped>
  .lds-ring {
    position: relative;
    margin: 0 auto;

    &.sm {
      width: 50px;
      height: 50px;

      div {
        width: 30px;
        height: 30px;
        border-width: 3px;
      }
    }

    &.md {
      width: 70px;
      height: 70px;

      div {
        width: 60px;
        height: 60px;
      }
    }

    &.lg {
      width: 100px;
      height: 100px;

      div {
        width: 90px;
        height: 90px;
      }
    }

    &.xl {
      width: 130px;
      height: 130px;

      div {
        width: 120px;
        height: 120px;
      }
    }

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      margin: 8px;
      border: solid #6610F5;
      border-width: 5px;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $main transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
</style>
