<template>
  <nav
    id="navbarScroll"
    class="pay_navbar orange_navbar"
    :class="{ 'scrollAnim noBlur': noBlur }"
  >
    <div class="container">
      <div class="row">
        <li @click="sidebarClose" class="navbar_brand">
          <router-link :to="logoLink" class="pay_brand scrollLink">
            <img :src="logo" alt="payMarket_logo" />
          </router-link>
        </li>

        <div class="pay_left">
          <ul>
            <li @click="sidebarOpen">
              <a  href="javascript:">
                {{ 'navbar_client' | localize }}
              </a>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.5303 8.03033C15.8232 7.73744 15.8232 7.26256 15.5303 6.96967C15.2374 6.67678 14.7626 6.67678 14.4697 6.96967L15.5303 8.03033ZM10.5893 11.9107L10.0589 11.3804L10.0589 11.3804L10.5893 11.9107ZM9.41074 11.9107L9.94107 11.3804L9.94107 11.3804L9.41074 11.9107ZM5.53033 6.96967C5.23744 6.67678 4.76256 6.67678 4.46967 6.96967C4.17678 7.26256 4.17678 7.73744 4.46967 8.03033L5.53033 6.96967ZM14.4697 6.96967L10.0589 11.3804L11.1196 12.4411L15.5303 8.03033L14.4697 6.96967ZM9.94107 11.3804L5.53033 6.96967L4.46967 8.03033L8.88041 12.4411L9.94107 11.3804ZM10.0589 11.3804C10.0264 11.413 9.97362 11.413 9.94107 11.3804L8.88041 12.4411C9.49874 13.0594 10.5013 13.0594 11.1196 12.4411L10.0589 11.3804Z"
                  fill="#fff" />
              </svg>
            </li>

            <li
              v-for="(n, i) in itemsLeft"
              :key="i"
              @click.prevent="sidebarClose"
            >
              <router-link
                :to="n.to"
                active-class="pay_link_active"
                class="scrollLink"
                exact
              >
                {{ n.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <!--        <a href="javascript:" class="pay_login scrollLink" @click="installPWA">-->
        <!--          <img :src="phone" alt="" />-->
        <!--          {{ "navbar_download-app" | localize }}-->
        <!--        </a>-->
        <li @click="sidebarClose" style="display: flex; align-items: center">
          <router-link :to="{ name: 'auth' }" class="pay_login scrollLink">
            <img :src="logouts" alt="" />
            {{ 'navbar_singIn' | localize }}
          </router-link>
        </li>
        <lang :white="white" />
      </div>
    </div>
  </nav>
</template>

<script>
import logo from '@/assets/uzum-logo-white.svg';
import logoMain from '@/assets/uzum-logo.svg';
import logout from '../assets/icons/logoutOrange.svg';
import logoutMain from '../assets/logout.svg';
import phoneIcon from '../assets/icons/phoneIcon.svg';
import phonewhite from '../assets/icons/phonewhite.svg';
import lang from './lang.vue';
import $ from 'jquery';
import config from '../config';

export default {
  name: 'navigate',
  props: {
    itemsLeft: {
      type: Array,
    },
    itemsRight: {
      type: Array,
    },
    noBlur: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
  },
  data: () => ({
    logoLink: config.logoLink,
    installable: false,
    deferredPrompt: null,
    logo,
    logouts: logout,
    phone: phonewhite,
    white: true,
  }),
  components: { lang },
  computed: {
    toggleTopPopup() {
      return !this.active
    }
  },
  methods: {
    async installPWA() {
      this.deferredPrompt.prompt();
    },
    langHandler(e) {
      this.defaultLang = e;
    },
    sidebarOpen() {
      this.$emit('sidebarOpens', { active: this.toggleTopPopup });
      this.logo = this.toggleTopPopup ? logoMain : logo;
      this.logouts = this.toggleTopPopup ? logoutMain : logout;
      this.phone = this.toggleTopPopup ? phoneIcon : phonewhite;
      this.white = !this.toggleTopPopup;
      document.body.style.overflow = this.toggleTopPopup ? 'hidden' : 'auto';
    },
    sidebarClose() {
      this.$emit('sidebarClose', { active: false });
      this.logo = logo;
      this.logouts = logout;
      this.phone = phonewhite;
      this.white = true;
      document.body.style.overflow = 'auto';
    },
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
    });
  },
  mounted() {
    window.addEventListener('scroll', () => {
      if (document.querySelector('#navbarScroll')?.classList.contains('scrollAnim')) {
        this.logo = logoMain;
        this.logouts = logoutMain;
        this.phone = phoneIcon;
        this.white = false;
      } else {
        this.logo = logo;
        this.logouts = logout;
        this.phone = phonewhite;
        this.white = true;
      }
    });
    document.querySelector('.overlayes').addEventListener('click', () => {
      let $scroll = $(window).scrollTop();
      let scrollNav = document.querySelector('#scrollNav');
      if (scrollNav) {
        if ($scroll >= scrollNav.offsetTop) {
          $('.pay_navbar').addClass('scrollAnim');
          this.logo = logoMain;
          this.logouts = logoutMain;
          this.phone = phoneIcon;
          this.white = false;
        }
        if ($scroll <= scrollNav.offsetTop) {
          $('.pay_navbar').removeClass('scrollAnim');
          this.logo = logo;
          this.logouts = logout;
          this.phone = phonewhite;
          this.white = true;
        }
      }
      document.body.style.overflow = 'auto';
    });
  },
};
</script>
<style lang="scss" scoped>
  @import "../assets/css/navbarOrange.scss";

  @media (max-width: 991px) {
    .pay_navbar {
      display: none !important;
    }
  }
</style>
