import { versionUz } from "../data/politic-confidence";

export default {
  bonusTransfer: "Bonus o'tkazmalari",
  commisionAmount: "Komissiya bilan birga summa:",
  selectCard: "O'tkazish uchun kartani tanlang",
  setPrice: "Summani kiriting",
  BonusPay: {
    "form-title": "Login"
  },
  overUZB: 'Butun O\'zbekiston bo\'ylab',
  'not-found': 'Hech narsa topilmadi.',
  'choose-region': 'Hududni tanlang',
  selectCategoryQuestion: 'Savol toifasini tanlang',
  vacancies: 'Ish o\'rinlari',
  mobile_operator_type: "{operator_type} prefix kodi xato",
  documentTitle: "Uzum Nasiya - muddatli to'lov uchun qulay servis!",
  navbar_client: "Xaridorlar uchun",
  navbar_business: "Hamkorlar uchun",
  "navbar_download-app": "Ilovani yuklab olish",
  navbar_singIn: "Kirish",
  user: "Foydalanuvchi",
  buyFromUzumNasiya: "Uzum Nasiyada xarid qiling",
  sidebar: {
    contactNumber: "Kontakt-markazi:",
    technicalSupport: "Texnik yordam:",
    more: "Kelasi o'yindagi sovg'alar"
  },
  footer: {
    support: {
      title: "Qo'llab-quvvatlash xizmati",
      "work-time": "Dushanba - Yakshanba",
      confidence: "Maxfiylik siyosati",
      oferta: "Ommaviy oferta"
    },
    installments: {
      title: "Savollar",
      "what-is": "Xizmatdan qanday foydalanish mumkin?",
      take: "Mahsulot narxi taqdim etilgan limit miqdoridan qimmat bo’lsa nima qilish kerak?",
      verification: "To’lovni qanday amalga oshirish mumkin?",
      pay: "To'lovlar qanday amalga oshiriladi ?"
    },
    info: {
      title: "Ma`lumot",
      "how-to-works": "Xizmat qanday ishlaydi?",
      partners: "Bizning hamkorlarimiz",
      bonuses: "Bonuslar",
      news: "Bloglar",
      blog: "Blog"
    },
    address: {
      country: "O'zbekiston, Toshkent sh.",
      region: "Yakkasaroy tumani",
      street:
        "Shota Rustaveli ko’chasi (Vosit Vohidov ko'chasi) 12-bino (Mo'ljal: Anjir kafesi, Ucell ofisi)",
      socials: "Biz ijtimoiy tarmoqlarda",
      app: "Uzum Nasiya mobil ilovasi"
    }
  },
  "footer-bottom": {
    "left-title": '<a href="https://solutionslab.uz" target="_blank">SOLUTIONS LAB, AJ ©</a> 2022. Barcha huquqlar himoyalangan.',
    "refreshed-at": "Oxirgi yangilanishlar"
  },
  infoMessage:
    "Ваша заявка принята. В скором времени с вами свяжутся наши Менеджеры.",
  error: {
    error_card_sms_off: "SMS xabarnoma ulanmagan",
    errorPage: { title: "Sahifa topilmadi(" },
    emptyPage: {
      otherContract: "Bu sizning shartnomangiz emas.",
      otherContractLogout: "Shaxsiy profilga o'ting"
    },
    empty_card: "Hisobingizda yetarli mablag' mavjud emas!",
    error_scoring: "Karta ro'yhatdan o'ta olmadi",
    contract_out_of_date: "Shartnomani faollashtirish muddati o'tgan",
    successPage: { title: "Shartnoma muvaffaqiyatli saqlandi" },
    text: "Xatolik !",
    upload: "Iltimos, fotosuratlarni yuklang",
    passport: "Pasportni o'qishda xato, yangi rasm yuklang",
    selfie: "Pasportda selfi o'qishda xato, yangi surat yuklang",
    term: "Karta ma'lumotlari xato kiritilgan. Iltimos, tekshirib ko'ring",
    "empty-name": "Iltimos FIO kiriting",
    "sms-check":
      "Kartaga biriktirilgan telefon raqami sizning telefon raqamingizga to'g'ri kelmayapti. Iltimos nomerga mos kartani kiriting yoki boshqa nomer orqali ro'yxatdan o'ting",
    "card-has": "Bu karta allaqachon ro'yhatdan o'tgan",
    "enough-money":
      "Sizning kartangiz talabga javob bermaydi! Kartangizga so'nggi 6 oy ichida oylik tushumlar 1 million so'mdan yuqori bo'lishi kerak. Boshqa kartani biriktirishga urunib ko'ring.",
    empty: "Iltimos bo'sh qolgan joylarni to'ldiring",
    code: "Kod hato kiritilgan!",
    sms_not_sended: "SMS yuborishda xatolik. Yana bir bor urinib ko'ring.",
    serviceUnavailable:
      "Prosessing xizmati vaqtincha ishlamayapti, keyinroq qayta urinib ko'ring.",
    message: {
      notSms: "Kod xato kiritilgan. Yana bir bor urinib ko'ring.",
      unCurretCartNumber: "UZCARD/HUMO karta raqami noto'g'ri kiritilgan.",
      unCurretTelNumber: "Iltimos, avval telefon raqamini kiriting.",
      applicationLoading: "Sizning arizangiz ko'rib chiqilmoqda.",
      contract_out_of_date: "Shartnomani rasmiylashtirish muddati tugadi."
    },
    cardAdd: {
      "card not fill": "Karta raqami noto'g'ri kiritilgan.",
      "exp not fill": "Kart sanasi noto'g'ri kiritilgan.",
      c_card_error: "Siz korporativ kartani qo'sha olmaysiz",
      invalid_card: "Noma'lum karta turi.",
      more_than_12: "12 dan katta raqam",
      more_than_31: "Kartaning amal qilish muddati 2031-yildan oshadi",
      uzcard_denied: "Uzcardda vaqtinchalik nosozlik!"
    },
    // TODO refactored errors
    user_is_blocked: "Ushbu foydalanuvchi bloklangan",
    sms_code_not_equal: "SMS kod noto'g'ri "
  },
  "app.amount_not_fill": "Balansda yetarlicha mablag' yo'q.",
  "app.amount_incorrect": "Summani kiriting",
  card_already_exists: "Bu karta mavjud",
  loading: {
    title: "Arizangiz ko'rib chiqilmoqda",
    text: "Bu bir necha daqiqa olishi mumkin",
    waiting: "Sizning arizangiz ko'rib chiqilmoqda, iltimos kuting."
  },
  "sign-up": {
    title: "Kabinetga kirish",
    "form-label": "Telefon raqami",
    "consent-title": "Shaxsiy ma'lumotlarni qayta ishlashga rozilik",
    "phone-number": "Telefon raqamini tanlang",
    sumPlaceholder: "Summani kiriting"
  },
  politicConfidence: {
    title: "Мaxfiylik siyosati",
    content: versionUz
  },
  "sms-code": {
    title: "SMS kodini kiriting",
    text: "Raqamga yuborildi",
    timer: "Qayta SMS yuboring",
    "timer-value": "(через ",
    "timer-time": " сек.)",
    "timer-time-uz": " soniya"
  },
  "card-sign": {
    sidebar: {
      "step-1": "Telefon raqami biriktirilishi",
      "step-2": "To'lov kartasi ma'lumotlarini kiritish",
      "step-3": "Pasport bosh sahifasi rasmi",
      "step-4": "Pasport propiska rasmi",
      "step-5": "Pasport bilan selfi rasmi",

      "step-id-1": "Telefon raqami biriktirilishi",
      "step-id-2": "To'lov kartasi ma'lumotlarini kiritish",
      "step-id-3": "ID karta bosh sahifasi rasmi",
      "step-id-4": "ID karta propiska rasmi",
      "step-id-5": "ID karta bilan selfi rasmi"
    },
    title: "Karta raqamini kiriting",
    text: "Tabriklaymiz! Iltimos, tezkor va bir martalik ro'yxat jarayonidan o'ting.",
    number: "Karta raqami",
    exp: "Karta muddati",
    confirmSms: "SMS orqali tasdiqlash.",
    successAdded: "Кarta muvaffaqiyatli qo'shildi.",
    "agree-with": "Ommaviy oferta bilan",
    "public-oferta": "rozilik",
    "politic-confidence": "Kompaniyaning kiberxavfsizlik siyosati"
  },
  regions: [
    { id: 1703, name: "Andijon viloyati" },
    { id: 1706, name: "Buxoro viloyati" },
    { id: 1708, name: "Jizzax viloyati" },
    { id: 1710, name: "Qashqadaryo viloyati" },
    { id: 1712, name: "Navoiy viloyati" },
    { id: 1714, name: "Namangan viloyati" },
    { id: 1718, name: "Samarqand viloyati" },
    { id: 1722, name: "Surxandaryo viloyati" },
    { id: 1724, name: "Sirdaryo viloyati" },
    { id: 1726, name: "Toshkent shahri" },
    { id: 1727, name: "Toshkent viloyati" },
    { id: 1730, name: "Farg`ona viloyati" },
    { id: 1733, name: "Xorazm viloyati" },
    { id: 1735, name: "Qoraqalpog`iston Respublikasi" }
  ],
  validation: {
    minPrice: {
      1000: "Minimal summa 1000 so'm"
    },
    required: "Iltimos bo'sh joyni toldiring",
    userNameLength: "Minimal qiymat 5",
    notValidName: "Notog'ri foydalanuvchi nomi",
    phoneNumberLength: "Telefon raqami noto'g'ri",
    equalPhoneNumber: "Shaxsiy telefon raqamni kiritib bo'lmaydi",
    guarantsPhoneMatchError: "Bir xil raqamlarni kiritmang"
  },
  promotion: {
    title: "Kelgusi o'yinlar",
    secondTitle: "Avvalgi o'yinlar",
    thirdTitle: "Video va rasm hisoboti",
    place: "Arena Markaz (Depo Mall)",
    empty_promotion: "AKSIYA",
    empty_soon: "Tez orada",
    empty_notice:
      "Yangi aksiyaning boshlanishi haqida yaqin vaqtda e'lon qilinadi. Biz bilan qoling!"
  },
  regionChoose: "Tumanni tanlang",
  statuses: {
    0: "Aktiv emas",
    1: "Aktiv",
    2: "Tasdiqlash kutilmoqda",
    3: "Muddati o'tgan",
    4: "Muddati o'tgan",
    5: "Bekor qilingan",
    9: "Tugallangan"
  },
  registration: {
    "image-confirmation": "Hujjat turini tanlang",
    "id-card": "ID karta",
    passport: "Pasport"
  },
  "register-photo": {
    title: "Pasport fotonusxasi",
    "id-title": "Id karta fotonusxasi",
    "download-id-file": "ID karta fotonusxasi " + "<br/>" + " yuklandi",
    "desctop-id-file":
      "ID karta bosh sahifasi fotonusxasini " + "<br/>" + " yuklang",
    "desctop-second-id-file":
      "ID karta orqa sahifasi fotonusxasini " + "<br/>" + " yuklang",
    "mobile-selfie-id-card": "ID karta bilan selfi",
    "mobile-id-file": "ID karta fotonusxasi",
    "download-selfie-id-card": "ID karta bilan selfi yuklandi",
    "desctop-selfie-id-card": "ID karta bilan selfini" + "<br/>" + " yuklang",
    "example-address-text-card":
      "ID kartadagi ro'yxatdan o'tish sahifasi yoki  boshqa  tasdiqlovchi hujjatning fotosuratini yuklash mumkin",

    "photo-ui": "Убедитесь что данные паспорта четко видны, отсутсвуют блики",
    "desctop-passport-file": "Pasport fotonusxasini " + "<br/>" + " yuklang",
    "desctop-selfie-file": "Pasport bilan selfini" + "<br/>" + " yuklang",
    "mobile-passport-file": "Pasport fotonusxasi",
    "mobile-selfie-file": "Pasport fotonusxasi",
    "passport-address": "Ro'yxatdan o'tish fotosurati",
    confidantTitle: "Yaqinlaringiz telefon raqami",
    confidantCardText: "Aloqa uchun shaxs -",
    confidantText:
      "Iltimos, sizga yaqin shaxslarning telefon raqamini ko'rsating",
    "download-passport-file": "Pasport fotonusxasi" + "<br/>" + " yuklandi",
    "download-selfie-file": "Pasport bilan selfi" + "<br/>" + " yuklandi",
    "download-address-file": "Yashash joyi " + "<br/>" + " fotosurati yuklandi",
    "how-to-photo": "Fotonusxalarni qanday qilish lozim?",
    "how-take-selfie-passport": "Passport bilan qanday selfie qilinadi?",
    example: "Rasm namunasi",
    exampleAddressText:
      "Pasportdagi ro'yxatdan o'tish sahifasi yoki  boshqa  tasdiqlovchi hujjatning fotosuratini yuklash mumkin"
  },
  oferta: {
    title:
      "SOLUTIONS LAB AJ kiberxavfsizlik sohasida etakchi xalqaro va mahalliy kompaniyalar bilan hamkorlik qiladi.",
    partners: {
      title: "Bizning prosessing hamkorlarimiz",
      text: "Prosessing markazlari orasidagi barcha ma'lumotlar xavfsiz aloqa kanallari orqali, shuningdek shifrlangan kriptografik shaklda o'tadi. Plastik bank kartalariga tegishli ma'lumotlar prosessing markazlari serverlarida saqlanadi. Prosessing markazlaridan olingan ma'lumotlarga asoslangan holda, \"Uzum Nasiya\" xizmati foydalanuvchisining to'lov qobiliyatining tahlili (skoring) shakllantiriladi va shuningdek rekurrent (takroriy) to'lov xizmati ham aktivlashtiriladi. Rekurrent to'lovlar tovar yoki xizmatlar uchun to'lovlar jadvaliga muvofiq miqdor va muddatlarda, sotib olingan tovar uchun to'lov to'liq to'lanmaguncha amalga oshiriladi."
    },
    "send-data": {
      title: "Ma'lumotlar almashinuvi",
      text: "Foydalanuvchi ma'lumotlari shifrlangan SLL ulanishi orqali uzatiladi. SSL ulanishidan foydalangan holda ma'lumotlarni uzatish barcha operatsiyalarni himoya qilishga va ma'lumotlarga begonalarni kirishini oldini olishga qaratilgan. SSL ulanishi shaxsiy va tranzaktsion ma'lumotlar bilan ishlashda sizning resursingiz va tarmoq xavfsizligini kafolatlashning ishonchli vositasidir."
    }
  },
  refusal: {
    title: "Afsuski, siz tekshiruvdan o'tmadingiz",
    text: "Возможные причины отказа:",
    causes: {
      overdue: "Есть просроченные кредиты",
      alreadyHave: "Данный пользователь есть на сайте UZUM NASIYA"
    }
  },
  popup: {
    "how-to-works": "Bu qanday ishlaydi",
    magazines: "Hamkorlarimiz",
    // bonuses: 'Bonuslar',
    news: "Blog",
    "download-app": "Ilovani <br/>yuklab  olish",
    text: "Tezkor xaridlarni  amalga <br/> oshiring",
    alert: "* 2021 yil 1-iyuldan foydalanish mumkin"
  },
  balancePopup: {
    title: "Balans",
    limit: "Muddatli to'lov limiti",
    possible: "Joriy limit",
    topUp: "To'ldiring",
    paymartCart: "Karta",
    personalBalance: "Shaxsiy hisob raqami",
    yourDeposit: "Sizning depozitingiz"
  },
  button: {
    shopsAll: "Barcha do'konlar",
    bonus: "Bonuslardan foydalaning",
    back: "Ortga",
    newmore: "Ro'yxatdan o'tish",
    uploadPhoto: "Rasmni yuklash",
    takePhoto: "Rasmga olish",
    cancel: "Bekor qiling",
    continue: "Davom eting",
    download: "Tez kunda *",
    delete: "O'chiring",
    "go-to-site": "Saytga o'ting",
    partnerSite: "Xamkor web-saytiga o'ting",
    "go-profile": "Kabinetga kiring",
    installments: "Muddatli to'lovni sinab ko'ring",
    "number-change": "Raqamni o'zgartiring",
    more: "Batafsil",
    buy: "Sotib oling",
    next: "Oldinga",
    "orders-send": "So'rovni yuboring",
    register: "Ro'yxatdan o'ting",
    sell: "Sotishni boshlang",
    upgrade: "Yaxshilash",
    open: "Oching",
    share: "Ulashish",
    close: "Yoping",
    pay: "To'lash",
    goHome: "Bosh sahifaga qaytish",
    logout: "Chiqish",
    downloadContract: "Shartnomani yuklab oling",
    offerPreview: "Ofertani ko'rmoq",
    askActivationCode: "Shartnomani faollashtirish kodini so'rang",
    done: "Shartnomani rasmiylashtirish",
    checkStatus: "Arizani tekshirish",
    check: "Tekshirish",
    send: "Yuboring"
  },
  input: { "search-placeholder": "Do'konni toping" },
  breadcrumb: {
    home: "Bosh sahifa",
    whatIsPaycoin: "Paycoin o'zi nima?"
  },
  banner: {
    buyer: {
      title: "Uzum Nasiya — siz sotib olasiz, biz to'laymiz!",
      text: "Uzum Nasiya orqali xarid qilganingizda siz doimo to’lov miqdorini va jadvalini aniq bilasiz. Hech qanday yashirin to'lovlar va kechikkanlik uchun jarimalar yo’q."
    },
    business: {
      title: "Siz va sizning mijozlaringiz uchun qulaylik",
      text: "Uzum Nasiya sizning faoliyat sohangizdan qat'i nazar, o'rtacha chek va mijozlar bazasini ko'paytirish bilan birga, mijozlaringizda mablag' yetishmasligi muammosini bartaraf qiladi."
    },
    bonuses: {
      title: "Bonus tizimi",
      text: "Mijozlar uchun mo'ljallangan shaffof va juda qulay bonus tizimi tezkorlik bilan o'z hisob raqamingizni yaxshilashga jumladan, katta chegirmalar, uzaytirilgan muddat yoki ko'paytirilgan limitlardan foydalanish imkoni yaratadi"
    },
    magazines: { title: "Do'konlar" },
    news: {
      title: "Blog",
      newsAll: "Barcha bloglar"
    },
    "how-to-work": {
      title: "Xizmat qanday ishlaydi?",
      text: "Bir necha daqiqada ro’yxatdan o’ting va 15 million soʻmgacha boʻlgan limit bilan kerakli tovarlarni muddatli to’lovga xarid qilish imkoniyatiga bir zumda ega boʻling."
    }
  },
  profile: {
    checkPaymentType: "To'lov turini tanlang:",
    title: "Profil",
    limit: "Joriy limit",
    sales: "Chegirmalar",
    limitPaycoin: "Maksimal limit",
    currency: "so'm",
    bonusPoints: "Bonus ballari:",
    month: "oy",
    maxPeriod: "Maksimal davr:",
    myCards: "Mening kartalarim",
    cardAdd: "To'lov kartasini qo'shish.",
    cardTitleDefault: "Oylik karta",
    balanceUp: {
      title: "Shaxsiy hisobni to'ldirish",
      cards: "Kartalar",
      replenishmentMethods: "To'ldirish usullari",
      numberPersonalAccount: "Shaxsiy hisob raqamingiz:",
      successPaid: "Muvaffaqiyatli to'landi"
    },
    contract: {
      goBack: "Saytga qaytish",
      confirm: "Tasdiqlash",
      number: "Shartnoma",
      installmentTerm: "Muddatli to'lov muddati",
      contracts: "Shartnomalar",
      empty: "Shartnomalar mavjud emas.",
      searchField: "Shartnoma raqamini kiriting",
      remaining: "Qoldiq:",
      labelSMSCode: "Faollashtirish SMS kodi:",
      nextPay: "Keyingi to'lov:",
      monthlyPayment: "Oylik to'lov:",
      thisMonth: "Ushbu oy uchun to'lov:",
      allPeriod: "Butun davr uchun to'lov:",
      repayment: "Shartnomani oldindan yopish uchun to'lov:",
      payment_for_month: "Oylik to'lov",
      payment_for_months: "Oylik to'lov",
      month_end: "",
      months: [
        "Yanvar",
        "Fevral",
        "Mart",
        "Aprel",
        "May",
        "Iyun",
        "Iyul",
        "Avgust",
        "Sentabr",
        "Oktabr",
        "Noyabr",
        "Dekabr"
      ],
      paymentError: "Toʻlov mobaynida xatolik, qayta urinib koʻring!",
      paymentPeriod: "To'lov muddatini tanlang:",
      paymentCard: "To'lovni amalga oshirish uchun kartani tanlang",
      payInfo: "Belgilangan muddat uchun sizning kartangizdan to'lov olinadi",
      paymentType: {
        oneMonth: "1 oy uchun to'lov",
        random: "Istalgan miqdorni to'lang"
      },
      selectAll: "Barchasini tanlash",
      clearAll: "Barchasini tozalash",
      payFor: "To'lov uchun"
    },
    history: {
      empty: "Bo'sh",
      title: "Tarix",
      payments: "To'lovlar",
      documents: "Hujjatlar",
      sum: "Summa:",
      payment: "To'lov:",
      card: "Karta",
      time: "Vaqt",
      underContract: "Shartnoma №",
      paymentGraphic: "To'lov jadvali",
      paymentDate: "To'lov sanasi",
      paymentType: {
        user: "Shaxsiy hisob raqamni to'ldirish",
        user_auto: "To'lov",
        auto: "Avto to'lov",
        refund: "Qaytarilgan bonus",
        fill: "Bonuslar qabul qilindi",
        A2C: "Bonuslarni kartaga o'tkazilindi",
        upay: "Bonusdan to'lov",
        Reimbursable: "Qarzdorlik bo'yicha yechildi",
        unknown: "Noma`lum"
      },
      amountToPay: "To'lov miqdori"
    },
    bonus: {
      title: "Bonus summalari",
      bonusCardTitle: "Bonus summalari",
      spend: "Sarflash"
    },
    sale: "Paycoinlarni chegirmani yaxshilash uchun sarflang",
    forSale: "Chegirmaga"
  },
  carousel: {
    header: {
      title: "Uzum Nasiya orqali xarid qiling",
      magazines: "Barcha 10 do'kon"
    },
    // text:
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tincidunt aliquet nisl.",
    partners: {
      title: "Muvaffaqiyatli hamkorlarimiz qatoridan joy oling",
      all: "Barcha hamkorlar"
    }
  },
  akciya: {
    days: "Kun",
    hours: "Soat",
    minutes: "Daqiqa",
    banner: "Uzum Nasiyaning aksiya va yutuqli o'yinlari",
    bannerTitle:
      "Ajoyib har oylik aksiyamizda ko'plab qimmatbaho sovg'alar o'ynaladi. Asosiy sovrin esa uchta Chevrolet Spark avtomobillari. Aksiyada qatnashish uchun Uzum Nasiya tizimida ro'yxatdan o'ting, muddatli to'lov orqali mahsulotlarni xarid qiling va to'lovlarni o'z vaqtida amalga oshiring!",
    seconds: "Soniya",
    title: "Aksiya",
    status: "Sizning statusingiz:",
    subTitle:
      "\"Batafsil\" tugmasini bosing va aksiyada ishtirok etish bo'yicha ma'lumotlar bilan tanishib chiqing",
    isYouMemberText:
      "Aksiyada ishtirok etayotganingizni tekshirish uchun raqamingizni kiriting va \"Tekshirish\" tugmasini bosing.",
    text:
      "Muddatli to'lovga xarid qiling, to'lovlarni o'z vaqtida amalga oshiring va Spark avtomobili egasiga aylaning!\n" +
      "Aksiyada Uzum Nasiya servisining foydalanuchilari ishtirok etadi. Ishtirokchilar oylik to'lovlarini o'z vaqtida amalga oshirgan bo'lishlari lozim. " +
      "Aksiya ishtirokchilari har oy muzlatgichlar, televizorlar, kir yuvish mashinalari va boshqa " +
      "ko'plab sovrinlarni yutib olishlari mumkin. Bosh sovrinlar — uchta Spark avtomobili 2022 yil iyun oyidan boshlab o‘ynaladi! " +
      "Har ikki oyda bitta avtomobildan sovg'a sifatida taqdim etamiz.",
    offerta: "Ommaviy oferta.",
    dates: [
      "Birinch sovrinli o'yinimiz 26.11.2021 soat 17:00 da \"Riviera Mall\" Savdo markazida bo'lib o'tadi",
      "Ikkinchi sovrinli o'yinimiz - 26.12.2021 11:00 dan 20:00 gacha «MEDIAPARK Bozori» Mirobod filiali maishiy texnika va elektronika do'konida bo'lib o'tadi",
      "Uchinchi sovrinli o'yinimiz - (Aniqlanmoqda)",
      "To'rtinchi sovrinli o'yinimiz - (Aniqlanmoqda)",
      "Beshinchi sovrinli o'yinimiz - (Aniqlanmoqda)",
      "Oltinchi sovrinli o'yinimiz - (Aniqlanmoqda)",
      "Super sovrin avtomobil - 18.06.2022"
    ],
    member: "Tabriklaymiz, siz aksiyada ishtirok etyapsiz!",
    overdue:
      "Sizning qarzdorligingiz bo'yicha kechikish mavjud, aksiyada ishtirok etish uchun mavjud qarzdorlikni so'ndiring",
    "not-verified-user":
      "Kompaniyamizning tanlovlarida ishtirok etish uchun ro'yxatdan o'tish jarayonini davom ettirishingiz lozim",
    "blocked-user": "Asfuski, Siz aksiyada ishtirok eta olmaysiz.",
    "not-member": "Asfuski, Siz aksiyada ishtirok etmayapsiz.",
    "no-user": "Siz ro'yxatdan o'tmagansiz",
    isMember: "Aksiya ishtirokchisisiz!",
    notMember: "Siz aksiya ishtirokchisi emassiz",
    winnersText: "G'oliblar ro'yxati",
    winnersPhotos: "Foto va videolar",
    giftText: "Yutuq:",
    winners: {
      riviera: [
        {
          name: "Xamrayeva	Shoxista	Baxtiyor Qizi",
          gift: "стиральная машина	Samsung WW70J42G0PSDLD"
        },
        {
          name: "Shafikov	Magsum	Mansurovich",
          gift: "холодильник	Indesit DS 4160 S"
        },
        {
          name: "Normuminova	Muxabbat	Normurotovna",
          gift: "газовая плита	Milagro 02-K CHU"
        },
        {
          name: "Ortikov	Abdunabi	Abdumajidovich",
          gift: "смартфон	VIVO Y31 (4/64) Black"
        },
        {
          name: "Abdiyeva	Dildora	Orifovna",
          gift: "ноутбук	Lenovo V15-IGL"
        },
        {
          name: "Sobirova	Shaxnoza	Nasimovna",
          gift: "телевизор	Samsung UE 40M5070"
        },
        {
          name: "Kodirov	Baxtiyor	Abdimurotovich",
          gift: "мини-печь	Itimat I-60 DL"
        },
        {
          name: "Payziyev	Faxriddin	Sayfiddin O‘g‘li",
          gift: "стиральная машина	Samsung WW70J42G0PSDLD"
        },
        {
          name: "Komilova	Gulnoza	Umurovna",
          gift: "холодильник	Indesit DS 4160 S"
        },
        {
          name: "Sidikov	Adimbay	Akbaraliyevich",
          gift: "газовая плита	Milagro 02-K CHU"
        },
        {
          name: "Ergashev	Musabek	Baxromjon-o‘g‘li",
          gift: "смартфон	VIVO Y31 (4/64) Black"
        },
        {
          name: "A’zamova	Xurshidaxon	Sharobidinovna",
          gift: "ноутбук	Lenovo V15-IGL"
        },
        {
          name: "Norkulov	Sirojiddin	Namozovich",
          gift: "телевизор	Samsung UE 40M5070"
        },
        {
          name: "Sarabekova	Saodat	Ko‘bayevna",
          gift: "мини-печь	Itimat I-60 DL"
        }
      ],
      mediapark: [
        {
          name: "Qalandarova Nafosat O'ktamboy Qizi",
          gift: "Milagro 02-K CHU gaz plitasi"
        },
        {
          name: "Bobayarova Gulnoza Amirovna",
          gift: "Itimat I-60 DL mini-pechi"
        },
        {
          name: "Xatamov Nodir Ravshan O‘g‘li",
          gift: "Lenovo V15-IGL noutbuki"
        },
        {
          name: "Kadirova Aynash Raxmettullayevna",
          gift: "Samsung WW70J42G0PSDLD kir yuvish mashinasi"
        },
        {
          name: "Adashbayeva Gulchexraxan Tashpolatovna",
          gift: "VIVO Y31 (4/64) Black smartfoni"
        },
        {
          name: "Sharipova Mehriniso Raximovna",
          gift: "ART HD 316 FN S muzlatgichi"
        }
      ],
      mediapark_qoratosh: [
        {
          name: "Alimbay Arkayev Xudaybergenovich",
          gift: "MINI-PECH Itimat I-60 DL"
        },
        {
          name: "Alisher Dautov Anayatovich",
          gift: "NOUTBUK Lenovo V15-IGL N4020/4GB/1000GB"
        },
        {
          name: "Gulmira Xamrakulova Ubaydullayevna",
          gift: "KIR YUVISH MASHINASI LG F2J5NS6W"
        },
        {
          name: "Gulshod Maxmudova Djamilovna",
          gift: "TELEVIZOR Panasonic 40 TX-40DR400"
        },
        {
          name: "Gulbohor Tangirova Buriyevna",
          gift: "SMARTFON VIVO Y31 (4/64) Black"
        },
        {
          name: "Nuriddin Turabayev Dusmurodovich",
          gift: "SOVUTGICH ART HD 316 FN S"
        },
        {
          name: "Maxmidjon Mirzakarimov Tursunovich",
          gift: "GAZ PLITASI Milagro 02-K CHU"
        }
      ]
    }
  },
  step: {
    title: "Barchasi oddiy!",
    link: "Batafsil ma'lumot",
    buy: {
      title: "Tezkor xarid",
      text: "Muddatli to’lovni 5 daqiqada rasmiylashtiring va hamkor do’konlarda istalgan mahsulotlarni boshlang’ich to’lovsiz olib keting."
    },
    calculations: {
      title: "Shaffof hisob-kitoblar",
      text: "Uzum Nasiya muddatli to’lov xizmatida hech qanday yashirin to’lov va jarimalar mavjud emas. Hisob-kitoblar hamma uchun tushunarli."
    },
    renewableLimit: {
      title: "Yangilanuvchi limit",
      text: "Limit har oy to’langan qarzdorlik miqdori barobarida yangilanadi/tiklanadi."
    }
  },
  benefits: {
    title: "Uzum Nasiya bilan hamkorlikning afzalliklari",
    cards: [
      {
        heading: "Savdo va konversiyaning o’sishi",
        title:
          "Hamkorlarimiz o'rtacha chekning 70% ga va savdo konversiyasining o'rtacha 15% ga osishini qayd etishadi. Takroriy va sodiq mijozlar soni ham sezilarli darajada oshadi. "
      },
      {
        heading: "Samarali marketing",
        title:
          "Biz O'zbekiston bo'ylab minglab foydalanuvchilarimizga siz va mahsulotlaringiz haqida barcha targ'ibot kanallarimiz orqali bepul aytib beramiz."
      },
      {
        heading: "Barchasi uchun universal echim",
        title:
          "Bu chakana savdo do'koni, do'konlar tarmog'i yoki onlayn-do'kon bo'ladimi, biz umumiy maqsadlarga erishish uchun siz uchun ideal parametrlarni taqdim etamiz."
      },
      {
        heading: "Biznesingizni kengaytirish imkoniyatlari",
        title: "Biz bilan biznesingiz kamroq xavf bilan tezroq rivojlanadi."
      }
    ]
  },
  "how-it-works": {
    title: "Bu qanday ishlaydi ?",
    cards: [
      {
        heading: "Mos yechimni tanlang",
        title:
          "Xaridoringiz uchun eng mos keladiganini tanlang: qo’shimcha to'lov hajmiga qarab 6, 9 yoki 12 oylik to'lovlar."
      },
      {
        heading: "Xavflaringizni minimallashtiring.",
        title:
          "Tovarlaringizni muddatli to’lovga soting va ular bilan bog'liq xavflar haqida o'ylamang. Uzum Nasiya orqali sotishda tovar uchun to'lov biz tomonimizdan amalga oshiriladi."
      },
      {
        heading: "Boshlash juda oson",
        title:
          "Ariza topshirilgan paytdan boshlab Uzum Nasiya orqali birinchi sotuvni amalga oshirishgacha barchasi oson. Oddiy va tez onlayn integratsiya. Biz har doim sizga yordam berishga tayyormiz."
      }
    ]
  },
  product: {
    single: "Mahsulot",
    plural: "Mahsulotlar",
    name: "Mahsulot nomi",
    price: "Narxi",
    amount: "Soni",
    sum: "Summa",
    total: "Jami"
  },
  productPage: {
    title: "Mahsulot"
  },
  "orders-form": {
    title: "So'rov qoldiring va taklif oling",
    "company-name": "Korxonaning nomi",
    "company-name-placeholder": "Korxona turi",
    phone: "Telefon raqami",
    name: "Ismingiz",
    "name-placeholder": "Ismingizni kiriting",
    "fio-placeholder": "FIO ni kiriting (qarindosh)",
    "number-placeholder": "Telefon raqamni kiriting",
    products: "Tashkilotlar turi",
    "products-placeholder": "Tanlang",
  },
  "pay-coin-info": [
    {
      heading: "Paycoin bu nima?",
      title:
        "Paycoin bu Uzum Nasiya platformasiga tegishli bonus tizimining elementi. Platformaning sodiq va intizomli foydalanuvchilari Paycoinlarni jamg'arib, keyinchalik o'z shartnomalarining shartlarini bir necha bor takomillashtirishlari mumkin.",
      active: true
    },
    {
      heading: "Paycoinlarni qanday ishlab topish mumkin?",
      title:
        "Paycoinlarni ishlab topish juda oson. Buning uchun faqatgina shartnomalar bo'yicha to'lovlarni o'z vaqtida amalga oshirish etarlidir",
      active: false
    },
    {
      heading: "Paycoinni nimaga sarflasa bo'ladi?",
      title:
        "Paycoinlarni maxsus tarif paketlari sotib olish uchun ishlatishingiz mumkin. Ulardan foydalanib, siz abadiy chegirma, oshirilgan limit, uzaytirilgan muddatlarga ega bo'lasiz.",
      active: false
    }
  ],
  "registration-info": {
    title: "Uzum Nasiyada ro'yxatdan o'tish uchun nimalar kerak?",
    text: "Verifikaciya jarayonidan o'tganingizdan so'ng\n              muddatli to'lov va bonus tizimlaridan foydalanishingiz mumkin"
  },
  installment: {
    "how-to-sign": {
      title: "Ro’yxatdan o’tish uchun nimalar kerak?",
      documents: [
        "Telefon raqami",
        "Pasport/ID-karta",
        "Bank plastik kartasi",
        "Kamerali mobil telefon"
      ]
    },
    "how-to-register": {
      title: "Qanday ro’yxatdan o’tish mumkin?"
    },
    step: [
      {
        heading: "Veb-saytda:",
        lists: [
          "Telefon raqamingizni kiriting",
          "To’lov kartasi raqamini kiriting",
          "Pasport suratini yuklang",
          "Pasport bilan selfi suratini yuklang"
        ]
      },
      {
        heading: "Mobil telefon",
        lists: [
          "Telefon raqamingizni kiriting",
          "To’lov kartasi raqamini kiriting",
          "Pasport seriyasini va tug’ilgan sanani kiriting",
          "Jonlilik tekshiruvidan o’ting (MyID tizimi bilan integratsiya) "
        ]
      },
      {
        heading: "Ma'lumotlarni kiritganingizdan so'ng, sizning raqamingizga SMS-xabar shaklida shaxsiy profilingizga kirish uchun parol yuboriladi.",
      }
    ],
    hint: "Ma'lumotlarni kiritganingizdan so'ng raqamingizga SMS-xabar ko’rinishida shaxsiy kabinetingizga kirish uchun parol yuboriladi."
  },
  "how-to-buy": {
    title: "Xarid qanday amalga oshiriladi?",
    cards: [
      {
        heading: "Hamkor do’konda mahsulotni tanlang",
        title:
          "Uzum Nasiya platformasi orqali mahsulotni muddatli to'lov asosida sotib olmoqchi ekanligingizni sotuvchi-maslahatchiga ayting."
      },
      {
        heading: "O'zingizga qulay shartlarni tanlang",
        title:
          "O'zingiz uchun eng mos nasiya muddatini tanlang va bu haqida sotuvchiga xabar bering. Uzum Nasiya platformasida ro'yxatdan o'tgan telefon raqamingizni ko'rsating."
      },
      {
        heading: "Hujjatlarni rasmiylashtiring",
        title:
          "To'lov rejasi shartlari bilan tanishing va telefoningizga keladigan SMS-kod bilan xaridni tasdiqlang. Imzolangan dalolatnomadan o'z nusxangizni oling."
      },
      {
        heading: "Xaridlardan zavq oling!",
        title:
          "Mahsulotlarni olib keting va foydalaning, to’lovni esa jadvalga muvofiq keyinroq amalga oshiring. "
      }
    ]
  },
  "how-to-pay": {
    title: "To'lov qanday amalga oshiriladi ?",
    text: "Foydalanuvchilarga qulaylik yaratish maqsadida platformada <br /> turli xil to'lov usullari mavjud:",
    cards: [
      {
        heading: "Foydalanuvchining kartasidan to'lovni avtomatik yechib olish",
        title:
          "Uzum Nasiya platformasi har oy to'lov kuni (shartnoma bo'yicha) foydalanuvchi kartasidan avtomatik ravishda belgilangan miqdorni yechib oladi va foydalanuvchiga qarzdorlik yopilgani yoki qoldiq qarz haqida xabar beradi."
      },
      {
        heading: "Shaxsiy hisobni to'ldirish.",
        title:
          "Shaxsiy kabinetingizdagi karta orqali shaxsiy hisobingizni to'ldirish va qarzingizni muddatidan oldin to'lash imkoniyati mavjud."
      },
      {
        heading: "Shaxsiy hisobni to'lov xizmatlari orqali to'ldirish",
        lists: [
          "To'lov xizmati operatorini tanlang: Payme, Click, Apelsin, MyUzcard, Upay;",
          "«To'lov» tugmasini bosing va «Qidirish» panelida Uzum Nasiya ni izlab topib belgilang;",
          "Telefon raqamingiz va to'lov summasini kiritib «To'lovni amalga oshirish» tugmasini bosing;",
          "To'lov muvaffaqiyatli amalga oshirilganda ushbu mablag'lar sizning shaxsiy hisobingizga yuboriladi. Shaxsiy hisobingizni Uzum Nasiya platformasidagi shaxsiy kabinetda tekshirishingiz mumkin."
        ]
      }
    ]
  },
  "faq-quest": [
    {
      title: "MUDDATLI TO’LOV XIZMATIDA RO’YXATDAN O’TISH",
      id: "one",
      quest: [
        {
          head: "Xizmatdan qanday foydalanishni boshlash mumkin?",
          title:
            "Avvaliga, siz uzumnasiya.uz veb-saytida yoki ilovada ro'yxatdan o'tishingiz kerak. Bunda sizga faqat pasport, bank plastik kartasi va mobil telefon kerak bo’ladi. Ro'yxatdan o'tgandan so'ng, Uzum Nasiya mutaxassislari tomonidan ishlab chiqilgan maxsus tahlil tizimi mijozga ajratilgan mablag' uchun maksimal limitni belgilaydi. Ushbu miqdor haqidagi ma'lumot sizning shaxsiy kabinetingizda ko'rsatiladi. Ro'yxatdan o'tgandan so'ng ushbu limitdan darhol foydalanish mumkin.",
          height: "100px"
        },
        {
          head: "Ro’yxatdan qanday o’tiladi?",
          title:
            "Ro'yxatdan o'tish jarayoni veb-saytda yoki mobil ilova orqali amalga oshiriladi. Ro'yxatdan o'tish uchun quyidagi bosqichlarni o'tishingiz kerak:",
          lists: [
            "Ish haqi plastik kartasiga SMS-xabarnoma xizmati o'rnatilgan telefon raqamini kiriting.",
            "Ish haqi plastik kartasining raqamini va telefoningizga yuboriladigan bir martalik SMS-kodni kiriting. Tizim ish haqi kartasidagi daromad va xarajatlarni tahlil qilish asosida limitni avtomatik ravishda belgilaydi.",
            "<b>Veb-saytda:</b> Pasportingizni suratga oling, shuningdek, pasportingiz bilan selfi suratga oling. Muhim: Kartadagi SMS-xabarnoma ulangan telefon raqamingiz, shuningdek, pasport va plastik karta egasi to'liq mos kelishi kerak. </br> </br> " +
            "<b>Mobil ilovada:</b> Pasport/ID-kartaning seriya raqamini, shuningdek, tug'ilgan sanani kiriting. Tasdiqlash MyID tizimi yordamida amalga oshiriladi. Sizdan quyidagi amallarni bajarib, jonlilik tekshiruvidan o'tishingiz talab qilinadi: kameraga qarang, ko'zingizni pirpirating va tabassum qiling."
          ],
          subtitle:
            "! Barcha ma'lumotlar kompaniyaga shifrlangan shaklda taqdim etiladi.",
          height: "200px"
        },
        {
          head: "Agar ro’yxatdan o’tish jarayonida rad javobi kelsa nima qilish kerak?",
          title:
            "Kompaniya mutaxassislari tomonidan ishlab chiqilgan tizim plastik kartadagi daromad va xarajatlar asosida avtomatik ravishda to'lov limitini belgilaydi. Quyidagi holatlarda rad javobi beriladi:",
          lists: [
            "Agar oylik daromadingiz 1 million so'mdan kam bo'lsa.",
            "Boshqa kreditlar bo'yicha muddatidan o'tgan majburiyatlaringiz bo'lsa.",
            "Boshqa do'konlarda va tizimlarda o'z vaqtida to'lanmagan shartnomalaringiz bo’lsa."
          ],
          height: "100px"
        },
        {
          head: "Agar ilgari rad etilgan bo'lsa, qayta ro'yxatdan o'tishim mumkinmi?",
          title:
            "Ha, to'lov tarixingiz yaxshilanganligi sababli siz qayta ro'yxatdan o'tishingiz mumkin, bunda:",
          lists: [
            "So'nggi 6 oylik ish haqi 1 million so'mdan oshsa;",
            "Barcha qarzlaringiz qaytarilgan bo’lsa;",
            "Siz tovar va xizmatlarni muddatli to’lov asosida sotuvchi boshqa do'konlarga va tizimlarga qarzlarni to'lagan bo’lsangiz."
          ],
          height: "120px"
        },
        {
          head: "Ro’yxatdan o’tish jarayonida qanday plastik kartani ko’rsatishim kerak?",
          title:
            "Agar sizda bir nechta plastik karta mavjud bo'lsa, unda oxirgi 6 oy ichida eng ko'p pul tushumiga ega kartani ko'rsatishingiz ma’qul, chunki tizim sizning to'lov qobiliyatingizni aynan shu karta orqali tahlil qiladi.",
          height: "50px"
        },
        {
          head: "Tizimda ikka marotaba ro’yxatdan o’tsam bo’ladimi?",
          title:
            "Agar siz bir marta muvaffaqiyatli ro’yxatdan o’tgan bo’lsangiz, unda siz tizimda qayta ro'yxatdan o'tolmaysiz.",
          height: "120px"
        },
        {
          head: "Ro’yxatdan o’tish necha pul turadi?",
          title: "Uzum Nasiya tizimida ro’yxatdan o’tish butunlay bepul.",
          height: "120px"
        }
      ]
    },
    {
      title: "MAVJUD LIMIT",
      id: "two",
      quest: [
        {
          head: "Agar men ro'yxatdan o'tgan bo’lib lekin xizmatdan foydalanmagan bo'lsam nima bo'ladi?",
          title:
            "Siz uchun to’lovlar do'kondan xaridni amalga oshirgandan keyingi oydan boshlanadi. Agar siz xizmatdan foydalanmagan bo'lsangiz, u holda hech qanday to'lovlar bo'lmaydi."
        },
        {
          head: "Oldingi shartnomani to'lab bo'lgach, yana bir bor muddatli to’lov xizmatidan qayta foydalanishim mumkinmi?",
          title:
            "Ha, avvalgi shartnomani to'liq yoki qisman to’langandan so'ng, sizning limitingiz tiklanadi va shaxsiy hisobingizda ko'rsatiladi."
        },
        {
          head: "To'lov limitidan qisman foydalanishim yoki bir nechta xaridni amalga oshirsam bo'ladimi?",
          title:
            "Ha, siz to'lov limitidan qisman va takroran foydalanishingiz mumkin. Masalan, ro'yxatdan o'tgandan keyin to'lov limiti miqdori 6 000 000 so'mni tashkil etdi. Siz hamkor do'kondan 500 000 so'mlik mahsulotlarni xarid qildingiz deylik. Minimal miqdor yo'q. Qolgan mablag' yo’qolib ketmaydi va siz uni o’zingiz uchun qulay bo'lgan vaqtda ishlatishingiz mumkin."
        },
        {
          head: "Agar mahsulot mavjud limitimdan qimmatroq bo'lsa, nima qilish kerak?",
          title:
            "Agar siz tanlagan mahsulot belgilangan limitdan qimmatroq bo'lib chiqsa, bu muammo emas. To'lov limiti va tovarlarning narxi o'rtasidagi farq sizning shaxsiy hisobingizdagi mablag’lar orqali qoplanishi mumkin. Ushbu miqdor sotib olingandan so'ng avtomatik ravishda ushlab qolinadi va unga qo'shimcha to'lov olinmaydi. Masalan, ro'yxatdan o'tgandan so’ng sizga 3 000 000 so'm limit belgilandi. Siz tanlagan mahsulot narxi 3 500 000 so'm deylik. Siz shaxsiy hisobingizni to'ldirish uchun 500 000 so'm miqdorida mablag' to’lashingiz kerak. Sotib olgandan keyin ushbu summa avtomatik ravishda shaxsiy hisobingizdan yechiladi. Qarz qoldig'i 3 000 000 so'mni tashkil qiladi. To'lov jadvali, shuningdek, 3 000 000 so'm miqdoridan kelib chiqqan holda hisoblab chiqiladi. 500 000 so'm uchun xech qanday ortiqcha to'lov yo'q."
        },
        {
          head: "To'lov limiti miqdorini qanday oshirish mumkin?",
          title: `To’lovlarni o'z vaqtida amalga oshirganda mijozlarga muddatli to’lov limiti miqdorini oshirish imkoniyati beriladi. Shuningdek, agar siz ish joyingizni o'zgartirgan bo'lsangiz va yangi joydagi maosh oldingisidan yuqori bo'lsa, kompaniyamizning aloqa markaziga murojaat qilib, qayta ro'yxatdan o'tishingiz mumkin. Biroq, yangi joyda siz kamida 6 oy ishlashingiz kerak.`
        }
      ]
    },
    {
      title: "TO’LOVLAR JADVALI",
      id: "three",
      quest: [
        {
          head: "Shartnoma uchun to’lov qanday qilinadi?",
          title:
            "Sizning vaqtingizni tejagan holda, kompaniya ofisiga yoki bank tarmog’iga borish zaruratini oldini olgan holda barcha to'lovlar avtomatik ravishda plastik kartadagi mablag'larni hisobdan yechish yo'li bilan amalga oshiriladi."
        },
        {
          head: "Tovarlarning narxi va to'lovlar jadvalini qanday bilsam bo'ladi?",
          title:
            "Xaridni amalga oshirishda do'kon sotuvchisi mahsulot, mahsulot narxi va miqdori, shartnomaning umumiy summasi, to'lovlar jadvali va har bir to'lov miqdori to'g'risida to'liq ma'lumotni o'z ichiga olgan shartnomaning qog'ozli versiyasini taqdim etadi. Shuningdek, mijozning shaxsiy kabinetida shartnoma to'g'risidagi to'liq ma'lumotlar mavjud va ma'lumotlar SMS-xabar orqali ham uzatiladi."
        },
        {
          head: "Shartnomada belgilangan muddatdan oldin to’lovlarni amalga oshirish mumkinmi?",
          title:
            "Ha, siz barcha xaridlaringiz uchun to’lovlarni muddatidan oldin to'lash huquqiga egasiz. Buning uchun siz shaxsiy kabinetingizda maxsus funktsiyadan foydalangan holda muddatidan oldin to'lovni amalga oshirishingiz kerak. Muddatidan oldin to'lash ikki yo'l bilan amalga oshirilishi mumkin: to'la muddatidan oldin to'lash va qisman muddatidan oldin to'lash yo’llari bilan."
        },
      ]
    },
    {
      title: "SHAXSIY KABINET VA SOZLAMALAR",
      id: "six",
      quest: [
        {
          head: "Telefonda kamera bo'lmasa yoki u juda yomon bo'lsa nima qilish kerak?",
          title:
            "Agar sizning telefoningizda kamera bo'lmasa yoki u juda yomon bo'lsa, u holda siz tizimimizning hamkori bo'lgan do'kon sotuvchisi yoradmi bilan ro'yxatdan o'tishingiz mumkin."
        }
      ]
    }
  ],
  faq: { title: "Ko'p so'raladigan savollar" },
  payServices: {
    mobile: "Uyali aloqa operatorlari",
    other: "Internet provayderlar"
  },
  "mobile-operator": "Uyali aloqa operatorlari",
  categories: "Kategoriyalar",
  "last-news": "Oxirgi bloglar",
  provider: "Internet provayderlar",
  partnersList: {
    about: "Xamkor haqida",
    productCategory: "Maxsulotlar kategoriyasi",
    categories: ["Maishiy tehnika", "Mobil telefonlar", "Kompyuter jihozlari"],
    text: {
      mediapark:
        "MEDIAPARK.UZ - Toshkent shahri va viloyatlar aro ko’plab do’konlariga ega maishiy texnika va elektronika savdosiga ixtisoslashgan do’konlar tarmog’i. Mahsulotlarning narx va sifat nisbati bo`yicha eng ilg’or takliflar aynan shu do’konlarda.",
      texnomart:
        "Texnomart - bozor narxlarida savdo qiluvchi maishiy texnika va elektronika do'konlari tarmog'i. Tarmoqning istalgan do’konidan yoki veb-sayt orqali o’zingizga qulay bo’lgan usulda bepul etkazib berish xizmati asosida xarid qilishingiz mumkin.",
      sandiq:
        "Sandiq.shop - O'zbekiston bo’ylab mobil va raqamli texnika sotuvchi etakchi onlayn-do'konlardan biri. Sandiq.shop - internet do'koni bu to'lov va yetkazib berish qulayligidir."
    }
  },
  news: [
    {
      id: 0,
      heading: "Ko'proq sovg'alar - Ramazonda yanada quvonchli daqiqalar! ✨",
      title:
        "Muborak Ramazon oyida Samsung Galaxy S21 | smartfonlarini sotib oling S21 + yoki S21 Ultra, siz aksiyada qatnashishingiz va bepul * Galaxy A8 planshetini olishingiz mumkin | A10.1.",
      href: "/news/detail",
      html:
        "Shuningdek, siz avtomatik ravishda haftalik qimmatli qog'ozlar yutuqlari ishtirokchisiga aylanasiz:\n" +
        "10 dona Galaxy Watch Active 2\n" +
        "10 dona Galaxy Buds Live\n" +
        "Chizma Instagram-da sahifada o'tkaziladi\n" +
        "Aksiya muddati: 28.04.2021 - 24.05.2021 (shu jumladan).\n" +
        "* sovg'alar soni cheklangan!\n" +
        "Ajoyib gadjetlar va qimmatbaho sovg'alarni foydali sotib olishga shoshiling!\n",
      dateTime: "10.05.2021",
      dateDay: "15:25"
    },
    {
      id: 1,
      heading: "✨ Muborak Ramazon oyi sharafiga yanada xayrli harakatlar!",
      title:
        "⚡Galaxy A seriyali smartfonlarni xarid qiling va Samsung Power Bank-ni sovg'a qiling!\n" +
        "Aksiyada ishtirok etadigan smartfonlar: Samsung Galaxy A31 | A32 | A41 | A51",
      href: "/news/detail",
      html:
        "Aksiya muddati: 24.04.2021 - 24.05.2021 (shu jumladan).\n" +
        "Do'konlarda sovg'alar:\n" +
        "- Toshkent shahri, Chorsu maydoni, 3A (Toshkent savdo markazining diqqatga sazovor joyi, sobiq GUM);\n" +
        "- Toshkent, st. Yangi shahar, 16a (Katta Zalning diqqatga sazovor joyi, Mega Planetga etib borguncha).\n" +
        "Aksiya tafsilotlarini samsung.uz veb-saytida topishingiz mumkin\n",
      dateTime: "09.05.2021",
      dateDay: "21:00"
    },
    {
      id: 2,
      heading: "Yangilangan MEDIAPARK Bozori Ipak Yo'lining ochilishi",
      title:
        "MEDIAPARK maishiy texnika do'konlari tarmog'i barcha O'zbekiston fuqarolarini kirib kelayotgan muqaddas Hayit bayrami bilan tabriklaydi",
      href: "/news/detail",
      text:
        "Filialning 1-qavatida siz kichik va katta maishiy texnika, shuningdek oshxona jihozlarini topasiz: gaz plitalari va uy uchun o'rnatilgan jihozlardan tortib, xushbo'y tashqi barbekyu tayyorlash uchun barbekyu. Kompyuter uskunalari, telefonlar, shuningdek muzlatgichlar, kir yuvish mashinalari, konditsionerlar va televizorlar kabi yirik maishiy texnika 2-qavatda joylashgan.\n" +
        "Tovarlarni sotib olayotganda biz sizga yoqimli va farovon muhitni, jahon brendlari mahsulotlarining keng assortimentini, ishlab chiqaruvchilarning xizmat ko'rsatish markazlarining rasmiy kafolati va savdo bo'yicha maslahatchilarimizning yuqori sifatli xizmatlarini taklif etamiz. Bundan tashqari, MEDIAPARK xaridorlari o'zlari va oilalari uchun foydali to'lovlarni tashkil qilishlari mumkin.\n" +
        "Biroq, bu hammasi emas! MEDIAPARK Ipak Yuli javonlarida Amerikaning Kitchen Aid va Yaponiyaning Yamaha brendlari bilan tanishing. Bu erda Amerikaning etakchi Kitchen Aid brendining bir qator premium mahsulotlari namoyish etilgan: sayyora mikserlari, ichki va yirik maishiy texnika, tushdi mashinalari, aralashtirgichlar va boshqalar. Shuningdek, assortimentdagi yorqin yangilik - bu dunyoga taniqli Yamaha musiqa asboblari ishlab chiqaruvchisi tovarlari.\n" +
        "Muqaddas Hayit bayrami uchun o'zingiz va oilangiz uchun mol sotib olishga shoshiling. Ushbu bayram uyingizga va oilangizga baxt va farovonlik keltirsin.\n",
      dateTime: "09.05.2021",
      dateDay: "18:15"
    },
    {
      id: 3,
      heading: "O'zbekistonda birinchi marta - faqat MEDIAPARK BOZORI-da!",
      title:
        "Smartfon, planshet yoki noutbukni sotib olish bilan birgalikda siz MOBILE PROTECTION kartasini sotib olishingiz mumkin.",
      href: "/news/detail",
      text:
        "Agar biron bir nosozlik bo'lsa, siz do'konga texnik xodimga murojaat qilishingiz, buzilgan telefonni qaytarib berishingiz va \"MOBILE PROTECTION\" Kafolat sertifikatini olishingiz mumkin, uni o'sha do'kondagi har qanday mahsulotga almashtirishingiz mumkin.\n" +
        "Natijada, siz kutilmagan vaziyat yuzaga kelgan taqdirda, buzilgan moslamangizni zudlik bilan yangisiga almashtirishga imkon beradigan keng qamrovli xizmatni olasiz!\n" +
        "Xizmatning asosiy parametrlari:\n" +
        "\"MOBILE PROTECTION\" kartasi 75 ming so'mdan iborat bo'lgan mobil uskunalar (mobil telefonlar, smartfonlar, planshetlar va boshqalar) ga qo'shimcha ravishda sotiladi.\n" +
        "\"MOBIL HIMOYa\" kartasi mobil uskunalar sotib olingan kundan boshlab 1 yil davomida amal qiladi. MOBILE PROTECTION Cardning amal qilish muddatining boshlanishi fiskal tushum olingan sana hisoblanadi.\n" +
        "Xarid qilish shartlari:\n" +
        "\"MOBILE PROTECTION\" kartasini faqat sotib olingan kuni sotib olish mumkin.\n" +
        "\"MOBIL HIMOYa\" kartasi uchun to'lov mobil qurilmani sotib olish sanasi bilan bir xil sanada sotuv kvitansiyasida aks ettirilishi kerak.\n" +
        "\"MOBIL HIMOYa\" kartasi faqat kassa cheklari bilan amal qiladi, faqat O'zbekiston Respublikasi qonunlarida nazarda tutilgan hollar bundan mustasno.\n" +
        "MOBILE PROTECTION Card yo'qolgan taqdirda, formani tiklash mumkin emas.\n" +
        "\"MOBILE PROTECTION\" kartasini faollashtirish uchun siz sotib olingan kundan boshlab 30 kun ichida www.mobileprotection.uz veb-saytida ro'yxatdan o'tish jarayonini yakunlashingiz kerak.\n" +
        "\n" +
        "MOBILE PROTECTION kartasini MOBILE PROTECTION kafolat sertifikatiga almashtirish:\n" +
        "\n" +
        "MOBILE PROTECTION Card-ning egasi buzilgan mobil qurilmani MOBILE PROTECTION kafolat sertifikatiga dasturda belgilangan hollarda almashtirish huquqiga ega:\n" +
        "Mexanik shikastlanish - mijozning qasddan qilingan harakatlari bilan to'g'ridan-to'g'ri sababiy aloqasi bo'lmagan taqdirda kutilmagan jismoniy kuchlarning to'satdan mexanik ta'siri natijasida zararlanishning paydo bo'lishi, ya'ni:\n" +
        "    suv toshqini tufayli zarar, suv bilan aloqa qilish;\n" +
        "    tarmoqdagi kuchlanishning pasayishi, elektr toki urishi, tarmoqdagi qisqa tutashuv natijasida shikastlanish;\n" +
        "    tushish, zarba natijasida zarar;\n" +
        "    ochiq olov, olov, olov bilan aloqa qilish natijasida zarar;\n" +
        "    Ishlab chiqaruvchining kafolat shartlarida nazarda tutilgan kafolatlarning buzilishi;\n" +
        "Buzilgan moslama evaziga MOBILE PROTECTION kafolat sertifikatini olish uchun MOBILE PROTECTION Card egasi tomonidan taqdim etiladigan hujjatlar to'plami:\n" +
        "     \"MEDIAPARK BOZORI\" do'konining xodimi tomonidan tekshirilishi uchun mobil qurilmaning o'zi (Istisnolar - bu yong'in holatlari. Yong'in natijasida mobil qurilmani yo'qotish holatlarida vakolatli organning tegishli guvohnomasi taqdim etiladi.)\n" +
        "    Mobil qurilmadan qadoqlash va standart paketning bir qismi bo'lgan mobil qurilmadan aksessuarlar.\n" +
        "    Mobil qurilmaning kafolat kartasi\n" +
        "    Pasport nusxasi, Xaridorning shaxsini tasdiqlovchi boshqa hujjat\n" +
        "    Savdo kvitansiyasining asl nusxasi / nusxasi\n" +
        "    \"MOBILE PROTECTION\" original kartasi\n" +
        "Hujjatlar to'plami va buzilgan moslama evaziga mijoz \"MEDIAPARK BOZORI\" do'konidagi har qanday mahsulotga almashtirilishi mumkin bo'lgan \"MOBIL HIMOYa\" kafolat sertifikatini oladi.\n" +
        "\"MOBILE PROTECTION\" kartasi quyidagilarga taalluqli emas:\n" +
        "    Komponent materiallari (zaryadlovchi qurilmalar, naushniklar, batareyalar va boshqalar)\n" +
        "    Kosonning kosmetik nuqsonlari, mobil qurilmaning normal ishlashiga ta'sir qilmaydi (kassa va ekran yoriqlari, chizish, eritish)\n" +
        "    Ishni ochish izlari bo'lgan qurilmalar;\n" +
        "    Ishlab chiqaruvchi tomonidan ko'rsatilmagan aksessuarlardan foydalanish;\n" +
        "    Mahsulotda begona narsalarning mavjudligi.\n",
      html:
        "Agar biron bir nosozlik bo'lsa, siz do'konga texnik xodimga murojaat qilishingiz, buzilgan telefonni qaytarib berishingiz va \"MOBILE PROTECTION\" Kafolat sertifikatini olishingiz mumkin, uni o'sha do'kondagi har qanday mahsulotga almashtirishingiz mumkin.\n" +
        "Natijada, siz kutilmagan vaziyat yuzaga kelgan taqdirda, buzilgan moslamangizni zudlik bilan yangisiga almashtirishga imkon beradigan keng qamrovli xizmatni olasiz!\n" +
        "Xizmatning asosiy parametrlari:\n" +
        "\"MOBILE PROTECTION\" kartasi 75 ming so'mdan iborat bo'lgan mobil uskunalar (mobil telefonlar, smartfonlar, planshetlar va boshqalar) ga qo'shimcha ravishda sotiladi.\n" +
        "\"MOBIL HIMOYa\" kartasi mobil uskunalar sotib olingan kundan boshlab 1 yil davomida amal qiladi. MOBILE PROTECTION Cardning amal qilish muddatining boshlanishi fiskal tushum olingan sana hisoblanadi.\n" +
        "Xarid qilish shartlari:\n" +
        "\"MOBILE PROTECTION\" kartasini faqat sotib olingan kuni sotib olish mumkin.\n" +
        "\"MOBIL HIMOYa\" kartasi uchun to'lov mobil qurilmani sotib olish sanasi bilan bir xil sanada sotuv kvitansiyasida aks ettirilishi kerak.\n" +
        "\"MOBIL HIMOYa\" kartasi faqat kassa cheklari bilan amal qiladi, faqat O'zbekiston Respublikasi qonunlarida nazarda tutilgan hollar bundan mustasno.\n" +
        "MOBILE PROTECTION Card yo'qolgan taqdirda, formani tiklash mumkin emas.\n" +
        "\"MOBILE PROTECTION\" kartasini faollashtirish uchun siz sotib olingan kundan boshlab 30 kun ichida www.mobileprotection.uz veb-saytida ro'yxatdan o'tish jarayonini yakunlashingiz kerak.\n" +
        "\n" +
        "MOBILE PROTECTION kartasini MOBILE PROTECTION kafolat sertifikatiga almashtirish:\n" +
        "\n" +
        "MOBILE PROTECTION Card-ning egasi buzilgan mobil qurilmani MOBILE PROTECTION kafolat sertifikatiga dasturda belgilangan hollarda almashtirish huquqiga ega:\n" +
        "Mexanik shikastlanish - mijozning qasddan qilingan harakatlari bilan to'g'ridan-to'g'ri sababiy aloqasi bo'lmagan taqdirda kutilmagan jismoniy kuchlarning to'satdan mexanik ta'siri natijasida zararlanishning paydo bo'lishi, ya'ni:\n" +
        "    suv toshqini tufayli zarar, suv bilan aloqa qilish;\n" +
        "    tarmoqdagi kuchlanishning pasayishi, elektr toki urishi, tarmoqdagi qisqa tutashuv natijasida shikastlanish;\n" +
        "    tushish, zarba natijasida zarar;\n" +
        "    ochiq olov, olov, olov bilan aloqa qilish natijasida zarar;\n" +
        "    Ishlab chiqaruvchining kafolat shartlarida nazarda tutilgan kafolatlarning buzilishi;\n" +
        "Buzilgan moslama evaziga MOBILE PROTECTION kafolat sertifikatini olish uchun MOBILE PROTECTION Card egasi tomonidan taqdim etiladigan hujjatlar to'plami:\n" +
        "     \"MEDIAPARK BOZORI\" do'konining xodimi tomonidan tekshirilishi uchun mobil qurilmaning o'zi (Istisnolar - bu yong'in holatlari. Yong'in natijasida mobil qurilmani yo'qotish holatlarida vakolatli organning tegishli guvohnomasi taqdim etiladi.)\n" +
        "    Mobil qurilmadan qadoqlash va standart paketning bir qismi bo'lgan mobil qurilmadan aksessuarlar.\n" +
        "    Mobil qurilmaning kafolat kartasi\n" +
        "    Pasport nusxasi, Xaridorning shaxsini tasdiqlovchi boshqa hujjat\n" +
        "    Savdo kvitansiyasining asl nusxasi / nusxasi\n" +
        "    \"MOBILE PROTECTION\" original kartasi\n" +
        "Hujjatlar to'plami va buzilgan moslama evaziga mijoz \"MEDIAPARK BOZORI\" do'konidagi har qanday mahsulotga almashtirilishi mumkin bo'lgan \"MOBIL HIMOYa\" kafolat sertifikatini oladi.\n" +
        "\"MOBILE PROTECTION\" kartasi quyidagilarga taalluqli emas:\n" +
        "    Komponent materiallari (zaryadlovchi qurilmalar, naushniklar, batareyalar va boshqalar)\n" +
        "    Kosonning kosmetik nuqsonlari, mobil qurilmaning normal ishlashiga ta'sir qilmaydi (kassa va ekran yoriqlari, chizish, eritish)\n" +
        "    Ishni ochish izlari bo'lgan qurilmalar;\n" +
        "    Ishlab chiqaruvchi tomonidan ko'rsatilmagan aksessuarlardan foydalanish;\n" +
        "    Mahsulotda begona narsalarning mavjudligi.\n",
      dateTime: "05.05.2021",
      dateDay: "09:20"
    }
    // {
    //   heading: "Nunc neque vulputate ut.",
    //   title:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fringilla gravida porta aenean quam aenean quam enim sit a. Nunc nunc vulputate donec nulla eu lacus.",
    //   href: "/",
    //   dateTime: "СЕГОДНЯ",
    //   dateDay: "13:23",
    // },
  ],

  // * contract page
  contractPage: {
    qrCode: `Mobil dasturni yuklab olish va shartnomani tasdiqlash uchun <strong>QR-kodni</strong> skaner qiling`,
    qrCodeSec: `To'lov cheki`,
    qrCodeMobile: `Shartnomani tasdiqlash uchun mobil dasturni yuklab oling.`,
    downloadBtn: "Mobil dasturni yuklab olish",
    cashBack: 'Siz xaridning <span style="color:#6610F5;">1% miqdorida “Keshbek”</span></span> olish huquqiga ega boʻldingiz'
  }
};
