<template>
  <div class="registerSidebar">
    <ul>
      <li v-for="(n, i) in steps" :key="i">
        <div
          class="icon"
          :class="n.disabled ? 'disabled' : n.active ? 'active' : ''"
          v-html="n.icon"
        ></div>
        <p :class="n.disabled ? 'disabled' : n.active ? 'active' : ''">
          {{ n.title }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import localize from '../filters/localize.filter';

export default {
  name: 'registerSidebar',
  data: () => ({
    step: [
      {
        icon: `
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 3C7.03749 3 3 7.03749 3 12C3 16.9625 7.03749 21 12 21C16.9625 21 21 16.9625 21 12C21 7.03749 16.9625 3 12 3ZM12 19.875C7.65765 19.875 4.12501 16.3424 4.12501 12C4.12501 7.65765 7.65765 4.12501 12 4.12501C16.3424 4.12501 19.875 7.65765 19.875 12C19.875 16.3424 16.3424 19.875 12 19.875Z" fill="#6610F5"/>
            <path d="M8 12.2222L10.8571 15L16 10" stroke="#6610F5" stroke-width="1.2"/>
          </svg>`,
        title: localize('card-sign')['sidebar']['step-2'],
        active: false,
        disabled: true,
        name: 'registerCart',
      },
      {
        icon: '2',
        title: localize('card-sign')['sidebar']['step-3'],
        active: false,
        disabled: false,
        name: 'upload-passport-s',
      },
      {
        icon: '3',
        title: localize('card-sign')['sidebar']['step-4'],
        active: false,
        disabled: false,
        name: 'upload-address-passport-s',
      },
      {
        icon: '4',
        title: localize('card-sign')['sidebar']['step-5'],
        active: false,
        disabled: false,
        name: 'upload-selfie-passport-s',
      },
      {
        icon: '5',
        title: localize('register-photo')['confidantTitle'],
        active: false,
        disabled: false,
        name: 'confidant-s',
      },
    ],
    stepById: [
      {
        icon: `
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 3C7.03749 3 3 7.03749 3 12C3 16.9625 7.03749 21 12 21C16.9625 21 21 16.9625 21 12C21 7.03749 16.9625 3 12 3ZM12 19.875C7.65765 19.875 4.12501 16.3424 4.12501 12C4.12501 7.65765 7.65765 4.12501 12 4.12501C16.3424 4.12501 19.875 7.65765 19.875 12C19.875 16.3424 16.3424 19.875 12 19.875Z" fill="#6610F5"/>
            <path d="M8 12.2222L10.8571 15L16 10" stroke="#6610F5" stroke-width="1.2"/>
          </svg>`,
        title: localize('card-sign')['sidebar']['step-id-2'],
        active: false,
        disabled: true,
        name: 'registerCart',
      },
      {
        icon: '2',
        title: localize('card-sign')['sidebar']['step-id-3'],
        active: false,
        disabled: false,
        name: 'upload-id-card-s',
      },
      {
        icon: '3',
        title: localize('card-sign')['sidebar']['step-id-4'],
        active: false,
        disabled: false,
        name: 'upload-address-id-card-s',
      },
      {
        icon: '4',
        title: localize('card-sign')['sidebar']['step-id-5'],
        active: false,
        disabled: false,
        name: 'upload-selfie-id-card-s',
      },
      {
        icon: '5',
        title: localize('register-photo')['confidantTitle'],
        active: false,
        disabled: false,
        name: 'confidant-id-card-s',
      },
    ],
  }),
  computed: {
    steps() {
      if (this.$route.name && this.$route.name.includes('id-card')) {
        const filteredSteps = this.stepById.map((n) => {
          return {
            ...n,
            active: n.name === this.$route.meta.names,
          };
        });
        return filteredSteps;
      } else if (this.$route.name) {
        const filteredSteps = this.step.map((n) => {
          return {
            ...n,
            active: n.name === this.$route.meta.names,
          };
        });
        return filteredSteps;
      } else {
        return this.step;
      }
    },
  },
  methods: {
    localize,
  },
  mounted() {
    // if (this.$route.name.includes('id-card')) {
    //   this.step.map((n) => {
    //     n.active = false;
    //   });
    //   this.step = this.stepById;
    //   this.step.filter((n) => {
    //     if (n.name === this.$route.meta.names) {
    //       return (n.active = true);
    //     }
    //   });
    // }

  },
};
</script>

<style lang="scss" scoped>
  .registerSidebar {
    padding-top: 19%;
    background: url("../assets/img/registerSidebarBg.svg") #6610F5 no-repeat center bottom / contain;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh;
    width: 100%;
    // padding-right: 100px;
    ul {
      background-image: url('../assets/icons/linesidebar.svg'), url("../assets/icons/linesidebar.svg");
      background-position: 1% 14%, 2% 70%;
      -webkit-background-size: 3.8%;
      background-size: 3.8%;
      background-repeat: no-repeat, no-repeat;
      height: auto;

      li {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        cursor: pointer;

        .icon {
          width: 32px;
          height: 32px;
          border-radius: 8px;
          font-weight: bold;
          font-size: 20px;
          line-height: 28px;
          background: $secondary;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $main;

          &.disabled {
            background: $secondary;
            cursor: not-allowed;
          }

          &.active {
            background: $white;
            color: $main;
          }
        }

        p {
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          margin-left: 12px;
          color: $secondary;

          &.disabled {
            color: $secondary;
            cursor: not-allowed;
          }

          &.active {
            color: $white;
          }
        }
      }
    }
  }
</style>
