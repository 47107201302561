export default {
  install(Vue) {
    Vue.prototype.$toastError = function (text) {
      if (!text) {
        return;
      }
      this.$toasted.clear();
      return this.$toasted.error(this.$localize(text), {
        className: 'error-toasted',
        duration: 5000,
        action: {
          text: this.$localize('button')['close'],
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    };

    Vue.prototype.$toastSuccess = function (text) {
      if (!text) {
        return;
      }
      this.$toasted.clear();
      return this.$toasted.success(this.$localize(text), {
        className: 'error-toasted',
        duration: 5000,
        action: {
          text: this.$localize('button')['close'],
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    };
  },
};