<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import MainLayout from '@/layouts/MainLayout.vue';
import RegisLayout from '@/layouts/RegisLayout.vue';
import ProfileLayout from '@/layouts/ProfileLayout.vue';
import PromotionLayout from '@/layouts/PromotionLayout.vue';
import $ from 'jquery';

import { createNamespacedHelpers } from 'vuex';

const { mapActions: mapBuyerActions } = createNamespacedHelpers('buyers');

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout';
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
    RegisLayout,
    ProfileLayout,
    PromotionLayout,
  },
  mounted() {
    document.title = this.$localize('documentTitle');

    this.getUserInfo();

    let $links = $('.scrollLink');
    $links.click(function () {
      $(window).scrollTop(0);
    });
  },
  methods: {
    ...mapBuyerActions({
      getUserInfo: 'refresh',
    }),
  },
};
</script>

<style lang="scss">
// @import './assets/font/stylesheet.css';
@import './assets/font/TT-Travels/stylesheet.css';
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import './assets/css/grid.scss';
@import 'assets/css/main';

iframe[scrolling] {
  display: none;
}

#app {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
