<template>
  <nav class="pay_navbar main-navbar" :class="{ noBlur }">
    <div class="container">
      <div class="row">
        <li @click="sidebarClose" class="navbar_brand">
          <router-link :to="logoLink" class="pay_brand scrollLink">
            <img :src="logo" alt="payMarket_logo" />
          </router-link>
        </li>

        <div v-if="!noApp" class="pay_left">
          <ul>
            <li class="for-buyers" @click="sidebarOpen">
              <a href="javascript:">
                {{ "navbar_client" | localize }}
              </a>

              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.5303 8.03033C15.8232 7.73744 15.8232 7.26256 15.5303 6.96967C15.2374 6.67678 14.7626 6.67678 14.4697 6.96967L15.5303 8.03033ZM10.5893 11.9107L10.0589 11.3804L10.0589 11.3804L10.5893 11.9107ZM9.41074 11.9107L9.94107 11.3804L9.94107 11.3804L9.41074 11.9107ZM5.53033 6.96967C5.23744 6.67678 4.76256 6.67678 4.46967 6.96967C4.17678 7.26256 4.17678 7.73744 4.46967 8.03033L5.53033 6.96967ZM14.4697 6.96967L10.0589 11.3804L11.1196 12.4411L15.5303 8.03033L14.4697 6.96967ZM9.94107 11.3804L5.53033 6.96967L4.46967 8.03033L8.88041 12.4411L9.94107 11.3804ZM10.0589 11.3804C10.0264 11.413 9.97362 11.413 9.94107 11.3804L8.88041 12.4411C9.49874 13.0594 10.5013 13.0594 11.1196 12.4411L10.0589 11.3804Z"
                  fill="#6610F5"
                />
              </svg>
            </li>

            <li v-for="(n, i) in itemsLeft" :key="i" @click="sidebarClose">
              <router-link
                :to="n.to"
                active-class="pay_link_active scrollLink"
                exact
              >
                {{ n.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <!--        <router-link v-if="!noApp" to="/" class="pay_login" @click="installPWA">-->
        <!--          <img src="../assets/icons/phoneIcon.svg" alt="" />-->
        <!--          {{ "navbar_download-app" | localize }}-->
        <!--        </router-link>-->
        <li
          v-if="!noApp"
          @mouseover="active = true"
          @mouseout="active = false"
          style="display: flex; align-items: center"
          class="pay_login-wrap"
        >
          <!--          <a class="pay_login scrollLink" v-if="user_info">-->
          <!--            <img :src="user" alt="" />-->
          <!--            {{ userInfo.name }} {{ userInfo.surname }}-->
          <!--          </a>-->
          <router-link :to="{ name: 'auth' }" class="pay_login scrollLink">
            <img :src="logout" alt="" />
            {{ "navbar_singIn" | localize }}
          </router-link>
          <!--          <sign-dropdown :active="active" :user="user_info" />-->
        </li>
        <div class="pay_rigth" v-if="noApp">
          <ul>
            <li v-for="(n, i) in itemsRight" :key="i">
              <router-link
                :to="{ name: n.href }"
                active-class="pay_link_active scrollLink"
                exact
              >
                {{ n.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <lang />
      </div>
    </div>
  </nav>
</template>

<script>
import logo from "@/assets/uzum-logo.svg";
import logout from "@/assets/logout.svg";
import lang from "./lang.vue";
import { mapGetters } from "vuex";
import config from "../config";

export default {
  name: "navigate",
  props: {
    navbarNone: {
      type: Boolean
    },
    itemsLeft: {
      type: Array
    },
    itemsRight: {
      type: Array
    },
    noApp: {
      type: Boolean
    },
    noBlur: {
      type: Boolean
    }
  },
  components: {
    lang
  },
  data: () => ({
    logoLink: config.logoLink,
    installable: false,
    logo,
    logout,
    active: false
  }),

  methods: {
    sidebarOpen() {
      this.active = !this.active;
      this.$emit("sidebarOpens", { active: this.active });
    },
    sidebarClose() {
      this.$emit("sidebarOpens", { active: false });
    }
  },
  computed: {
    ...mapGetters("buyers", ["userInfo"])
  },
  created() {
    let api_token = this.$cookieGet("token");
    let user_info = localStorage.getItem("user_info");

    if (api_token && user_info) {
      this.$axios.get(`/buyers/detail`).then((res) => {
        const { data } = res;
        const info = data.data;
        this.$store.dispatch("buyers/fetchUserInfo", info);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../assets/css/navbar.scss';

@media (max-width: 991px) {
  .pay_navbar {
    display: none !important;
  }
}
</style>
