<template>
  <div id="emptyLayout">
    <Navbar :noApp="true" :itemsRight="items" />
    <router-view />
  </div>
</template>

<script>
import Navbar from '../components/Navbar.vue';
import localizeFilter from '../filters/localize.filter';

export default {
  components: {
    Navbar,
  },
  data: () => ({
    items: [
      {
        title: localizeFilter('banner')['magazines']['title'],
        href: 'partners',
      },
    ],
  }),
};
</script>
<style lang="scss" scoped>
  #emptyLayout {
    &::before {
      content: "";
      position: relative;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      background: $grey;
      width: 100%;
      height: 88px;
    }

    .overlayes {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
      z-index: 900;
    }
  }

  @media (max-width: 575px) {
    #emptyLayout {
      &::before {
        height: 48px;
      }
    }
  }
</style>
