import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index.js";
import i18n from "./i18n/i18n";

import moment from "moment";
// installed components
import MaskedInput from "v-mask";
import Toasted from "vue-toasted";
import Fragment from "vue-fragment";
import Paginate from "vuejs-paginate";
// filters
import localize from "./filters/localize.filter";
// components
import OTPInput from "@8bu/vue-otp-input";
import pButton from "./components/Pbutton";
import Loading from "./components/Loading";
import JsonPrinter from "./components/dev-only/JsonPrinter";
// utils
import numberFormatter from "./utils/numberFormatter";
import toast from "./utils/toast";
import cookie from "./utils/cookie";
import "./utils/axios";
// plugins
import "./plugins/async-computed";
import "./plugins/skeleton";
import "@lottiefiles/lottie-player";
import "./plugins/vue-star-rating";
import "./plugins/aspect-ratio";
// Validation
import { extend, ValidationProvider } from "vee-validate";
import { min as validationRuleMin, required as validationRuleRequired } from "vee-validate/dist/rules";
import validPhone from "@/helpers/validPhone";

window.Vue = Vue;

Vue.config.productionTip = false;
Vue.filter("localize", localize);

Vue.use(Toasted);
Vue.use(Fragment.Plugin);
Vue.use(MaskedInput);
Vue.use(numberFormatter);
Vue.use(toast);
Vue.use(cookie);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("sms-code-input", OTPInput);
Vue.component("paginate", Paginate);
Vue.component("p-button", pButton);
Vue.component("loading", Loading);
Vue.component("vdd", JsonPrinter);

Vue.prototype.$localize = localize;
Vue.prototype.$moment = moment;

// eslint-disable-next-line
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount("#app");

// VeeValidation Rules
// TODO: rules in file
extend("required", {
  ...validationRuleRequired,
  message: localize("validation")["required"]
});

extend("card_check", {
  validate(value) {
    const quartette = value?.split(' ');
    const secondQuartetteLastTwo = quartette[1]?.slice(2, 4);
    if (quartette[0] === "8600") {
      if (secondQuartetteLastTwo === "32" || secondQuartetteLastTwo === "08") {
        return localize("error")["cardAdd"]["c_card_error"];
      }
    }
    return true
  },
  message: localize("error")["cardAdd"]["invalid_card"]
});
extend("date_check", {
  validate(value) {
    let cardExp = moment(value, "MM/YY", true);

    if (cardExp.isValid() && cardExp.endOf("month").isSameOrAfter()) {
      return true;
    } else {
      return localize("error")["cardAdd"]["invalid_card"];
    }
  },
  message: localize("error")["cardAdd"]["invalid_card"]
});

extend("min", {
  ...validationRuleMin,
  message: localize("validation")["userNameLength"]
});

// TODO: Find better solve
extend("min_value", {
  params: ["target"],
  validate(value, { target }) {
    if (process.env.NODE_ENV != "development") {
      return +value.replace(/\s/g, "") >= +target;
    }
    return +value.replace(/\s/g, "");
  },
  message: ((fieldName, { target }) => localize("validation")["minPrice"][target])
});

extend("phone", {
  validate: value => validPhone(value) !== null,
  message: localize("validation")["phoneNumberLength"]
});

extend("phone_type", {
  params: ["target"],
  validate: (value, { target }) => {
    const operatorCode = +value.match(/\(([^()]*)\)/)[1];
    const operatorName = target.toLowerCase();
    const operatorsInfo = {
      "beeline": [90, 91],
      "ucell": [93, 94],
      "mobiuz (ums)": [97, 88],
      "perfectum": [98],
      "uzmobile": [99, 95]
    };
    return operatorsInfo[operatorName].includes(operatorCode);
  },
  message: (fieldName, { target }) => i18n.t("mobile_operator_type", { "operator_type": target })
});

extend("guarantPhoneMatch", {
  params: ["target"],
  validate: (value, { target }) => validPhone(value) !== validPhone(target),
  message: localize("validation")["guarantsPhoneMatchError"]
});
extend("guarantPersonalPhone", {
  params: ["target"],
  validate: (value, { target }) => validPhone(value) !== validPhone(target),
  message: localize("validation")["equalPhoneNumber"]
});
