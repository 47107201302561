export function balance(state) {
  return state.balance;
}

export function balanceAll(state) {
  return state.balance.all || 0;
}

export function userInfo(state) {
  return state.userInfo;
}

export function cardUser(state) {
  return state.cardUser;
}

export function userStatus(state) {
  return state.userStatus;
}

export function passportType(state) {
  return state.passportType;
}

export function getActionStatus(state) {
  return state.actionStatus;
}
