const API_DOMAIN = process.env.VUE_APP_API_DOMAIN

export const config = {
  API_URL: `${API_DOMAIN}/api/v1` || '',
  logoLink: { name: 'home' },
  ymapApiKey: '4502b395-4a44-4c85-afb5-7a888b020c2c',
  iosAppUrl: 'https://apps.apple.com/ru/app/paymart-mobile/id1579281935',
  androidAppUrl:
    'https://play.google.com/store/apps/details?id=uz.paymart.paymart_mobile',
  paymartSupportPhone: '+998 78 777 1515',
  magoApiCredentials: {
    password: 'arf5bgv9uv',
    username: 'paymart',
  },
  magoID: 215242,
  magoToken: '9e1412739fa0ffc7084f6dcebeb1dfa5',
};

export default config;
