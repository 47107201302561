export const versionRu = [
  {
    title:
      'Физическое или юридическое лицо (Заявитель), направляя запрос об оказании электронных услуг на онлайн платформе рассрочки платежей “Uzum Nasiya” (Uzum Nasiya), принимает настоящую политику конфиденциальности персональных данных (Политика) и дает свое согласие на обработку персональных данных.',
  },
  {
    title:
      'Полным и безоговорочным согласием с Политикой (Согласие) и их принятием является подтверждение факта ознакомления Заявителя со всеми пунктами настоящей Политики, путем проставления соответствующей отметки на сайте uzumnasiya.uz.',
  },
  {
    title:
      'Заявитель дает свое Согласие на обработку своих персональных данных OA «Solutions Lab» (оператор), которому принадлежит сайт uzumnasiya.uz.',
  },
  {
    title:
      'Заявитель дает Согласие на обработку следующих персональных данных:',
    children: [
      'фамилия, имя, отчество;',
      'серия и номер паспорта;',
      'адрес прописки и места жительства;',
      'номер телефона;',
      'номер и срок платежной карты;',
      'в соответствии со статьёй 19 Закона Республики Узбекистан «Об обмене кредитной информацией» кредитный отчет с кредитной историей, находящейся в ООО «Кредитное бюро «Кредитно-информационный аналитический центр».',
      'иные данные, предоставляемые Заявителем или получаемые Uzum Nasiya с использованием предоставленных Заявителем данных.',
    ],
  },
  {
    title: 'Целью обработки персональных данных является:',
    children: [
      'идентификация Заявителя на Uzum Nasiya;',
      'оценка платежеспособности Заявителя;',
      'договорные отношения с Заявителем.',
    ],
  },
  {
    title:
      'В ходе обработки с персональными данными Заявителя будут совершены следующие действия:',
    children: [
      'сбор;',
      'систематизация;',
      'хранение;',
      'изменение;',
      'дополнение;',
      'использование;',
      'предоставление;',
      'распространение;',
      'передача;',
      'обезличивание;',
      'уничтожение;',
    ],
  },
  {
    title:
      'Заявителю обеспечивается защита персональных данных от несанкционированного доступа в соответствии законодательством Республики Узбекистан.',
  },
  {
    title:
      'Заявитель подтверждает, что указанные им персональные данные принадлежат лично ему.',
  },
  {
    title:
      'Персональные данные Заявителя хранятся и обрабатываются до момента достижения цели их обработки или отзыва Согласия в соответствии с пунктом 13 настоящей Политики.',
  },
  {
    title:
      'Согласие может быть отозвано Заявителем либо его законным представителем, путем направления отзыва Согласия в произвольной письменной форме в адрес оператора.',
  },
  {
    title: 'В случае просрочки платежа за Товар или за услуги, предоставленные Покупателю в\n' +
      'рамках Договора, Покупатель дает свое согласие на включение Покупателя в «СПИСОК\n' +
      'НЕПЛАТЕЛЬЩИКОВ», размещенный в сети интернет по сайту www.royxat.uz. Доступ к\n' +
      'указанному веб-сайту является публичным и Покупатель дает свое полное согласие на\n' +
      'распространение и передачу его/ее личных данных и всей информации относительно\n' +
      'заключенных с Продавцом Договоров, третьим лицам для использования в рамках системы\n' +
      'оценки (скоринга) и выявления рейтинга платежеспособности Покупателя.'
  },
  {
    title:
      'Заявитель согласен на получение информационных сообщений от Uzum Nasiya.',
  },
  { title: 'Согласие Заявителя действует до момента его отзыва Заявителем.' },
  {
    title:
      'Настоящая Политика действуют с момента размещения на сайте uzumnasiya.uz и может быть изменена оператором без предварительного уведомления Заявителя.',
  },
];

export const versionUz = [
  {
    title:
      'Jismoniy yoki yuridik shaxs (Ariza beruvchi), “Uzum Nasiya” muddatli to’lov hizmatlari onlayn platformasida (Uzum Nasiya) xizmat ko‘rsatilishi uchun so‘rov yuborish vaqtida, mazkur shaxsga doir ma’lumotlarni maxfiylik siyosatini (keyingi o‘rinlarda - Maxfiylik siyosati) qabul qiladi va shaxsga doir ma’lumotlarini ishlov berishga o‘z roziligini beradi;',
  },
  {
    title:
      'Mazkur maxfiylik siyosatining barcha bandlari bilan uzumnasiya.uz saytida Arizachi tanishishi va kerakli belgini qo‘yishi, mazkur maxfiylik siyosatiga uning to‘liq va e’tirozsiz kelishuvini (Kelishuv) bildirishi va ularni qabul qilishi hisoblanadi;',
  },
  {
    title:
      'Arizachi maxfiylik siyosatini qabul qilib, uzumnasiya.uz sayti tegishli bo‘lgan AJ “Solutions Lab” tomonidan shaxsiy ma’lumotlarini ishlov berishga o‘z roziligini beradi;',
  },
  {
    title:
      'Arizachi quyidagi shaxsiy ma’lumotlariga ishlov berishga o‘z roziligini beradi:',
    children: [
      'familiyasi, ismi, otasining ismi;',
      'pasport seriyasi va raqami;',
      'ro’yxatdan o’tish va yashash joyi manzili;',
      'telefon raqami;',
      'to’lov kartasi raqami va muddati;',
      '"Kredit axboroti almashinuvi to\'g\'risida" gi O\'zbekiston Respublikasi Qonunining 19 -moddasiga muvofiq, "Kredit axborot tahlil markazi" Kredit byurosi kredit tarixiga ega bo\'lgan kredit hisoboti.',
      'Ariza beruvchi tomonidan taqdim qilayotgan va yoki Uzum Nasiya tomonidan ushbu ma’lumotlarni ishlatib qo’ga kiritilgan boshqa ma’lumotlar.',
    ],
  },
  {
    title: 'Shaxsga doir ma’lumotlarni ishlov berishning maqsadi:',
    children: [
      'Uzum Nasiya hizmatlaridan foydalanayotgan Arizachini identifikatsiyalash;',
      'Arizachining to’lov qobiliyatini aniqlash;',
      'Arizachi bilan shartnomaviy munosabatlar.',
    ],
  },
  {
    title:
      'Ariza beruvchining shaxsga doir ma’lumotlariga ishlov berish mobaynida quyidagi harakatlar amalga oshiriladi:',
    children: [
      'yig‘ish;',
      'tizimlashtirish;',
      'saqlash;',
      'o‘zgartirish;',
      'to‘ldirish;',
      'ulardan foydalanish;',
      'ularni berish;',
      'tarqatish;',
      'uzatish;',
      'egasizlantirish;',
      'yo‘q qilish;',
    ],
  },
  {
    title:
      'O’zbekiston Respublikasi qonunchiligiga asosan ariza beruvchiga shaxsga doir ma’lumotlarini uning ruxsatisiz foydalanish muxofaza qilinishi ta’minlanadi;',
  },
  {
    title:
      'Ariza beruvchi, u tomonidan ko‘rsatilgan shaxsga doir ma’lumotlar shaxsan unga tegishliligini tasdiqlaydi;',
  },
  {
    title:
      'Ariza beruvchining shaxsga doir ma’lumotlari ularni ishlov berish maqsadiga erishgunga yohud mazkur Maxfiylik siyosatining 13 bandiga muvofiq Kelishuv tugaguniga qadar saqlanadi va ishlov beriladi;',
  },
  {
    title:
      'Kelishuv, Ariza beruvchi yoki uning qonuniy vakili tomonidan o‘z xohishiga ko‘ra xat shaklida operatorga murojaat qilib bekor qilinishi mumkin;',
  },
  {
    title:
      'Ariza beruvchi Uzum Nasiya tomonidan yuboriladigan axborot xabarlarini qabul qilishga roziligini bildiradi;',
  },
  { title: 'Arizachining Kelishuvi uni bekor qilinguniga qadar amalda bo‘ladi;' },
  {
    title:
      'Mazkur maxfiylik siyosati uzumnasiya.uz saytida joylashtirilgan vaqtidan boshlab amalda bo‘ladi va operator tomonidan Ariza beruvchini ogohlantirishsiz o‘zgartirilishi mumkin.',
  },
];

export default {
  versionRu,
  versionUz,
};
