<template>
  <div id="profileLayout">
  <navbar :activeMobile="activeMobile" @open-mobile-sidebar="openMobileSidebar" />
    <div class="container fluid pl230">
      <div
        class="overlay"
        v-if="activeMobile"
        @click="activeMobile = !activeMobile"
      ></div>
      <div class="dn-lg">
        <SidebarProfile />
      </div>
      <div class="d-block-lg">
        <SidebarMobile
          @closeDropdown="closeSidebar"
          @close-sidebar="closeSidebar"
          :active="activeMobile"
        />
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
import navbar from "@/components/profile/Navbar.vue";
import SidebarProfile from "@/components/profile/SidebarProfile.vue";
import SidebarMobile from "@/components/profile/SidebarMobile.vue";
import BuyerService from "@/services/buyer.service";

export default {
  data: () => ({
    activeMobile: false,
  }),
  components: {
    navbar,
    SidebarProfile,
    SidebarMobile,
  },
  methods: {
    openMobileSidebar(active) {
      this.activeMobile = active.activeMobile;
    },
    closeSidebar(active) {
      this.activeMobile = active.active;
    },
    akciyaMore() {
      this.$router.push({
        name: "news-detail",
        params: {
          lang: this.$i18n.locale,
          slug: 19,
        },
      });
    },
  },
  async created() {
    await BuyerService.detail();
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/css/mixins/font";

#profileLayout {
  background: $grey;
  padding-top: 96px;

  .pl230 {
    padding-left: 230px;
  }

  .d-block-lg {
    display: none;
  }

  .member-information {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    text-align: center;
    background-color: $main;
    @include font(14px, 600, normal, $white);

    .text {
      margin-right: 10px;
    }

    .info-button {
      width: auto;
      margin-right: 10px;
      background-color: $white;
      color: $main;
      font-size: 12px;
      padding: 8px;
    }
  }
}

@media (max-width: 1199px) {
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 800;
  }

  .pl230 {
    padding-left: 16px !important;
  }

  .dn-lg {
    display: none !important;
  }

  .d-block-lg {
    display: block !important;
  }
}

@media (max-width: 575px) {
  #profileLayout {
    padding-top: 48px;
  }
}
</style>
