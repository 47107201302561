<template>
  <div
    class="navbar"
    @mousedown="
      (e) => {
        e.preventDefault();
      }
    "
  >
    <div class="overlay-dropdown" v-if="active" @click="active = !active"></div>
    <div class="navbar_container" :class="{ active }">
      <div class="navbar_left">
        <div class="navbar_brand">
          <router-link :to="logoLink">
            <img
              src="@/assets/uzum-logo.svg"
              alt="Logo"
            />
          </router-link>
        </div>


        <div class="navbar_dropdown d-none-lg" :class="{ active }">
          <!--<div class="navbar_rect" @click="active = !active">
            <img src="../../assets/profile_assets/cash.svg" alt="" />
          </div>-->

          <div class="navbar_cash">
            <div class="cash_top">
              <p>{{ localize('balancePopup')['limit'] }}:</p>
            </div>

            <div class="cash_bottom">
              <h5 v-if="loading">Loading...</h5>

              <h5 v-else>{{ $t('currency', { price: balanceAll }) }}</h5>
            </div>
          </div>

          <div class="navbar_cash">
            <div class="cash_top">
              <p>{{ localize('balancePopup')['personalBalance'] }}:</p>

              <router-link :to="{ name: 'profile.balance-up' }">
                <svg width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M18.6787 13.4711L18.6788 17.3199L22.5285 17.3208C22.9023 17.32 23.2065 17.6242 23.2065 17.9989C23.2065 18.1862 23.1305 18.3567 23.0072 18.48C22.8855 18.6017 22.715 18.6777 22.5269 18.6785L18.678 18.6784L18.6781 22.5273C18.6781 22.7146 18.6013 22.8859 18.4796 23.0076C18.3563 23.1309 18.1858 23.2069 17.9985 23.2069C17.6238 23.2069 17.3196 22.9027 17.3204 22.5289L17.3195 18.6792L13.4706 18.6791C13.0968 18.6799 12.7926 18.3757 12.7918 18.0002C12.7926 17.6248 13.096 17.3214 13.4714 17.3206L17.3203 17.3207L17.3202 13.4718C17.3218 13.0956 17.6268 12.7922 17.9998 12.7922C18.3753 12.793 18.6795 13.0972 18.6787 13.4711ZM25.525 25.5257C21.3752 29.6755 14.6245 29.6746 10.4745 25.5246C6.3253 21.3754 6.32434 14.6246 10.4741 10.4749C14.6239 6.32508 21.3747 6.32604 25.5238 10.4752C29.6738 14.6252 29.6748 21.376 25.525 25.5257ZM9.51458 9.51472C4.83567 14.1936 4.83585 21.8065 9.51418 26.4849C14.1933 31.164 21.8062 31.1642 26.4851 26.4853C31.1641 21.8064 31.1639 14.1935 26.4847 9.51432C21.8064 4.83599 14.1935 4.83581 9.51458 9.51472Z"
                        fill="#FFFFFF" />
                </svg>
              </router-link>
            </div>

            <div class="cash_bottom">
              <h5 v-if="loading">Loading...</h5>

              <h5 v-else class="text-main">{{ $t('currency', { price: this.$numberFormat(balance.deposit) }) }}</h5>
            </div>
          </div>

          <DropdownMenu
            :balance="balance"
            @closeDropdown="closeDropdown"
            :active="active"
          />
        </div>
      </div>

      <div class="navbar_right d-none-lg">
        <ul>
          <li class="link">
            <router-link :to="{ name: 'partners' }">
              {{ localize('banner')['magazines']['title'] }}
            </router-link>
          </li>

          <li class="icon_link lang">
            <a href="javascript:" class="ru-RU">
              <img src="../../assets/icons/rus.png" alt="" />
            </a>

            <a href="javascript:" class="uz-UZ">
              <img src="../../assets/icons/uzb.png" alt="" />
            </a>
          </li>

          <!-- <li class="icon_link">
            <router-link to="/profile">
              <img src="../../assets/profile_assets/notification.svg" alt="" />
            </router-link>
          </li>
          <li class="icon_link">
            <router-link to="/profile">
              <img src="../../assets/profile_assets/settings.svg" alt="" />
            </router-link>
          </li> -->

          <li class="icon_link" @click="clearStorage">
            <router-link :to="{ name: 'profile-index' }">
              <img src="../../assets/profile_assets/log-out.svg" alt="" />
            </router-link>
          </li>
        </ul>
      </div>

      <div class="menu-icon">
        <div class="navbar_btn" @click="openMobileSidebar">
          <svg
            class="hamburger_menu"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.33301 6.668H18.667M5.33301 12H18.667M5.33301 17.332H18.667"
              stroke="#1E1E1E"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapGetters } from 'vuex';
import DropdownMenu from './DropdownMenu.vue';
// import MainButton from '../buttons/MainButton';
import localize from '../../filters/localize.filter';
import i18n from '../../i18n/i18n';
import router from '../../router';
import BuyerService from '../../services/buyer.service';
import config from '../../config';

export default {
  name: 'Navbar',
  props: {
    activeMobile: {
      type: Boolean,
    },
  },
  data: () => ({
    logoLink: config.logoLink,
    active: false,
    loading: false,
    lang: localStorage.getItem('lang') || 'ru-RU',
  }),
  components: {
    DropdownMenu,
    // MainButton,
  },
  computed: {
    ...mapGetters('buyers', ['balance', 'getActionStatus']),
    balanceAll() {
      return this.$numberFormat(this.balance.all);
    },
  },
  methods: {
    localize,
    openMobileSidebar() {
      this.$emit('open-mobile-sidebar', { activeMobile: !this.activeMobile });
    },
    openCashMenu() {
      this.active = true;
    },
    closeDropdown(active) {
      this.active = active;
    },
    clearStorage() {
      this.$cookieRemove('token');
      localStorage.removeItem('user_info');
      localStorage.removeItem('number');
      localStorage.removeItem('card_hash');
      localStorage.removeItem('hash');
      localStorage.removeItem('check');
      this.$store.dispatch('buyers/signOut');
      this.$router.push({ name: 'home' });
    },
  },
  created() {
    BuyerService.balance();
  },
  mounted() {
    $(`.lang a`).not(`.${this.lang}`).css({ display: 'none' });

    let $this = this;

    $(`.lang`).click(function () {
      $('.' + `${$this.lang}`).slideUp();
      $(`.lang a`).not(`.${$this.lang}`).slideDown();

      if (localStorage.getItem('lang') == 'ru-RU') {
        localStorage.setItem('lang', 'uz-UZ');
        i18n.locale = 'uz';

        router.push({ params: { lang: 'uz' } });
        window.location.reload();
      } else {
        localStorage.setItem('lang', 'ru-RU');
        i18n.locale = 'ru';

        router.push({ params: { lang: 'ru' } });
        window.location.reload();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
  @import "src/assets/css/mixins/font";

  .navbar {
    height: 96px;
    background: $white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(45px);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 900;
    width: 100%;

    &.akciya {
      top: 48px;
    }

    &_left {
      height: 100%;
      display: flex;
    }

    &_container {
      height: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 1000;

      &.active {
        background: $white;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.04);
      }
    }

    &_brand {
      padding-left: 16px;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 90px;
    }

    &_rect {
      background: rgba(102, 16, 245, 0.1);
      border-radius: 8px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
    }

    &_dropdown {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.33s;
      position: relative;
      padding: 0 24px;
      // &*{
      //   z-index: 2;
      // }
      // &.active{
      //   padding: 0 24px;
      //   background: $grey;
      // }
      // &:hover {
      //   background: $grey;
      //   padding: 0 24px;
      // }
    }

    &_cash {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:first-child {
        border-right: 2px solid $grey;
        padding-right: 24px;
      }

      &:nth-child(2) {
        padding-left: 24px;
      }

      .cash_top {
        display: flex;
        align-items: center;

        p {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          display: flex;
          align-items: center;
          color: $black;
          margin-right: 4px;
        }

        svg {
          path {
            fill: $main;
          }
        }
      }

      .cash_bottom {
        display: flex;
        align-items: center;

        h5 {
          font-weight: 900;
          font-size: 24px;
          line-height: 30px;
          display: flex;
          align-items: center;
          color: $black;
          margin-right: 12px;

          &.text-main {
            color: $main;
          }
        }

        span {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          display: flex;
          align-items: flex-end;
          color: $black;
          opacity: 0.3;
        }
      }
    }

    &_right {
      height: 100%;

      ul {
        display: flex;
        height: 100%;

        li.link a {
          height: 100%;
          display: flex;
          padding: 0px 24px;
          align-items: center;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          color: $black;
        }

        li.icon_link a {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0px 24px;
          transition: 0.33s;

          &:hover {
            background: $grey;
          }
        }
      }
    }
  }

  .overlay-dropdown {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.3);
  }

  .menu-icon {
    display: none;
  }

  .lang {
    overflow: hidden;

    a img {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 1199px) {
    .d-none-lg {
      display: none !important;
    }
    .menu-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding-right: 24px;
    }
  }

  @media (max-width: 575px) {
    .navbar {
      height: 61px;

      &_brand {
        padding: 16px;
        margin-top: 3px;

        img {
          width: 108px;
        }
      }
    }
  }
</style>
