export default function () {
  return {
    balance: {},
    userInfo: {},
    cardUser: [],
    userStatus: 0,
    actionStatus: 0,
    passportType: null,
    productId: null,
    hasPurchase: false
  };
};