import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const element = document.querySelector(to.hash);

      if (element) {
        window.scrollTo({
          top: element.offsetTop - 90,
          behavior: 'smooth',
        });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
});

// router.beforeEach((t, f, next) => {
//   const requiredAuth = t.matched.some((rec) => rec.meta.auth);
//   const requiredAuthCard = t.matched.some((rec) => rec.meta.authCard);
//   const requiredAuthToken = t.matched.some((rec) => rec.meta.authToken);
//   const requiredAuthUserInfo = t.matched.some((rec) => rec.meta.authUserInfo);
//   const requiredAuthUser = t.matched.some((rec) => rec.meta.authUser);
//
//   const number = localStorage.getItem("number");
//   const api_token = localStorage.getItem("api_token");
//   const card_hash = localStorage.getItem("card_hash");
//   const user_info = localStorage.getItem("user_info");
//
//   if (requiredAuthUser && user_info) {
//     next("/profile");
//   }
//   if (requiredAuth && !number) {
//     next("/sign-up?error=unCurretTelNumber");
//     window.location.reload();
//   }
//   if (requiredAuthToken && !api_token) {
//     next("/phone-number-confirm");
//     window.location.reload();
//   }
//   if (requiredAuthCard && !card_hash) {
//     next("/cart_sign");
//     window.location.reload();
//   }
//   if (requiredAuthUserInfo && !user_info) {
//     next("/register_photo");
//     window.location.reload();
//   } else {
//     next();
//   }
// });

export default router;
