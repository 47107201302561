<template>
  <div
    class="lang_pay"
    :class="{ white }"
    @mouseover="active = true"
    @mouseout="active = false"
  >
    <div class="lang_pay_wrap">
      <img :src="$route.params.lang === 'uz' ? uzbIcon : ruIcon" alt="" />
      <span>{{ $route.params.lang === 'uz' ? 'UZ' : 'RU' }}</span>
    </div>
    <div class="lang_pay_lists" :class="{ active }">
      <ul>
        <li v-for="(n, i) in list" :key="i" @click="langHandler(n)">
          <img :src="n.icon" alt="" />
          <span>{{ n.title }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ruIcon from '@/assets/icons/rus.png';
import uzbIcon from '@/assets/icons/uzb.png';

export default {
  props: {
    white: {
      type: Boolean,
    },
  },
  data: () => ({
    active: false,
    defaultLang: null,
    ruIcon,
    uzbIcon,
    list: [
      {
        title: 'RU',
        icon: ruIcon,
      },
      {
        title: 'UZ',
        icon: uzbIcon,
      },
    ],
  }),
  methods: {
    langHandler(e) {
      this.defaultLang = e;
      if (e.title == 'RU') {
        localStorage.setItem('lang', 'ru-RU');
        this.$cookieSet('locale', 'ru');
        this.$i18n.locale = 'ru';
        this.$router.push({ params: { lang: 'ru' } });
        window.location.reload();
      } else {
        localStorage.setItem('lang', 'uz-UZ');
        this.$cookieSet('locale', 'uz');
        this.$i18n.locale = 'uz';
        this.$router.push({ params: { lang: 'uz' } });
        window.location.reload();
      }
    },
  },
  mounted() {
    if (localStorage.getItem('lang') == 'uz-UZ') {
      this.defaultLang = {
        title: 'UZB',
        icon: this.uzbIcon,
      };
    } else {
      this.defaultLang = {
        title: 'RU',
        icon: this.ruIcon,
      };
    }
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/css/navbar.scss";

  .white {
    .lang_pay_wrap {
      color: white;

      &:hover {
        color: rgba(255, 255, 255, 0.63);
      }
    }
  }
</style>
