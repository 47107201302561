import axios from 'axios'
import Cookies from 'js-cookie'
import config from '@/config'

const token = Cookies.get('token')

export default axios.create({
  baseURL: config.API_URL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});