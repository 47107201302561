export default {
  authorizationFailed: {
    title: "Siz ro'yxatdan o'tmagansiz",
    text: "Ro'yxatdan o'tish uchun ushbu silka orqali ilovani yuklang:"
  },
  currency: '{price} so\'m',
  month: '{number}{hyphen}oy',
  phone: 'Tel: {number}',
  akciyaFrom: '{title}\'dan aksiya',
  'mobile_operator_type': "{operator_type} prefiks kodi noto'g'ri",
  auth: {
    personalData: '<a href="/uz/politic-confidence" target="_blank" class="text-main">Shaxsiy ma\'lumotlarni</a> qayta ishlashga rozilik',
  },
  map: {
    label: 'Shahar bo\'ylab filiallar ({number})'
  },
  nds: '(QQS bilan)',
  description: 'Tavsifi',
  installmentMonth: '/ 12 oy.',
  halyal: {
    title: "Uzum Nasiya - halol muddatli to'lov tizimi",
    text: "Uzum Nasiya o'z xizmatlarini shariat me'yorlari va qonunlariga muvofiq taqdim etadi, bu, o'z navbatida, xizmatning ochiqligi va halolligini bildiradi. Bu O‘zbekiston Respublikasi Musulmonlari Idorasining tegishli hujjati bilan tasdiqlangan. Uzum Nasiyaning halol muddatli to'lov tizimi ekanligi hech qanday yashirin to'lovlar va jarimalar yo'qligi bilan ifodalanadi. Uzum Nasiya muddatli to'lovi qarzdorlikni nazarda tutmaydi, bu esa bizni kreditdan ajratib turadi. Uzum Nasiya bilan xarid qilganingizda siz har doim to'lov muddati qachon tugashini bilib turasiz."
  },
  search: 'Qidirish',
}
