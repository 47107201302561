import ru from '../localize/ru';
import uz from '../localize/uz';
import i18n from '../i18n/i18n';

const locals = {
  'ru': ru,
  'uz': uz,
};

export default function localizeFilter(key) {
  let locale = '';
  if (!i18n.locale) {
    locale = 'ru-RU';
  } else {
    locale = i18n.locale;
  }
  // TODO: Was: [Localize ERROR] - key ${key} not found . Need error catch
  return locals[locale][key] || key;
}
