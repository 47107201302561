import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';
import createPersistedState from 'vuex-persistedstate';

//modules
import buyers from './buyers';
import filter from './filter';
import common from './common';

Vue.use(Vuex);

const buyersState = createPersistedState({
  key: 'status',
  paths: ['buyers'],
  storage: {
    getItem: key => Cookies.get(key),
    setItem: (key, value) => Cookies.set(key, value, {
      expires: 2,
    }),
    removeItem: key => Cookies.remove(key),
  },
});

export default new Vuex.Store({
  modules: { buyers, filter, common },
  plugins: [buyersState],
});
