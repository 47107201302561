<template>
  <nav
    class="pay_navbar wheat_navbar"
    :class="`${background} ` + `${noBlur ? 'noBlur' : ''}`"
  >
    <div class="container">
      <div class="row">
        <li @click="sidebarClose" class="navbar_brand">
          <router-link :to="{ name: 'home' }" class="pay_brand scrollLink">
            <img :src="logo" alt="payMarket_logo" />
          </router-link>
        </li>
        <div class="pay_left">
          <ul>
            <li>
              <a @click="sidebarOpen" href="javascript:">
                {{ 'navbar_client' | localize }}
              </a>

              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.5303 8.03033C15.8232 7.73744 15.8232 7.26256 15.5303 6.96967C15.2374 6.67678 14.7626 6.67678 14.4697 6.96967L15.5303 8.03033ZM10.5893 11.9107L10.0589 11.3804L10.0589 11.3804L10.5893 11.9107ZM9.41074 11.9107L9.94107 11.3804L9.94107 11.3804L9.41074 11.9107ZM5.53033 6.96967C5.23744 6.67678 4.76256 6.67678 4.46967 6.96967C4.17678 7.26256 4.17678 7.73744 4.46967 8.03033L5.53033 6.96967ZM14.4697 6.96967L10.0589 11.3804L11.1196 12.4411L15.5303 8.03033L14.4697 6.96967ZM9.94107 11.3804L5.53033 6.96967L4.46967 8.03033L8.88041 12.4411L9.94107 11.3804ZM10.0589 11.3804C10.0264 11.413 9.97362 11.413 9.94107 11.3804L8.88041 12.4411C9.49874 13.0594 10.5013 13.0594 11.1196 12.4411L10.0589 11.3804Z"
                  fill="#6610F5" />
              </svg>
            </li>
            <li
              v-for="(n, i) in itemsLeft"
              :key="i"
              @click.prevent="sidebarClose"
            >
              <router-link
                :to="n.to"
                active-class="pay_link_active"
                class="scrollLink"
                exact
              >
                {{ n.title }}
              </router-link>
            </li>
          </ul>
        </div>
        <!--        <a href="javascript:" class="pay_login scrollLink" @click="installPWA">-->
        <!--          <img src="../assets/icons/phoneIcon.svg" alt="" />-->
        <!--          {{ "navbar_download-app" | localize }}-->
        <!--        </a>-->
        <li @click="sidebarClose" style="display: flex; align-items: center">
          <router-link :to="{ name: 'auth' }" class="pay_login scrollLink">
            <img :src="logouts" alt="" />
            {{ 'navbar_singIn' | localize }}
          </router-link>
        </li>
        <lang />
      </div>
    </div>
  </nav>
</template>

<script>
import logoMain from '@/assets/uzum-logo.svg';
import logoutMain from '../assets/logout.svg';
import lang from './lang.vue';

export default {
  components: { lang },
  name: 'navigate',
  props: {
    itemsLeft: {
      type: Array,
    },
    itemsRight: {
      type: Array,
    },
    background: {
      type: String,
    },
    noBlur: {
      type: Boolean,
    },
  },
  data: () => ({
    installable: false,
    deferredPrompt: null,
    logo: logoMain,
    logouts: logoutMain,
  }),
  methods: {
    async installPWA() {
      this.deferredPrompt.prompt();
    },
    langHandler(e) {
      this.defaultLang = e;
    },
    sidebarOpen() {
      this.$emit('sidebarOpens', { active: true });
    },
    sidebarClose() {
      this.$emit('sidebarOpens', { active: false });
    },
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
    });
  },
};
</script>
<style lang="scss" scoped>
  @import "../assets/css/navbar.scss";

  .pay_navbar {
    background: #F7F2FF;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.04);

    &.blue {
      background: rgba(238, 247, 255, 0.65);
      box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.04);

      &.scrollAnim {
        background: rgba(249, 249, 249, 0.65);
      }
    }

    &.scrollAnim {
      background: rgba(249, 249, 249, 0.65);
    }
  }

  @media (max-width: 991px) {
    .pay_navbar {
      display: none !important;
    }
  }
</style>
