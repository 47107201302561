<template>
  <div class="dropdown-menu" :class="{ active }">
    <ul>
      <li class="installment_plan">
        <h3>{{ localize('balancePopup')['limit'] }}</h3>
        <p>{{ localize('balancePopup')['possible'] }}</p>
        <div class="price">
          <h4>{{ $t('currency', { price: $numberFormat(balance.installment) }) }}</h4>
        </div>
      </li>
      <hr />
      <li class="deposit">
        <h3>
          {{ localize('balancePopup')['personalBalance'] }}:
          <span @click="pushRoute">
            {{ localize('balancePopup')['topUp'] }}
            <img src="../../assets/profile_assets/add.svg" alt="" />
          </span>
        </h3>
        <p>{{ localize('balancePopup')['yourDeposit'] }}</p>
        <div class="price">
          <h4>{{ $t('currency', { price: $numberFormat(balance.deposit) }) }}</h4>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import localize from '../../filters/localize.filter';

export default {
  name: 'DropdownMenu',
  props: {
    active: {
      type: Boolean,
    },
    balance: {
      type: Object,
    },
  },
  methods: {
    localize,
    pushRoute() {
      this.$router.push({ name: 'profile.balance-up' });
      this.$emit('closeDropdown', false);
    },
  },
};
</script>

<style lang="scss" scoped>
  .dropdown-menu {
    background: $white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 0 0 12px 12px;
    padding: 24px;
    position: absolute;
    top: -500px;
    left: 0;
    z-index: 1;
    width: 100%;

    &.active {
      top: 100%;
      transition-duration: 0.5s;
    }

    .installment_plan {
      padding-bottom: 14px;

      h3 {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: $black;
      }

      p {
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $black;
        margin-bottom: 12px;
      }

      .price {
        display: flex;
        height: 40px;

        h4 {
          font-weight: 900;
          font-size: 24px;
          line-height: 40px;
          display: flex;
          align-items: center;
          color: $black;
        }

        p {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          display: flex;
          align-items: flex-end;
          color: $black;
          opacity: 0.3;
          margin-bottom: 0px;
          margin-left: 12px;
        }
      }
    }

    hr {
      opacity: 0.1;
      border: 1px solid #1e1e1e;
      margin-bottom: 12px;
    }

    .deposit {
      h3 {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        align-items: center;
        color: $black;
        justify-content: space-between;

        span {
          font-weight: normal;
          font-size: 14px;
          line-height: 111.63%;
          display: flex;
          align-items: center;
          color: $main;
          display: flex;
          align-items: center;
        }
      }

      p {
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: $black;
        margin-bottom: 12px;
      }

      .price {
        display: flex;
        height: 40px;

        h4 {
          font-weight: 900;
          font-size: 24px;
          line-height: 30px;
          display: flex;
          align-items: center;
          color: $main;
        }

        p {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          display: flex;
          align-items: flex-end;
          color: $black;
          opacity: 0.3;
          margin-bottom: 0px;
          margin-left: 12px;
        }
      }
    }
  }
</style>
