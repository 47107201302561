import Cookies from 'js-cookie';

export default {
  install(Vue) {
    // set cookie
    Vue.prototype.$cookieSet = function (key, item, expires = 2) {
      if (!key || !item) {
        return false;
      }

      return Cookies.set(key, item, { expires });
    };

    // get cookie
    Vue.prototype.$cookieGet = function (key, item) {
      if (!key || item) {
        return false;
      }

      return Cookies.get(key, item)
    };

    // remove cookie
    Vue.prototype.$cookieRemove = function (key) {
      if (!key) {
        return false;
      }

      return Cookies.remove(key)
    };
  },
};