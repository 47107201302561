<template>
  <div class="pay_navbar mobile_navbar">
    <div class="navbar_top">
      <ul>
        <li>
          <!--          <h1>asdasd</h1>-->
          <router-link :to="{ name: 'home' }" class="scrollLink">
            <img v-if="!active" src="@/assets/uzum-logo.svg" alt="" />
            <img v-else src="@/assets/uzum-logo-white.svg" alt="" />
          </router-link>
        </li>
        <li class="navbar_top_btn" @click="changeLogo">
          <a href="javascript:">
            <svg class="hamburger_menu" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M5.33301 6.668H18.667M5.33301 12H18.667M5.33301 17.332H18.667" stroke="#6610F5"
                    stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </a>
        </li>
      </ul>
    </div>
    <div class="navbar_slide">
      <ul>
        <li v-for="(n, i) in links" :key="i">
          <router-link class="link_router scrollLink" @click="changeLogo" :to="{ name: n.href }" v-if="n.href">
            <div @click="changeLogo">
              <span>{{ n.title }}</span>
              <div class="icon">
                <img :src="n.icon" alt="" />
              </div>
            </div>
          </router-link>
          <p v-if="!n.href">
            <span class="title" data-href="slide-item">
              <span>{{ n.title }}</span>
              <span class="icon">
                <img :src="n.icon" alt="" />
              </span>
            </span>
          <ul v-if="n.item" class="slide-item">
            <li @click="changeLogo" v-for="(l, i) in n.item" :key="i">
              <router-link class="scrollLink" :to="{ name: l.href}">{{ l.title }}</router-link>
            </li>
          </ul>
          </p>
        </li>
        <li class="lang">
          <div class="lang_container">
            <div class="lang_title">
              <img class="lang_img" :src="defaultLang ? defaultLang.icon : ruIcon" alt="">
              <p>{{ defaultLang ? defaultLang.title : "Русский" }}</p>
            </div>
            <span>
              <img class="lang_plus" :src="plusIcon" alt="">
            </span>
          </div>
          <ul class="lang_item">
            <li v-for="(n, i) in list" :key="i" @click="langHandler(n)">
              <img :src="n.icon" alt="" />
              <span>{{ n.title }}</span>
            </li>
          </ul>
        </li>
        <li>
          <router-link :to="{ name: 'auth' }" class="sign_btn scrollLink">
            <img src="../assets/logout.svg" alt="">
            {{ localize("button")["go-profile"] }}
          </router-link>
        </li>
        <!--        <li class="down_app">-->
        <!--          <h4>Скачайте приложение</h4>-->
        <!--          <p>И делайта моментальные покупки</p>-->
        <!--          <a href="/sign-up" class="sign_btn" @click="installPWA">-->
        <!--            <img src="../assets/icons/phoneIcon.svg" alt="">-->
        <!--            Скачать-->
        <!--          </a>-->
        <!--        </li>-->
      </ul>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import ruIcon from "@/assets/icons/rus.png";
import uzbIcon from "@/assets/icons/uzb.png";
import plusIcon from "@/assets/icons/plus-mobile.svg";
import closeIcon from "@/assets/icons/close-mobile.svg";
import localize from "../filters/localize.filter";
import chevronRight from "@/assets/icons/chevron-right-mobile.svg";

export default {
  data: () => ({
    installable: false,
    deferredPrompt: null,
    plusIcon,
    links: [
      {
        title: localize("navbar_business"),
        icon: chevronRight,
        href: "business"
      },
      {
        icon: plusIcon,
        closeIcon,
        title: localize("navbar_client"),
        item: [
          {
            title: localize("footer")["info"]["how-to-works"],
            href: "how-it-works"
          },
          {
            title: localize("footer")["info"]["partners"],
            href: "partners"
          },
          {
            title: localize("footer")["info"]["news"],
            href: "news"
          },
          {
            title: localize("footer")["installments"]["title"],
            name: "faq"
          }
        ]
      }
    ],
    ruIcon,
    active: false,
    defaultLang: null,
    list: [
      {
        title: "Русский",
        icon: ruIcon
      },
      {
        title: "O'zbekcha",
        icon: uzbIcon
      }
    ]
  }),
  methods: {
    localize,
    async installPWA() {
      this.deferredPrompt.prompt();
    },
    langHandler(e) {
      this.defaultLang = e;
      if (e.title == "Русский") {
        localStorage.setItem("lang", "ru-RU");
        this.$i18n.locale = "ru";
        this.$router.push({ params: { lang: "ru" } });
        window.location.reload();
      } else {
        localStorage.setItem("lang", "uz-UZ");
        this.$i18n.locale = "uz";
        this.$router.push({ params: { lang: "uz" } });
        window.location.reload();
      }
    },
    changeLogo() {
      this.active = !this.active;
    }
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  mounted() {
    let slideItem = $("[data-href = slide-item]");
    let dataHref = $(document).find("." + `${$(slideItem).attr("data-href")}`);
    slideItem.click(function() {
      let icon = $(this).find(".icon");
      dataHref.slideToggle();
      icon.toggleClass("active");
    });
    $(".sign_btn").click(function() {
      $(".navbar_top_btn").removeClass("active");
      $(".navbar_top_btn a").html(`<svg class="hamburger_menu" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.33301 6.668H18.667M5.33301 12H18.667M5.33301 17.332H18.667" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`);
    });
    dataHref.find("li").click(function() {
      $(".navbar_slide").slideToggle();
      $(".pay_navbar").toggleClass("active");
      $(".hamburger_menu").toggleClass("active");
      $(".navbar_top_btn").removeClass("active");
      $(".navbar_top_btn a").html(`<svg class="hamburger_menu" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.33301 6.668H18.667M5.33301 12H18.667M5.33301 17.332H18.667" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`);
      $(document).find("." + `${slideItem.attr("data-href")}`).slideUp();
    });
    $(".link_router").click(function() {
      $(".navbar_slide").slideToggle();
      $(".pay_navbar").toggleClass("active");
      $(".hamburger_menu").toggleClass("active");
      $(".navbar_top_btn").removeClass("active");
      $(".navbar_top_btn a").html(`<svg class="hamburger_menu" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.33301 6.668H18.667M5.33301 12H18.667M5.33301 17.332H18.667" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`);
      $(document).find("." + `${slideItem.attr("data-href")}`).slideUp();
    });
    $(".slide-item").click(function() {
      $(this).slideToggle();
    });
    $(".navbar_top_btn").click(function() {
      $(".navbar_slide").slideToggle();
      $(".pay_navbar").toggleClass("active");
      $(".hamburger_menu").toggleClass("active");

      if (!$(this).hasClass("active")) {
        $(this).find("a").html(`<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.1747 5.86099L16.4676 5.15389L11.0302 10.5913L5.87632 5.43734L5.16922 6.14444L10.3231 11.2984L5.1539 16.4676L5.86101 17.1747L11.0302 12.0055L16.4829 17.4582L17.19 16.751L11.7373 11.2984L17.1747 5.86099Z" fill="#F6F6F6"/>
          </svg>`);
        $(this).addClass("active");
      } else {
        $(this).find("a").html(`<svg class="hamburger_menu" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.33301 6.668H18.667M5.33301 12H18.667M5.33301 17.332H18.667" stroke="#1E1E1E" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>`);
        $(this).removeClass("active");
      }
      $(document).find("." + `${slideItem.attr("data-href")}`).slideUp();
    });
    $(".lang_container").click(function() {
      $(".lang_item").slideToggle();
      $(".lang_plus").toggleClass("active");

    });
    if (localStorage.getItem("lang") == "ru-RU") {
      this.defaultLang = {
        title: "Русский",
        icon: ruIcon
      };
    } else {
      this.defaultLang = {
        title: "O'zbekcha",
        icon: uzbIcon
      };
    }
  }
};
</script>
<style lang="scss" scoped>
.pay_navbar {
  min-height: 48px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 900;
  padding: 16px;
  background: rgba(246, 246, 246, 0.65);
  transition: .4s;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(45px);
  display: none;

  &.active {
    background: $main;
    height: 100vh;
  }

  .hamburger_menu {
    &.active {
      path {
        stroke: $white;
      }
    }
  }

  .navbar_top {
    width: 100%;

    ul {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      li a {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          @media screen and (max-width: $mobile) {
            width: 117px;
          }
        }
      }
    }
  }

  .navbar_slide {
    display: none;
    padding-top: 16px;

    ul {
      width: 100%;

      li {
        width: 100%;
        box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.08);

        a {
          div {
            padding: 12px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #f6f6f6;
          }
        }

        p {

          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #f6f6f6;

          .title {
            padding: 12px 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon {
              transition: 0.4s;
              transform: rotate(0deg);

              &.active {
                transform: rotate(-45deg) !important;
              }
            }
          }
        }

        .slide-item {
          display: none;

          li {
            box-shadow: none;

            a {
              padding-top: 4px;
              padding-bottom: 4px;
              font-weight: normal;
              font-size: 14px;
              line-height: 32px;
              letter-spacing: 0.01em;
              color: #f6f6f6;
            }
          }
        }
      }

      li.lang {
        width: 100%;

        .lang_container {
          width: 100%;
          display: flex;
          align-items: center;
          padding-top: 16px;
          padding-bottom: 16px;
          justify-content: space-between;

          img.lang_img {
            width: 20px;
            height: 20px;
            object-fit: cover;
          }

          img.lang_plus {
            transition: .4s;

            &.active {
              transform: rotate(45deg);
            }
          }

          .lang_title {
            display: flex;
            align-items: center;

            p {
              margin-left: 8px;
            }
          }
        }

        .lang_item {
          display: none;

          li {
            box-shadow: none;

            img {
              width: 20px;
              height: 20px;
              object-fit: cover;
            }

            padding-top: 4px;
            padding-bottom: 4px;
            font-weight: normal;
            font-size: 14px;
            line-height: 32px;
            letter-spacing: 0.01em;
            color: #f6f6f6;
            display: flex;
            align-items: center;

            span {
              margin-left: 8px;
            }
          }
        }
      }

      li {
        width: 100%;

        .sign_btn {
          background: $white;
          padding-top: 15px;
          padding-bottom: 15px;
          box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
          border-radius: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          color: $main;
          margin-top: 24px;

          img {
            margin-right: 10px;
          }
        }
      }

      li.down_app {
        margin-top: 24px;

        h4 {
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #F6F6F6;
        }

        p {
          font-weight: 600;
          font-size: 13px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #F6F6F6;
          margin-bottom: 8px;
        }

        a {
          padding-top: 11px;
          padding-bottom: 11px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .pay_navbar {
    display: block;
  }
}
</style>
