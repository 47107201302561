import Vue from 'vue';
import store from '../store';
import axios from '../utils/axios';
import i18n from '../i18n/i18n';
import localize from '../filters/localize.filter';

class AbstractService {
  $axios = axios;
  $store = store;
  $i18n = i18n;

  $numberFormat(number) {
    return new Intl.NumberFormat('ru-RU').format(number);
  }

  $toast(text = 'Default message', type = 'error') {
    return Vue.toasted.show(text, {
      className: 'error-toasted',
      type,
      action: {
        text: localize('button')['close'],
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      },
    });
  }
}

export default AbstractService;