import store from '../store';
import Cookies from 'js-cookie';
import i18n from '../i18n/i18n';

const redirectToByStatus = (status, next = () => {}) => {
  if (status === 4) {
    next({ name: 'profile-index' })
  } else {
    next({ name: 'auth-failed' })
  }

  // switch (status) {
  //   case 0:
  //     next({ name: 'auth' });
  //     break;
  //   case 1:
  //     next({ name: 'cardAdd' });
  //     break;
  //   case 2:
  //   case 6:
  //     next({ name: 'loading' });
  //     break;
  //   case 4:
  //     next({ name: 'profile-index' });
  //     break;
  //   case 5:
  //     next({ name: 'upload-passport' });
  //     break;
  //   case 8:
  //     next({ name: 'refusal' });
  //     break;
  //   case 10:
  //     next({ name: 'upload-selfie-passport' });
  //     break;
  //   case 11:
  //     next({ name: 'upload-address-passport' });
  //     break;
  //   case 12:
  //     next({ name: 'confidant' })
  //     break;
  //
  //   default:
  //     next({ name: 'home' });
  //     break;
  // }
}

export const routes = [
  {
    path: '/',
    redirect: `${i18n.locale || '/uz'}`,
  },
  {
    path: '*',
    name: 'error',
    meta: { layout: 'empty' },
    component: () => import('../pages/error/ErrorPage'),
  },
  {
    path: '/app',
    name: 'redirect-to-app',
    meta: {
      layout: 'empty',
    },
    component: () => import('../pages/other/RedirectToApp')
  },
  {
    path: '/:lang',
    component: () => import('../pages/Index'),
    beforeEnter(to, from, next) {
      const lang = to.params.lang;

      if (!['ru', 'uz'].includes(lang)) {
        return next({
          name: 'home',
          params: { lang: 'uz' },
        });
      }

      if (i18n.locale !== lang) {
        i18n.locale = lang;
      }

      return next();
    },
    children: [
      {
        path: '/',
        name: 'home',
        meta: { layout: 'main' },
        component: () => import('../pages/HomePage'),
      },
      {
        path: 'authorization-failed',
        name: 'auth-failed',
        meta: {
          layout: 'empty',
        },
        component: () => import('@/pages/other/AuthFailed')
      },
      {
        path: 'business',
        name: 'business',
        meta: { navbar: 'orange', layout: 'main' },
        component: () => import('../pages/BusinessPage'),
      },
      {
        path: 'promotions',
        name: 'promotions',
        meta: { layout: 'promotion', navbarClass: 'bg-dark', navbar: 'pro', mobileNavbar: 'white' },
        component: () => import('../pages/PromotionsPage'),
      },
      {
        path: 'partners',
        name: 'partners',
        meta: { layout: 'main' },
        component: () => import('../pages/PartnersPage'),
      },
      {
        path: 'how-it-works',
        name: 'how-it-works',
        meta: { navbar: 'wheat', layout: 'main' },
        component: () => import('../pages/InstallmentsPage'),
      },
      {
        path: 'faq',
        name: 'faq',
        meta: { layout: 'main' },
        component: () => import('../pages/FaqPage'),
      },
      {
        path: 'payment',
        name: 'payment',
        meta: { layout: 'main' },
        component: () => import('../pages/PaymentPage'),
      },
      {
        path: 'news',
        name: 'news',
        meta: {
          layout: 'main',
        },
        component: () => import('../pages/NewsPage'),
      },
      {
        path: 'news/:slug',
        name: 'news-detail',
        meta: { layout: 'main' },
        component: () => import('../pages/NewsDetailPage'),
      },
      // {
      //   path: 'bonus',
      //   name: 'bonus',
      //   meta: { navbar: 'wheat', navClass: 'blue', layout: 'main' },
      //   component: () => import('../pages/BonusPage'),
      // },
      {
        path: 'partner/:partnerId',
        name: 'partner',
        meta: { layout: 'main' },
        props: true,
        component: () => import('../pages/PartnerPage'),
      },
      {
        path: 'promotions-upcoming/:slug',
        name: 'stock',
        meta: { layout: 'main' },
        props: true,
        component: () => import('../pages/StockPage'),
      },
      {
        path: 'promotions/:slug',
        name: 'promotion',
        meta: { layout: 'main' },
        props: true,
        component: () => import('../pages/PromotionPage'),
      },
      {
        path: 'vacancies',
        name: 'vacancies',
        meta: { layout: 'main' },
        props: true,
        component: () => import('../pages/Vacancies'),
      },
      {
        path: 'vacancies/:type',
        name: 'vacancies.type',
        meta: { layout: 'main' },
        props: true,
        component: () => import('../pages/VacancyType'),
      },
      {
        path: 'vacancies/:id',
        name: 'vacancy',
        meta: { layout: 'main' },
        props: true,
        component: () => import('../pages/Vacancy'),
      },
      {
        path: 'pretension/:contractId',
        name: 'pretension',
        meta: {
          layout: 'no',
        },
        props: true,
        component: () => import('../pages/PretensionPage'),
      },
      {
        path: 'empty',
        name: 'empty',
        meta: {
          layout: 'empty',
        },
        component: () => import('../pages/error/EmptyPage'),
      },
      {
        path: 'auth',
        name: 'auth',
        redirect: { name: 'auth-failed' },
        meta: { layout: 'empty', authUser: true },
        component: () => import('../pages/auth/AuthPage'),
      },
      {
        path: 'products/:slug/:id',
        name: 'product',
        meta: {
          layout: 'empty',
        },
        props: true,
        component: () => import('../pages/ProductPage'),
      },
      {
        path: 'politic-confidence',
        name: 'politic-confidence',
        meta: { layout: 'empty', authUser: true },
        component: () => import('../pages/auth/PoliticConfidencePage'),
      },
      {
        path: 'phone-number-confirm',
        name: 'phone-number-confirm',

        meta: { layout: 'empty', authUser: true, auth: true },
        component: () => import('../pages/auth/PhoneSmsPage'),
        beforeEnter(to, from, next) {
          const number = localStorage.getItem('number');
          if (number) {
            next();
          } else {
            next({ name: 'auth' });
          }
        },
      },
      {
        path: 'card-add',
        name: 'cardAdd',
        meta: {
          layout: 'regis',
          names: 'registerCart',
        },
        component: () => import('../pages/auth/CardAddPage'),
        beforeEnter(to, from, next) {
          const userStatus = store.getters['buyers/userStatus'];
          const isAuthorized = !!Cookies.get('token');

          if (isAuthorized && userStatus === 1) {
            next();
          } else {
            redirectToByStatus(userStatus, next)
          }
        },
      },
      {
        path: 'sign_sms_code',
        name: 'sign_sms_code',
        meta: {
          layout: 'regis',
          names: 'registerCart',
        },
        component: () => import('../pages/auth/CardSmsPage'),
      },
      {
        path: 'upload-passport',
        name: 'upload-passport',
        meta: {
          layout: 'regis',
          names: 'upload-passport-s',
        },
        component: () => import('../pages/auth/UploadPassportPage'),
        beforeEnter(to, from, next) {
          const userStatus = store.getters['buyers/userStatus'];
          const passportType = store.getters['buyers/passportType'];
          const isAuthorized = !!Cookies.get('token');

          if (isAuthorized && userStatus === 5 && (passportType === 6 || passportType === null)) {
            next();
          } else {
            redirectToByStatus(userStatus, next)
          }
        },
      },
      {
        path: 'upload-address-passport',
        name: 'upload-address-passport',
        meta: {
          layout: 'regis',
          names: 'upload-address-passport-s',
        },
        component: () => import('../pages/auth/UploadAddressPassportPage'),
        beforeEnter(to, from, next) {
          const userStatus = store.getters['buyers/userStatus'];
          const passportType = store.getters['buyers/passportType'];
          const isAuthorized = !!Cookies.get('token');

          if (isAuthorized && userStatus === 11 && passportType === 6) {
            next();
          } else {
            redirectToByStatus(userStatus, next)
          }
        },
      },
      {
        path: 'upload-selfie-passport',
        name: 'upload-selfie-passport',
        meta: {
          layout: 'regis',
          names: 'upload-selfie-passport-s',
        },
        component: () => import('../pages/auth/UploadSelfiePassportPage'),
        beforeEnter(to, from, next) {
          const userStatus = store.getters['buyers/userStatus'];
          const passportType = store.getters['buyers/passportType'];
          const isAuthorized = !!Cookies.get('token');

          if (isAuthorized && userStatus === 10 && passportType === 6) {
            next();
          } else {
            redirectToByStatus(userStatus, next)
          }
        },
      },
      {
        path: 'confidant',
        name: 'confidant',
        meta: {
          layout: 'regis',
          names: 'confidant-s',
        },
        component: () => import('../pages/auth/GuarantPage'),
        beforeEnter(to, from, next) {
          const userStatus = store.getters['buyers/userStatus'];
          const isAuthorized = !!Cookies.get('token');
          const passportType = store.getters['buyers/passportType'];

          if (isAuthorized && userStatus === 12 && passportType === 6) {
            next();
          } else {
            redirectToByStatus(userStatus, next)
          }
        },
      },
      // register by id card
      {
        path: 'upload-id-card',
        name: 'upload-id-card',
        meta: {
          layout: 'regis',
          names: 'upload-id-card-s',
        },
        component: () => import('../pages/auth/UploadIdCardPage'),
        beforeEnter(to, from, next) {
          const userStatus = store.getters['buyers/userStatus'];
          const passportType = store.getters['buyers/passportType'];
          const isAuthorized = !!Cookies.get('token');

          if (isAuthorized && userStatus === 5 && (passportType === 0 || passportType === null)) {
            next();
          } else {
            redirectToByStatus(userStatus, next)
          }
        },
      },
      {
        path: 'upload-address-id-card',
        name: 'upload-address-id-card',
        meta: {
          layout: 'regis',
          names: 'upload-address-id-card-s',
        },
        component: () => import('../pages/auth/UploadAddressIdCardPage'),
        beforeEnter(to, from, next) {
          const userStatus = store.getters['buyers/userStatus'];
          const passportType = store.getters['buyers/passportType'];
          const isAuthorized = !!Cookies.get('token');

          if (isAuthorized && userStatus === 11 && passportType === 0) {
            next();
          } else {
            redirectToByStatus(userStatus, next)
          }
        },
      },
      {
        path: 'upload-selfie-id-card',
        name: 'upload-selfie-id-card',
        meta: {
          layout: 'regis',
          names: 'upload-selfie-id-card-s',
        },
        component: () => import('../pages/auth/UploadSelfieIdCardPage'),
        beforeEnter(to, from, next) {
          const userStatus = store.getters['buyers/userStatus'];
          const passportType = store.getters['buyers/passportType'];
          const isAuthorized = !!Cookies.get('token');

          if (isAuthorized && userStatus === 10 && passportType === 0) {
            next();
          } else {
            redirectToByStatus(userStatus, next)
          }
        },
      },
      {
        path: 'confidant-id-card',
        name: 'confidant-id-card',
        meta: {
          layout: 'regis',
          names: 'confidant-id-card-s',
        },
        component: () => import('../pages/auth/GuarantPage'),
        beforeEnter(to, from, next) {
          const userStatus = store.getters['buyers/userStatus'];
          const passportType = store.getters['buyers/passportType'];
          const isAuthorized = !!Cookies.get('token');

          if (isAuthorized && userStatus === 12 && passportType === 0) {
            next();
          } else {
            redirectToByStatus(userStatus, next)
          }
        },
      },
      // end register by id card
      {
        path: 'loading',
        name: 'loading',
        meta: { layout: 'empty', authUser: true, auth: true },
        component: () => import('../pages/auth/AwaitPage'),
      },
      {
        path: 'refusal',
        name: 'refusal',
        meta: { layout: 'main' },
        component: () => import('../pages/auth/RefusalPage'),
      },
      {
        path: 'success',
        name: 'success',
        meta: { layout: 'empty' },
        component: () => import('../pages/error/SuccessPage'),
      },
      {
        path: 'contract/:id',
        name: 'contract',
        meta: { layout: 'profile' },
        component: () => import('../pages/profile/ContractPage'),
        beforeEnter(to, from, next) {
          const isAuthorized = !!Cookies.get('token');

          if (!isAuthorized) {
            console.log('contract redirect to auth');

            next({
              name: 'auth',
              params: { lang: 'ru' },
              query: { redirectTo: to.name, contractId: to.params.id },
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'contract-create/:productId',
        name: 'contract/create',
        meta: {
          layout: 'empty',
        },
        props: true,
        component: () => import('../pages/ContractCreatePage'),
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            name: 'profile-index',
            meta: { layout: 'profile' },
            component: () => import('../pages/profile/ProfilePage'),
          },
          {
            path: 'contracts',
            name: 'profile.contracts',
            meta: { layout: 'profile' },
            component: () => import('../pages/profile/ContractsPage'),
          },
          {
            path: 'contracts/:id',
            name: 'profile.contract',
            meta: { layout: 'profile' },
            component: () => import('../pages/profile/ContractPage'),
          },
          {
            path: 'history',
            name: 'profile.history',
            meta: { layout: 'profile' },
            component: () => import('../pages/profile/HistoryPage'),
          },
          {
            path: 'bonus',
            name: 'profile.bonus',
            meta: { layout: 'profile' },
            component: () => import('../pages/profile/BonusPage'),
          },
          {
            path: 'balance-up',
            name: 'profile.balance-up',
            meta: { layout: 'profile' },
            component: () => import('../pages/profile/BalanceUpPage'),
          },
          {
            path: 'contract/:id/graphic',
            name: 'profile.graphic',
            meta: { layout: 'profile' },
            component: () => import('../pages/profile/ContractGraphicPage'),
          },
          {
            path: 'faq',
            name: 'profile.faq',
            meta: { layout: 'profile' },
            component: () => import('../pages/profile/FaqPage'),
          },
          {
            path: 'paycoin-sale',
            name: 'profile.paycoin-sale',
            meta: { layout: 'profile' },
            component: () => import('../pages/profile/PaycoinSalePage'),
          },
        ],
        component: () => import('../pages/profile/Index'),
        beforeEnter(to, from, next) {
          const isAuthorized = !!Cookies.get('token');
          const userStatus = store.getters['buyers/userStatus'];

          if (isAuthorized && userStatus === 4) {
            next();
          } else {
            redirectToByStatus(userStatus, next)
          }
        },
      },
    ],
  },
];

export default routes;
