<template>
  <div @click="onClick" :class="{ 'btn-loading': loading }">
    <router-link
      :to="to"
      class="P_button"
      :disabled="loading"
      :class="{ 'btn-loading disabled': loading }"
      :style="`
      background:${background};
      color:${color};
    `"
    >
      <slot />
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    to: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '#ffffff',
    },
    background: {
      type: String,
      default: '#6610F5',
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
};
</script>
<style lang="scss" scoped>
  a.P_button {
    background: $main;
    box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
    border-radius: 14px;
    width: auto;
    padding: 18px 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 111.63%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    transition: 0.2s;

    &:hover {
      background: $main-light;
    }
  }

  .btn-loading {
    position: relative;
    pointer-events: none;
    color: transparent !important;

    &:after {
      animation: spinAround 500ms infinite linear;
      border: 2px solid $white;
      border-radius: 50%;
      border-right-color: transparent;
      border-top-color: transparent;
      content: "";
      display: block;
      height: 1em;
      width: 1em;
      position: absolute;
      left: calc(50% - (1em / 2));
      top: calc(50% - (1em / 2));
    }
  }

  @keyframes spinAround {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  @media (max-width: 575px) {
    a.P_button {
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #ffffff;
      padding: 16px 24px;
    }
  }
</style>
