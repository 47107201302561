<template>
  <div
    id="sidebar_navbar"
    class="sidebar"
    :class="{ active: active }"
  >
    <div class="links">
      <ul>
        <li v-for="(link, index) in links" :key="index" @click="sidebarClose">
          <router-link :to="{ name: link.name }" active-class="active" class="scrollLink" exact>
            {{ link.title }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="app">
      <div class="title">

        <h2 v-html="localize('popup')['download-app']"></h2>

        <p v-html="localize('popup')['text']"></p>

        <ul style="display: flex; align-items: center;">

          <li>
            <a :href="iosApp" role="button">
              <img src="../assets/icons/appstore.svg" alt="" />
            </a>
          </li>

          <li>
            <a :href="androidApp" role="button">
              <img src="../assets/icons/playmarket.svg" alt="" />
            </a>
          </li>

        </ul>

      </div>

      <img src="../assets/img/sidebar-2.png" alt="" />

    </div>
  </div>
</template>

<script>
import localize from "@/filters/localize.filter";
import config from "@/config";

export default {
  props: {
    active: {
      type: Boolean
    }
  },
  data: () => ({
    installable: false,
    iosApp: config.iosAppUrl,
    androidApp: config.androidAppUrl,
    links: [
      {
        title: localize("popup")["how-to-works"],
        name: "how-it-works"
      },
      {
        title: localize("popup")["magazines"],
        name: "partners"
      },
      {
        title: localize("popup")["news"],
        name: "news"
      },
      {
        title: localize("footer")["installments"]["title"],
        name: "faq"
      }
    ]
  }),
  methods: {
    localize,
    sidebarClose() {
      this.$emit("sidebarClose", { active: false });
      document.body.style.overflow = "auto";
    },
    sidebarOpen() {
      this.$emit("sidebarClose", {
        active: true
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixins/font.scss";

.sidebar {
  padding: 32px;
  background: rgb(249, 249, 249);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 12px 12px;
  display: flex;
  width: 1008px;
  height: 298px;
  position: fixed;
  top: -500px;
  z-index: 1011;
  left: 50%;
  transform: translateX(-50%);
  transition: 1s;

  &.active {
    top: 88px;
  }

  .links {
    width: 35%;
  }

  ul {
    li {
      margin-bottom: 24px;

      a {
        @include font(20px, 600, 24px, $black);

        &.active,
        &:hover {
          color: $main;
        }
      }
    }
  }

  .app {
    display: flex;
    justify-content: space-between;
    width: 65%;

    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .text {
        margin-top: 8px;
        font-size: 14px;
        color: $main;
      }

    }

    h2 {
      @include font(36px, 900, 45px, $main, false);
      margin-bottom: 8px;
    }

    p {
      @include font(20px, 400, 24px, $muted);
      letter-spacing: 0.01em;
      margin-bottom: 32px;

      &.soon {
        color: $main;
      }
    }

    /*a {
      background: $main;
      box-shadow: -10px 10px 30px rgba(255, 118, 67, 0.24);
      border-radius: 8px;
      padding: 18px 24px;
      font-weight: 600;
      font-size: 18px;
      line-height: 111.63%;
      display: flex;
      align-items: center;
      color: $white;
    }*/
  }

  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
