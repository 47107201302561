import card1 from '@/assets/profile_assets/Carddesign1.svg';
import card2 from '@/assets/profile_assets/Carddesign2.svg';
import card3 from '@/assets/profile_assets/Carddesign3.svg';
import uzcardWhite from '@/assets/profile_assets/uzcardWhite.svg';
import humoWhite from '@/assets/profile_assets/humoWhite.svg';
import uzcardBlack from '@/assets/profile_assets/uzcardBlack.svg';
import humoBlack from '@/assets/profile_assets/humoBlack.svg';
import localize from '@/filters/localize.filter';
import moment from 'moment';

export function setBalance(state, data) {
  state.balance = data;
}

// TODO: replace 'setInfoUser' on 'SET_USER_INFO'
export function setInfoUser(state, info) {
  state.userInfo = info;
}

export function setActionStatus(state, status) {
  state.actionStatus = status;
}

export function setCard(state, { cards, deposit }) {
  // ! card
  cards.unshift({
    id: 0,
    balance: deposit,
    style: card1,
    theme: humoWhite,
    title: localize('balancePopup')['personalBalance'],
    type: 'UZCARD',
    exp: '----',
  });

  cards.map((card, i) => {
    card.style = i + 1;

    let cardExp = moment(card.exp, 'MMYY', true);
    if (!cardExp.isValid()) {
      cardExp = moment(card.exp, 'YYMM', true);
    }

    card.exp = cardExp.isValid() ? cardExp.format('MM/YY') : '--/--';

    const cardStyle = Number(card.style % 3);

    card.theme = 'whiteThema';
    card.logo = card.type === 'UZCARD' ? uzcardWhite : humoWhite;

    switch (cardStyle) {
      case 0:
        card.theme = 'blackThema';
        card.style = card3;
        card.logo = card.type === 'UZCARD' ? uzcardBlack : humoBlack;
        break;
      case 1:
        card.style = card1;
        break;
      case 2:
        card.style = card2;
        break;
      default:
        card.style = card3;
    }

    return card;
  });

  state.cardUser = cards;
}

export function setStatus(state, data) {
  state.passportType = data.passport_type;
  state.userStatus = data.status;
}

export function SET_USER_INFO(state, userInfo) {
  state.userInfo = userInfo
}

export function SET_PRODUCT_FOR_BUY(state, payload) {
  state.productId = payload.productId
  state.hasPurchase = payload.hasPurchase
}

export function REMOVE_PRODUCT_FOR_BUY(state) {
  state.productId = null
  state.hasPurchase = false
}