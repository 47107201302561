<template>
  <div
    id="mainLayout"
    :class="classes ? classes : navbar"
  >
    <div @click="active = false" class="overlayes" v-show="active"></div>
    <component
      @sidebarOpens="sidebarHandler"
      :is="navbar"
      :itemsLeft="itemsLeft"
      :itemsRight="itemsRight"
      :background="classes ? classes : ''"
      :class="navbarClass"
      @sidebarClose="sidebarCloses"
      :noBlur="active"
      :navbarNone="true"
      :active="active"
      :key="locale"
      :mobileNavbar="mobileNavbar"
    />
<!--    <navbar-mobile />-->
<!--    <white-navbar />-->
    <white-navbar />
    <Sidebar :active="active" @sidebarClose="sidebarClose" />
    <router-view />
    <foo-ter />
  </div>
</template>

<script>
import MainNavbar from '../components/Navbar.vue';
import ProNavbar from '../components/PromotionNavbar';
import Sidebar from '../components/sidebar.vue';
import OrangeNavbar from '../components/OrangeNavbar.vue';
import WheatNavbar from '../components/WheatNavbar.vue';
import NavbarMobile from '../components/NavbarMobile.vue';
import WhiteNavbar from '../components/NavbarMobileWhite.vue';
import Footer from '../components/footer.vue';
import localizeFilter from '../filters/localize.filter';

export default {
  components: {
    MainNavbar,
    ProNavbar,
    OrangeNavbar,
    WheatNavbar,
    Sidebar,
    NavbarMobile,
    WhiteNavbar,
    'foo-ter': Footer,
  },
  computed: {
    navbar() {
      return (this.$route.meta.navbar || 'main') + '-navbar';
    },
    mobileNavbar() {
      return (this.$route.meta.mobileNavbar || 'main') + '-navbar';
    },
    classes() {
      return this.$route.meta.navClass || '';
    },
    navbarClass() {
      return this.$route.meta.navbarClass || '';
    },
    locale() {
      return localStorage.getItem('lang');
    },
  },
  data: () => ({
    active: false,
    itemsLeft: [
      {
        title: localizeFilter('navbar_business'),
        to: { name: 'auth' },
      },
    ],
    itemsRight: [
      {
        title: localizeFilter('О нас'),
        to: { name: 'about'},
      },
      {
        title: 'Как это работает',
        to: { name: 'how-it-works' },
      },
      {
        title: localizeFilter('banner')['magazines']['title'],
        to: { name: 'partners' },
      },
    ],
  }),
  methods: {
    localizeFilter,
    sidebarHandler(e) {
      this.active = e.active;
    },
    sidebarClose(e) {
      this.active = e.active;
    },
    sidebarCloses(e) {
      this.active = e.active;
    },
  },
};
</script>
<style lang="scss">
  #mainLayout {
    padding-top: 88px;

    &::after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      background: $grey;
      width: 100%;
      height: 88px;
    }

    &.orange-navbar {
      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        background: $main;
        width: 100%;
        height: 88px;
      }
    }
    &.pro-navbar {
      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        background: #1E1E1E;
        width: 100%;
        height: 88px;
      }
    }
    &.wheat-navbar {
      &::after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        background: rgb(255, 246, 238);
        width: 100%;
        height: 88px;
      }
    }

    &.blue {
      &::after {
        background: #eef7ff;
      }
    }

    .overlayes {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.5);
      z-index: 900;
    }
  }

  @media (max-width: 991px) {
    .component_none {
      display: none;
    }
    #mainLayout {
      padding-top: 48px;

      &::after {
        height: 48px;
      }

      &.orange-navbar {
        &::after {
          height: 48px;
        }
      }

      &.wheat-navbar {
        &::after {
          height: 48px;
        }
      }
    }
  }
</style>
