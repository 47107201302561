<template>
  <div id="regisLayout">
    <div class="container fluid p-0">
      <div class="row m-0">
        <div class="col-xl-5 col-ml-5 p-0 d-none">
          <registerSidebar />
        </div>
        <div
          class="col-xl-1 col-ml-1 p-0 d-none"
          style="background: #f5f5f5"
        ></div>
        <div class="col-xl-6 col-ml-6" style="background: #f5f5f5">
          <div class="d-block"></div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import registerSidebar from "../components/registerSidebar.vue";

export default {
  data: () => ({
    step: 1,
  }),
  components: { registerSidebar },
  methods: {
    stepperForm(i) {
      this.step = i.i;
    },
  },
};
</script>
<style lang="scss" scoped>
.m-0 {
  margin: 0 !important;
}

.p-0 {
  padding: 0px !important;
}

.d-block {
  display: none;
}

@media (max-width: 1199px) {
  .d-block {
    display: block;
  }
  .d-none {
    display: none;
  }
}
</style>
